import { FC, useEffect } from "react";
import { Button } from "../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { IBiodataFilterOptns } from "../../types/custom";
import {
  getAllGPZ,
  getAllStates,
} from "../../lib/redux/slices/geolocation/locationThunk";
import {
  getAllAgeGroups,
  getAllLiteracyLevels,
} from "../../lib/redux/slices/demography/demographyThunk";

interface BiodataTableFilterProps {
  setFilterOptns: React.Dispatch<React.SetStateAction<IBiodataFilterOptns>>;
  filterOptns: IBiodataFilterOptns;
  handleFilter?: Function;
  clearFilters?: Function;
}
const BiodataTableFilter: FC<BiodataTableFilterProps> = ({
  setFilterOptns,
  filterOptns,
  handleFilter,
  clearFilters,
}) => {
  const { allGPZ, allStates } = useSelector(
    (store: RootState) => store.geolocation
  );
  const { allAgeGroup, allLiteracyLevels } = useSelector(
    (store: RootState) => store.demography
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllGPZ());
      await dispatch(getAllStates());
      await dispatch(getAllAgeGroups());
      await dispatch(getAllLiteracyLevels());
    };

    getData();
  }, [dispatch]);

  return (
    <div className=" p-6  bg-white z-50 space-y-6  w-[36rem]">
      <h4 className="font-medium text-[2.8rem] text-[#3F434A]">Filter</h4>

      <div className="space-y-3">
        <label className="font-normal text-base" htmlFor="">
          Gender
        </label>

        <select
          value={filterOptns.gender}
          onChange={(e) =>
            setFilterOptns({
              ...filterOptns,
              gender: e.target.value,
            })
          }
          id="gender"
          className="bg-gray-50 h-[4rem] border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
        >
          <option value="">Gender</option>
          {["male", "female"].map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className="space-y-3">
        <label className="font-normal text-base" htmlFor="">
          Age Group
        </label>

        <select
          value={filterOptns.age_group_id}
          onChange={(e) =>
            setFilterOptns({
              ...filterOptns,
              age_group_id: e.target.value,
            })
          }
          id="age_group"
          className="bg-gray-50 h-[4rem] border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
        >
          <option value="">Select Age Group</option>
          {allAgeGroup &&
            allAgeGroup.map((item, index) => (
              <option
                className="h-[4rem]"
                key={index}
                value={item.id.toString()}
              >
                {item.name}
              </option>
            ))}
        </select>
      </div>
      <div className="space-y-3">
        <label className="font-normal text-base" htmlFor="">
          Literacy Level
        </label>

        <select
          value={filterOptns.literacy_level_id}
          onChange={(e) =>
            setFilterOptns({
              ...filterOptns,
              literacy_level_id: e.target.value,
            })
          }
          id="literacy_level"
          className="bg-gray-50 h-[4rem] border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
        >
          <option value="">Select Literacy Level</option>
          {allLiteracyLevels &&
            allLiteracyLevels.map((item, index) => (
              <option
                className="h-[4rem]"
                key={index}
                value={item.id.toString()}
              >
                {item.name}
              </option>
            ))}
        </select>
      </div>

      <div className="space-y-3">
        <label className="font-normal text-base" htmlFor=""></label>
      </div>
      <div className="space-y-3">
        <label className="font-normal text-base" htmlFor="">
          Zone
        </label>

        <select
          value={filterOptns.geo_political_zone_id}
          onChange={(e) =>
            setFilterOptns({
              ...filterOptns,
              geo_political_zone_id: e.target.value,
            })
          }
          id="geo_political_zone_id"
          className="bg-gray-50 h-[4rem] border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
        >
          <option value="">Select Geo Political Zone</option>
          {allGPZ &&
            allGPZ.map((item, index) => (
              <option className="h-[4rem]" key={index} value={item.id}>
                {item.name}
              </option>
            ))}
        </select>
      </div>

      <div className="space-y-3">
        <label className="font-normal text-base" htmlFor="">
          State
        </label>

        <select
          value={filterOptns.state_id}
          onChange={(e) =>
            setFilterOptns({
              ...filterOptns,
              state_id: e.target.value,
            })
          }
          id="state_id"
          className="bg-gray-50 h-[4rem] border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
        >
          <option value="">Select States</option>
          {allStates &&
            allStates.map((item, index) => (
              <option className="h-[4rem]" key={index} value={item.id}>
                {item.name}
              </option>
            ))}
        </select>
      </div>

      <div className="flex justify-between gap-6 items-center mt-6">
        {clearFilters && (
          <Button
            className="bg-rose-200 border-rose-500 text-rose-500 hover:bg-rose-300"
            onClick={() => clearFilters()}
          >
            Clear
          </Button>
        )}
        {handleFilter && <Button onClick={() => handleFilter()}>Save</Button>}
      </div>
    </div>
  );
};

export default BiodataTableFilter;
