import { FC } from "react";
import { Input } from "../../ui/Input";
import { Mail } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { UserRole } from "../../../utils/data/tableData";
import { IUser } from "../../../types/users";

interface UserFormProps {
  readonly?: boolean;
  userDetails: Omit<IUser, "id">;
  mode?: string;
  handleRoleChange: (role: UserRole) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const UserForm: FC<UserFormProps> = ({
  readonly,
  userDetails,
  mode,
  handleInputChange,
  handleRoleChange,
}) => {
  return (
    <div className="space-y-6 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Name</label>
        <div className="flex gap-4 items-center w-[70%]">
          <Input
            value={userDetails.name}
            onChange={handleInputChange}
            readOnly={readonly}
            name="name"
            placeholder="Enter Name"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0"
          />
        </div>
      </div>

      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Email Address</label>

        <div className="border rounded-lg flex items-center gap-3 px-6 py-4  h-18 w-[70%]">
          <Mail className="text-gray-500" />
          <Input
            value={userDetails.email}
            onChange={handleInputChange}
            name="email"
            readOnly={readonly}
            placeholder="Email"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0 border-0 py-0 m-0"
          />
        </div>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">Role</label>

        <Select
          disabled={readonly && mode !== "edit"}
          defaultValue={userDetails.role}
          onValueChange={handleRoleChange}
        >
          <SelectTrigger className="w-[70%] focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select Role-" />
          </SelectTrigger>
          <SelectContent>
            {Object.values(UserRole).map((item, index) => (
              <SelectItem key={index} value={item}>
                {item}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default UserForm;
