import { FC } from "react";
import { TailSpin } from "react-loader-spinner";

interface LoaderIconProps {
  height?: number;
  width?: number;
}
const LoaderIcon: FC<LoaderIconProps> = ({ height, width }) => {
  return (
    <div className="flex w-full p-16 h-full justify-center items-center">
      <TailSpin width={width} height={height} />
    </div>
  );
};

export default LoaderIcon;
