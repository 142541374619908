import { useDispatch, useSelector } from "react-redux";
import AuthPageHeader from "../components/shared/AuthPageHeader";
import { Button, buttonVariants } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { cn } from "../lib/shadcnUtils";
import { Key, MoveLeft } from "lucide-react";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../lib/redux/store";
import { forgotPassswordHandler } from "../lib/redux/slices/auth/authThunk";
import { toast } from "../hooks/useToast";

interface ForgotPasswordProps {}
const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [email, setEmail] = useState("");
  const { isLoading } = useSelector((store: RootState) => store.auth);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    try {
      await dispatch(forgotPassswordHandler(email));

      navigate("/auth-code");
    } catch (error) {
      return toast({
        title: "Error Making request",
        description: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="py-[3rem] w-screen flex items-center  bg-white">
      <div className=" mx-auto w-[30%] ">
        <div className="flex flex-col gap-6 items-center">
          <AuthPageHeader />
          <div className="bg-[#D4EBE2] mx-auto w-fit h-fit rounded-full border-[#EAF9EC] border-[1rem] p-4">
            <Key className="w-[2.8rem] h-[2.8rem]" />
          </div>
          <h6 className="font-bold text-[3rem] font-manjari mb-[3rem]">
            Forgot password
          </h6>
          <p className="font-normal text-[#344054] text-[1.6rem]">
            As a first time user we’ll send you reset instructions.
          </p>

          <form className="space-y-6 w-full">
            <div className="spaace-y-4 mb-4">
              <label
                className="text-normal mb-3 flex  text-base text-[#344054]"
                htmlFor="email"
              >
                Email:
              </label>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="h-20"
                placeholder="Enter your Email"
              />
            </div>

            <Button
              isLoading={isLoading}
              onClick={handleForgotPassword}
              size={"lg"}
              className="rounded-xl text-base w-full"
            >
              Continue...
            </Button>
          </form>
          <Link
            to={"/login"}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "text-[#667085] gap-2"
            )}
          >
            <MoveLeft />
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
