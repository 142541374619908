import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import RoleForm from "../components/accessManagement/organisms/RoleForm";
import { Button } from "../components/ui/Button";
import PermissionsWrapper from "../components/accessManagement/organisms/PermissionsWrapper";
import { IROle } from "../types/role";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";

interface EditRoleProps {}
const EditRole: FC<EditRoleProps> = () => {
  const [roleDetails, setRoleDetails] = useState<Omit<IROle, "id">>({
    title: "Administrator",
    description: "Some Description",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setRoleDetails({ ...roleDetails, [name]: value });
  };

  return (
    <div>
      <PageHeader
        title="Edit Role"
        subTitle="Manage role and  permissions here."
      />
      <PageSecondaryHeadings title="Role info" description="Update role details here."  />

      <div className="mb-8 border-y py-16">
        <RoleForm
          roleDetails={roleDetails}
          handleInputChange={handleInputChange}
        />
      </div>

      <div className="mb-8">
        <h5 className="font-medium text-base text-[#344054] ">Permission</h5>
        <p className="font-normal text-base text-[#667085]">
          Select permission from dropdown
        </p>
      </div>

      <div className="w-[80%]">
        <PermissionsWrapper  />
      </div>
      <div className="flex justify-end ">
        <div className="flex gap-4 items-center ">
          <Button variant={"outline"} className="bg-transparent">
            Cancel
          </Button>
          <Button disabled>Save</Button>
        </div>
      </div>
    </div>
  );
};

export default EditRole;
