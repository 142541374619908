import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  addNewAgeGroup,
  addNewLiteracyLevel,
  addNewOccupation,
  deleteAgeGroup,
  deleteLiteracyLevel,
  deleteOccupation,
  getAllAgeGroups,
  getAllLiteracyLevels,
  getAllOccupations,
  updateAgeGroup,
  updateLiteracyLevel,
  updateOccuption,
} from "./demographyThunk";

interface AccessMgmtSliceState {
  [key: string]: any;
  selectedAgeGroup: IAgeGroup | null;
  selectedLiteracyLevel: ILiteracyLevel | null;
  selectedOccupation: IOccupation | null;
  allAgeGroup: IAgeGroup[] | null;
  allLiteracyLevels: ILiteracyLevel[] | null;
  allOccupation: IOccupation[] | null;
  searchList: IAgeGroup[] | ILiteracyLevel[] | IOccupation[] | null;
  currentPage: number;
  currentAgeGroupPage: number;
  currentLiteracyLevelPage: number;
  currentOccupationPage: number;
  search: string;
  isLoading: boolean;
  limit: number;
  numberOfPages: number;
}
interface UpdateStatePayload {
  name: keyof AccessMgmtSliceState;
  value: any;
}

const initialState: AccessMgmtSliceState = {
  selectedAgeGroup: null,
  selectedLiteracyLevel: null,
  selectedOccupation: null,
  allAgeGroup: null,
  allLiteracyLevels: null,
  allOccupation: null,
  searchList: null,
  search: "",
  currentPage: 1,
  currentAgeGroupPage: 1,
  currentLiteracyLevelPage: 1,
  currentOccupationPage: 1,
  isLoading: false,
  limit: 10,
  numberOfPages: 0,
};

const AccessMgmtSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedAgeGroup: (state, action) => {
      state.selectedAgeGroup = action.payload;
    },
    setSelectedLiteracyLevel: (state, action) => {
      state.selectedLiteracyLevel = action.payload;
    },
    setSelectedOccupation: (state, action) => {
      state.selectedOccupation = action.payload;
    },
    updateDemographyStateValues: (
      state,
      action: PayloadAction<UpdateStatePayload>
    ) => {
      state[action.payload.name] = action.payload.value;
    },
    selectPage: (state, action) => {
      if (action.payload <= state.numberOfPages && action.payload >= 0) {
        state.currentPage = action.payload;
      }
    },
    moveNextPage: (state) => {
      if (state.currentPage < state.numberOfPages) {
        state.currentPage += 1;
      }
    },
    movePrevPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllAgeGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAgeGroups.fulfilled, (state, action) => {
        state.allAgeGroup = action.payload.data;
        state.numberOfPages = action.payload.data.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllAgeGroups.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addNewAgeGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewAgeGroup.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewAgeGroup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAgeGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAgeGroup.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateAgeGroup.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteAgeGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAgeGroup.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteAgeGroup.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAllLiteracyLevels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLiteracyLevels.fulfilled, (state, action) => {
        state.allLiteracyLevels = action.payload.data;
        state.numberOfPages = action.payload.data.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllLiteracyLevels.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addNewLiteracyLevel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewLiteracyLevel.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewLiteracyLevel.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateLiteracyLevel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLiteracyLevel.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateLiteracyLevel.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteLiteracyLevel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLiteracyLevel.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteLiteracyLevel.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAllOccupations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOccupations.fulfilled, (state, action) => {
        state.allOccupation = action.payload.data;
        state.numberOfPages = action.payload.data.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllOccupations.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addNewOccupation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewOccupation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewOccupation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateOccuption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOccuption.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateOccuption.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteOccupation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOccupation.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteOccupation.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSelectedAgeGroup,
  setSelectedLiteracyLevel,
  setSelectedOccupation,
  updateDemographyStateValues,
  selectPage,
  moveNextPage,
  movePrevPage,
} = AccessMgmtSlice.actions;

export default AccessMgmtSlice.reducer;
