import { FC } from "react";
import PageHeader from "../components/shared/PageHeader";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import { Pencil } from "lucide-react";
import CustomBadge from "../components/shared/atoms/CustomBadge";
import { GroupStatus } from "../utils/data/groupData";
import GroupForm from "../components/group/GroupForm";
import { useSelector } from "react-redux";
import { RootState } from "../lib/redux/store";

interface CreateViewGroupsProps {
  mode: "edit" | "new" | "view";
}
const CreateViewGroups: FC<CreateViewGroupsProps> = ({ mode }) => {
  const navigate = useNavigate();

  const { selectedGroup } = useSelector((store: RootState) => store.group);

  return (
    <div>
      <PageHeader
        title={`${mode} Group`}
        subTitle="Manage your team members and their account permissions here."
      />
      {mode !== "edit" && mode !== "new" && (
        <div className="flex justify-end ">
          <PrimaryOutlineBtns
            primaryBtnText="Edit"
            primaryBtnLink={`/groups/${selectedGroup?.id}/edit`}
            outlineBtnText="Cancel"
            outlineBtnOnclick={() => navigate(-1)}
            primaryBtnIcon={<Pencil />}
          />
        </div>
      )}
      <div className="mb-8">
        <div className="flex gap-4">
          <h5 className="font-medium text-xl ">Group Info</h5>
          {mode !== "new" && <CustomBadge status={GroupStatus.active} />}
        </div>
        <p className="font-normal text-base text-[#667085]">
          View Groups details here.
        </p>
      </div>
      <hr className="mb-8 " />

      <div className="mb-20">
        <GroupForm readonly={mode === "view"} />
      </div>
    </div>
  );
};

export default CreateViewGroups;
