import { FC, useEffect, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import CustomTable from "../components/shared/customTable/CustomTable";
import TableContainerHeader from "../components/accessManagement/molecules/TableContainerHeader";
import AgenciesTableBody from "../components/agencies/AgenciesTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import {
  moveNextPage,
  movePrevPage,
  selectPage,
} from "../lib/redux/slices/mda/mdaSlice";
import { IfilteOptns } from "../types/custom";
import { deleteAgency, getAllAgencies } from "../lib/redux/slices/mda/mdaThunk";
import { closeModal } from "../lib/redux/slices/dialogSlice";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import Fuse from "fuse.js";
import { updateMdaStateValues } from "../lib/redux/slices/mda/mdaSlice";

const initFilter = {
  ministry: "",
};

interface AgenciesProps {}
const Agencies: FC<AgenciesProps> = () => {
  const [filterOpts, setFilterOptns] = useState<IfilteOptns>(initFilter);
  const [search, setSearch] = useState("");

  const { isOpen } = useSelector((store: RootState) => store.dialog);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const { currentPage, selectedAgency, allAgencies, limit } = useSelector(
    (store: RootState) => store.mda
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setNumberOfPages(allAgencies ? Math.ceil(allAgencies?.length / limit) : 0);
  }, [allAgencies, limit]);

  useEffect(() => {
    if (search.length === 0) {
      dispatch(
        updateMdaStateValues({
          name: "isSearching",
          value: false,
        })
      );
    }
  }, [dispatch, search]);

  const handleFilter = () => {
    dispatch(
      updateMdaStateValues({
        name: "isFiltering",
        value: true,
      })
    );
    if (allAgencies) {
      const items = allAgencies.filter((agencies) => {
        return agencies.ministry_id === filterOpts.ministry;
      });

      dispatch(
        updateMdaStateValues({
          name: "filterList",
          value: items,
        })
      );
    }
  };

  const clearFilters = async () => {
    setFilterOptns(initFilter);
    dispatch(
      updateMdaStateValues({
        name: "isFiltering",
        value: false,
      })
    );

    dispatch(closeModal());
  };

  const handleSearch = async () => {
    dispatch(
      updateMdaStateValues({
        name: "isSearching",
        value: true,
      })
    );
    if (allAgencies) {
      const fuse = new Fuse(allAgencies, options);

      if (search.length === 0) {
        dispatch(
          updateMdaStateValues({
            name: "isSearching",
            value: false,
          })
        );
        return await dispatch(getAllAgencies());
      }

      const results = fuse.search(search);

      const items = results.map((result) => result.item);

      dispatch(
        updateMdaStateValues({
          name: "searchList",
          value: items,
        })
      );
    }
  };

  const confirmModal = async () => {
    if (selectedAgency) {
      await dispatch(deleteAgency(selectedAgency.id.toString()));
    }

    await dispatch(getAllAgencies());
  };

  return (
    <div>
      <PageHeader title="Agencies" subTitle="Manage Agencies here" />
      <SearchFilterContainer
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        filterOpts={filterOpts}
        setFilterOptns={setFilterOptns}
        handleFilter={handleFilter}
        clearFilters={clearFilters}
      />

      <div className="border border-[#EAECF0] p-8 shadow-sm rounded-lg bg-white ">
        <TableContainerHeader btnLink="new" btnText="Add Agency" />
        <CustomTable
          tableBody={<AgenciesTableBody />}
          headingList={agenciesTableHeadings}
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          handleSelectPage={(index: number) =>
            dispatch(selectPage({ newPage: index, numberOfPages }))
          }
          handlePrevPage={() => dispatch(movePrevPage())}
          handleNextPage={() => dispatch(moveNextPage(numberOfPages))}
        />
      </div>

      {isOpen && <ConfirmationDialog confirmModal={confirmModal} />}
    </div>
  );
};

export default Agencies;

const agenciesTableHeadings = [
  {
    text: "Name of Agency",
  },
  {
    text: "Contact",
  },
  {
    text: "Ministry",
  },
  {
    text: "",
  },
];

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
