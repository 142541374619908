import { FC } from "react";
import { createDashboardChartTooltip } from "../../utils/helpers/customChartTooltip";
import { ArcElement, Chart, ChartData, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

interface DoughnutChartProps {
  chartData: ChartData<"doughnut">;
  aspRatio?: number;
}

Chart.register(ArcElement, Tooltip);

const DoughnutChart: FC<DoughnutChartProps> = ({ chartData, aspRatio }) => {
  return (
    <Doughnut
      data={chartData}
      options={{
        
        maintainAspectRatio: true,
        aspectRatio: aspRatio || 2,
        font: {
          family: "'Manrope', 'Helvetica', 'Arial', sans-serif",
        },
        
        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            enabled: false,
            caretSize: 10,
            external: createDashboardChartTooltip,
          },
        },
      }}
    />
  );
};

export default DoughnutChart;
