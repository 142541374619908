import { FC, ReactNode } from "react";
import { Button } from "../ui/Button";
import { cn } from "../../lib/shadcnUtils";
import { UserStatus } from "../../utils/data/tableData";

interface OptsDropdownProps {
  options: {
    id: string;
    text: string;
    icon: ReactNode;
    btnOnclick: Function;
  }[];
  selectedId: string | number;
  status?: UserStatus;
}
const OptsDropdown: FC<OptsDropdownProps> = ({
  options,
  status,
  selectedId,
}) => {
  return (
    <div className="shadow-popOver bg-white rounded-xl absolute z-10 right-4 top-4 p-6 border min-w-[22.4rem]">
      {options.map(function (optn, index) {
        if (optn.text === "Enable" || optn.text === "Disable") {
          if (
            (status === 1 && optn.text === "Disable") ||
            (status === 0 && optn.text === "Enable")
          ) {
            return (
              <Button
                variant={"ghost"}
                key={index}
                onClick={() => optn.btnOnclick(selectedId)}
                className={cn(
                  "flex gap-4 items-center justify-start w-full",
                  optn.text.startsWith("Enable")
                    ? "text-[#229745]"
                    : optn.text === "Disable"
                    ? "text-[#E61919]"
                    : "text-[#33363F]"
                )}
              >
                {optn.icon}
                {optn.text}
              </Button>
            );
          }
        } else {
          return (
            <Button
              variant={"ghost"}
              key={index}
              onClick={() => optn.btnOnclick(selectedId)}
              className={cn(
                "flex gap-4 items-center justify-start w-full",
                optn.text === "Delete" ? "text-[#E61919]" : "text-[#33363F]"
              )}
            >
              {optn.icon}
              {optn.text}
            </Button>
          );
        }

        return null;
      })}
    </div>
  );
};

export default OptsDropdown;
