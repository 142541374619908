export const rolesData = [
  {
    id: "1",
    text: "Ability to Log in and Log out",
  },
  {
    id: "2",
    text: "Ability to manage information in the database",
  },
  {
    id: "3",
    text: "Ability to recover forgotten password",
  },
  {
    id: "4",
    text: "Ability to Log in and Log out",
  },
  {
    id: "5",
    text: "Ability to manage information in the database",
  },
  {
    id: "6",
    text: "Ability to recover forgotten password",
  },
  {
    id: "7",
    text: "Ability to recover forgotten password",
  },
  {
    id: "8",
    text: "Ability to view  ",
  },
];

export enum RoleStatus {
  active = "Active",
  inactive = "Inactive",
}
