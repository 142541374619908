import { useEffect, useState } from "react";
import { Progress } from "../../ui/Progress";

import { FC } from "react";

interface ProgressBarProps {
  width: number;
}
const ProgressBar: FC<ProgressBarProps> = ({ width }) => {
  const [progress, setProgress] = useState(13);

  useEffect(() => {
    const timer = setTimeout(() => setProgress(width), 500);
    return () => clearTimeout(timer);
  }, [width]);

  return <Progress value={progress} />;
};

export default ProgressBar;
