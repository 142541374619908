import { configureStore } from "@reduxjs/toolkit";
import DialogSlice from "./slices/dialogSlice";
import RoutingSlice from "./slices/routingSlice";
import AccessMgmtSlice from "./slices/accessMgmt/accessMgmtSlice";
import GeolocationSlice from "./slices/geolocation/locationSlice";
import DemographySlice from "./slices/demography/demographySlice";
import AuthSlice from "./slices/auth/authSlice";
import GroupSlice from "./slices/group/groupSlice";
import MdaSlice from "./slices/mda/mdaSlice";
import BioDataSlice from "./slices/biodata/biodataSlice";
import AnalyticsSlice from "./slices/analytics/analyticsSlice";

export const store = configureStore({
  reducer: {
    dialog: DialogSlice,
    routing: RoutingSlice,
    auth: AuthSlice,
    accessMgmt: AccessMgmtSlice,
    geolocation: GeolocationSlice,
    demography: DemographySlice,
    group: GroupSlice,
    biodata: BioDataSlice,
    mda: MdaSlice,
    analytics: AnalyticsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
