import { FC, useState } from "react";
import { Input } from "../ui/Input";
import { IUserPassword } from "../../types/users";
import PrimaryOutlineBtns from "../shared/PrimaryOutlineBtns";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../lib/redux/store";
import { changeUserPassword } from "../../lib/redux/slices/auth/authThunk";
import { toast } from "../../hooks/useToast";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

interface UserPasswordFormProps {
  readonly?: boolean;
  setIsEditingMode: Function;
}

const UserPasswordForm: FC<UserPasswordFormProps> = ({
  readonly,
  setIsEditingMode,
}) => {
  const [passwordChangeDetails, setPasswordChangeDetails] =
    useState<IUserPassword>({
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setPasswordChangeDetails({ ...passwordChangeDetails, [name]: value });
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      if (
        !passwordChangeDetails.new_password ||
        !passwordChangeDetails.current_password ||
        !passwordChangeDetails.new_password_confirmation
      ) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }

      const res = await dispatch(changeUserPassword(passwordChangeDetails));

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });
      toast({
        title: "Password Change Successful",
        description: "You have Changed your Password successfully",
        variant: "success",
      });

      navigate(-1);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 || error.response?.status === 401) {
          return toast({
            title: "Error Changing Password",
            description: error.response?.data,
            variant: "destructive",
          });
        }
      }

      toast({
        title: "Error Login in",
        description: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="space-y-6 py-12 text-base border-t border-b font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Current Password</label>

        <Input
          value={passwordChangeDetails.current_password}
          onChange={handleInputChange}
          name="current_password"
          readOnly={readonly}
          type="password"
          placeholder="**********"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>

      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">New Password</label>

        <Input
          value={passwordChangeDetails.new_password}
          onChange={handleInputChange}
          name="new_password"
          type="password"
          readOnly={readonly}
          placeholder="**********"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Confirm Password</label>

        <Input
          value={passwordChangeDetails.new_password_confirmation}
          onChange={handleInputChange}
          name="new_password_confirmation"
          type="password"
          readOnly={readonly}
          placeholder="**********"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>

      {!readonly && (
        <div className="flex justify-end ">
          <PrimaryOutlineBtns
            isLoading={isLoading}
            primaryBtnNotLink
            primaryBtnText="Update Passwords"
            primaryBtnOnclick={() => handleChangePassword()}
            outlineBtnText="Cancel"
            outlineBtnOnclick={() => setIsEditingMode()}
          />
        </div>
      )}
    </div>
  );
};

export default UserPasswordForm;
