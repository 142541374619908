import TitledProgressBar from "../atoms/TitledProgressBar";
import CustomLoader from "../../shared/CustomLoader";
import { RootState } from "../../../lib/redux/store";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface ZoneRespondantSummaryProps {}

const ZoneRespondantSummary: FC<ZoneRespondantSummaryProps> = () => {
  const { digitizationAnalytics, filter } = useSelector(
    (store: RootState) => store.analytics
  );

  const gpzData = [
    {
      name: "North West",
      total: 0,
    },
    {
      name: "North East",
      total: 0,
    },

    {
      name: "South South",
      total: 0,
    },
    {
      name: "North Central",
      total: 0,
    },
    {
      name: "South West",
      total: 3,
    },
    {
      name: "South East",
      total: 0,
    },
  ];

  const [values, setValues] = useState<
    { name: string; percentage: number }[] | null
  >(null);

  useEffect(() => {
    if (digitizationAnalytics) {
      if (filter === "State") {
        setValues(
          digitizationAnalytics.state_counts
            .map((item) => {
              const percentage =
                (item.total / digitizationAnalytics.bio_data_count) * 100;

              return {
                name: item.state_name,
                percentage,
              };
            })
            .sort((a, b) => a.percentage - b.percentage)
            .slice(0, 7)
        );
      } else {
        setValues(
          gpzData
            .map((item) => {
              const percentage =
                (item.total / digitizationAnalytics.bio_data_count) * 100;

              return {
                name: item.name,
                percentage,
              };
            })
            .sort((a, b) => a.percentage - b.percentage)
            .slice(0, 7)
        );
      }
    }
  }, [digitizationAnalytics, filter]);

  return (
    <div>
      <h3 className="text-darkBlue font-semibold text-[3.6rem] mb-4">178.9k</h3>
      <div>
        {values ? (
          values.map((value, index) => (
            <React.Fragment key={index}>
              <TitledProgressBar title={value.name} width={value.percentage} />
            </React.Fragment>
          ))
        ) : (
          <CustomLoader height={20} width={20} />
        )}
      </div>
    </div>
  );
};

export default ZoneRespondantSummary;
