export enum LocationStatus {
  active = "Active",
  inactive = "Inactive",
}

export const geolocationData = [
  {
    id: "1",
    name: "South - West",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "2",
    name: "South - East",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "3",
    name: "South - North",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "4",
    name: "South - South",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "5",
    name: "West - West",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "8",
    name: "East - West",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "7",
    name: "Noth - West",
    code: "10",
    status: LocationStatus.active,
  },
];

export const stateData = [
  {
    id: "1",
    zone: "South - West",
    state: "Oyo",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "2",
    zone: "South - East",
    state: "Lagos",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "3",
    zone: "South - North",
    state: "Jos",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "4",
    zone: "South - South",
    state: "Cross River",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "5",
    zone: "West - West",
    state: "Imo",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "8",
    zone: "East - West",
    state: "Gombe",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "7",
    zone: "Noth - West",
    state: "Rivers",
    code: "10",
    status: LocationStatus.active,
  },
];

export const lgaData = [
  {
    id: "1",
    zone: "South - West",
    state: "Oyo",
    lga: "Obudu",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "2",
    zone: "South - East",
    state: "Lagos",
    lga: "Yala",
    code: "10",
    status: LocationStatus.active,
  },

  {
    id: "4",
    zone: "South - South",

    state: "Cross River",
    lga: "Obudu",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "5",
    zone: "North - East",
    state: "Imo",
    lga: "Obinze",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "8",
    zone: "Middle - Belt",
    state: "Gombe",
    lga: "Abini",
    code: "10",
    status: LocationStatus.active,
  },
  {
    id: "7",
    zone: "Noth - West",
    state: "Rivers",
    lga: "Port",
    code: "10",
    status: LocationStatus.active,
  },
];

export const communitiesData = [
  {
    id: "1",
    name: "Rural",
    description:
      "Rural communities are typically characterized by low population density and open spaces.",
    status: LocationStatus.active,
  },
  {
    id: "2",
    name: "Urban",
    description:
      "Rural communities are typically characterized by low population density and open spaces.",
    status: LocationStatus.active,
  },
  {
    id: "3",
    name: "Urban Rural",
    description:
      "Rural communities are typically characterized by low population density and open spaces.",
    status: LocationStatus.active,
  },
];

export const ministriesData = [
  {
    id: "1",
    name: "MINISTER OF DEFENCE",
    status: LocationStatus.active,
  },
  {
    id: "2",

    name: "MINISTER OF DEFENCE",

    status: LocationStatus.active,
  },
  {
    id: "3",

    name: "MINISTER OF DEFENCE",

    status: LocationStatus.active,
  },
  {
    id: "4",

    name: "MINISTER OF DEFENCE",

    status: LocationStatus.active,
  },
  {
    id: "5",

    name: "MINISTER OF DEFENCE",

    status: LocationStatus.active,
  },
  {
    id: "8",
    name: "MINISTER OF EDUCATION",

    status: LocationStatus.active,
  },
  {
    id: "7",
    name: "MINISTER OF DEFENCE",

    status: LocationStatus.active,
  },
];

export const agenciesData = [
  {
    id: "1",
    ministry: "MINISTER OF DEFENCE",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
  {
    id: "2",

    ministry: "MINISTER OF DEFENCE",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
  {
    id: "3",

    ministry: "MINISTER OF DEFENCE",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
  {
    id: "4",

    ministry: "MINISTER OF DEFENCE",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
  {
    id: "5",

    ministry: "MINISTER OF DEFENCE",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
  {
    id: "8",
    ministry: "MINISTER OF EDUCATION",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
  {
    id: "7",
    ministry: "MINISTER OF DEFENCE",
    name: "National Examination Council",

    status: LocationStatus.active,
  },
];
