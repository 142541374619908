export enum DemographyStatus {
  active = "Active",
  inactive = "Inactive",
}

export const ageGroupData = [
  {
    id: "1",
    type: "Infant",
    upperBand: 10,
    lowerBand: 10,
    status: DemographyStatus.active,
  },
  {
    id: "2",
    type: "Toddler",
    upperBand: 10,
    lowerBand: 10,
    status: DemographyStatus.active,
  },
  {
    id: "3",
    type: "Young Adult",
    upperBand: 10,
    lowerBand: 10,
    status: DemographyStatus.active,
  },
  {
    id: "4",
    type: "Adult",
    upperBand: 10,
    lowerBand: 10,
    status: DemographyStatus.active,
  },
  {
    id: "5",
    type: "Senior",
    upperBand: 10,
    lowerBand: 10,
    status: DemographyStatus.active,
  },
];

export const literacyLevelData = [
  {
    id: "1",
    name: "Basic",
    description:
      "Advanced literacy skills, capable of critical analysis and synthesis of information.",
    status: DemographyStatus.active,
  },
  {
    id: "2",
    name: "None",
    description:
      "Advanced literacy skills, capable of critical analysis and synthesis of information.",
    status: DemographyStatus.active,
  },
  {
    id: "3",
    name: "Advanced",
    description:
      "Advanced literacy skills, capable of critical analysis and synthesis of information.",
    status: DemographyStatus.active,
  },
];
