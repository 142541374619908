export const locationPageTitles = [
  {
    name: "Geo Political Zone",
    pageSecondarySubText: "Enter Name and Code here",
  },
  {
    name: "State",
    pageSecondarySubText: "Enter Geo political, Name and Code here",
  },
  {
    name: "LGA",
    pageSecondarySubText: "Enter Geo political, State, Name and Code here",
  },
  {
    name: "Settlements",
    pageSecondarySubText: "View and edit Community details here.",
  },
];

export const demographyPageTitles = [
  {
    name: "Age Group",
    pageSecondarySubText: "Update Age Group details here.",
  },
  {
    name: "Level of Literacy ",
    pageSecondarySubText: "Update Community details here.",
  },
  {
    name: "Occupation",
    pageSecondarySubText: "Update Occupation details here.",
  },
];
