import { FC } from "react";
import { Table } from "../../ui/Table";
import CustomTableHeader from "../../shared/customTable/CustomTableHeader";
import TablePaginations from "../../shared/TablePaginations";
import SettlementsTableBody from "../molecules/SettlementsTableBody";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import {
  movePrevPage,
  selectPage,
  moveNextPage,
} from "../../../lib/redux/slices/geolocation/locationSlice";
import { useDispatch, useSelector } from "react-redux";

const communitiesTableHeadings = [
  {
    text: "Name",
  },
  {
    text: "Description",
  },

  {
    text: "",
  },
];

interface SettlementsTableProps {}
const SettlementsTable: FC<SettlementsTableProps> = () => {
  const { currentPage, numberOfPages } = useSelector(
    (store: RootState) => store.geolocation
  );

  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="relative">
      <Table className="border-y mb-6 no-scrollbar">
        <CustomTableHeader headingList={communitiesTableHeadings} />

        <SettlementsTableBody />
      </Table>

      <div className="px-6 py-4">
        <TablePaginations
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          handleSelectPage={(index: number) => dispatch(selectPage(index))}
          handlePrevPage={() => dispatch(movePrevPage())}
          handleNextPage={() => dispatch(moveNextPage())}
        />
      </div>
    </div>
  );
};

export default SettlementsTable;
