import { FC, useState } from "react";
import { Input } from "../ui/Input";
import { Mail } from "lucide-react";
import { Button } from "../ui/Button";
import Tiptap from "../shared/TipTap";
import RecipientFilterDialog from "./RecipientFilterDialog";
import { sendMail } from "../../lib/redux/slices/biodata/biodataThunk";
import { toast } from "../../hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/lib/redux/store";
import { IBiodataFilterOptns } from "@/types/custom";
import { useNavigate } from "react-router-dom";

interface SendEmailFormProps {}
const SendEmailForm: FC<SendEmailFormProps> = () => {
  const [emailInfo, setEmailInfo] = useState({
    subject: "",
    message: "",
  });
  const [filterOptns, setFilterOptns] = useState<IBiodataFilterOptns>({
    gender: "",
    state_id: "",
    geo_political_zone_id: "",
    age_group_id: "",
    literacy_level_id: "",
  });

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate()
  const { isLoading, biodataRecipeint } = useSelector(
    (store: RootState) => store.biodata
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setEmailInfo({ ...emailInfo, [name]: value });
  };

  const handleSendMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!emailInfo.subject || !emailInfo.message) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const emailPayload = {
        ...filterOptns,
        ...emailInfo,
      };
      const res = await dispatch(sendMail(emailPayload));

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      setFilterOptns({
        gender: "",
        state_id: "",
        geo_political_zone_id: "",
        age_group_id: "",
        literacy_level_id: "",
      });
      setEmailInfo({
        subject: "",
        message: "",
      });

      navigate(-1)

      return toast({
        title: "Emails Send Successfully ",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form
      onSubmit={handleSendMail}
      className="space-y-6 py-12 text-base border-t border-b font-medium"
    >
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">To</label>
        <div className="flex gap-4 items-center w-[80%]">
          <div className="flex items-center justify-between h-18 w-full rounded-xl border border-input bg-transparent px-6 py-4 text-base ring-offset-background  ">
            <span className="">
              {biodataRecipeint ? biodataRecipeint.length : 0} Recipient
            </span>
            <RecipientFilterDialog
              filterOptns={filterOptns}
              setFilterOptns={setFilterOptns}
            />
          </div>
        </div>
      </div>

      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Email Address</label>

        <div className="border rounded-lg flex items-center gap-3 px-6 py-4  h-18 w-[80%]">
          <Mail className="text-gray-500" />
          <Input
            value={emailInfo.subject}
            onChange={handleChange}
            name="subject"
            placeholder="Subject"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0 border-0 py-0 m-0"
          />
        </div>
      </div>
      <hr />
      <div className="">
        <div className="w-[87%]">
          <Tiptap
            message={emailInfo.message}
            setMessage={(text: string) =>
              setEmailInfo({ ...emailInfo, message: text })
            }
          />
        </div>
      </div>

      <div className="flex justify-end ">
        <Button isLoading={isLoading} type="submit">
          Send Message
        </Button>
      </div>
    </form>
  );
};

export default SendEmailForm;
