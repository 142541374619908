import { FC, useEffect, useRef, useState } from "react";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { Button } from "../../ui/Button";
import { SlOptionsVertical } from "react-icons/sl";
import UserAvater from "../../shared/atoms/UserAvater";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { CircleX, Eye, Pencil } from "lucide-react";
import OptsDropdown from "../../shared/OptsDropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { setSelectedUser } from "../../../lib/redux/slices/accessMgmt/accessMgmtSlice";
import { openModal } from "../../../lib/redux/slices/dialogSlice";
import { getAllUsers } from "../../../lib/redux/slices/accessMgmt/accessMgmtThunk";
import LoaderIcon from "../../shared/atoms/LoaderIcon";
import CustomBadge from "../../../components/shared/atoms/CustomBadge";
import { format } from "date-fns";
import { IUser } from "../../../types/users";

interface UsersTableBodyProps {}
const UsersTableBody: FC<UsersTableBodyProps> = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [skip, setSkip] = useState(0);
  const [usersData, setUsersData] = useState<IUser[] | null>(null);

  const ref = useRef(null);

  const {
    allUsers,
    isFiltering,
    isSearching,
    searchList,
    filterList,
    currentPage,
    limit,
    isLoading,
  } = useSelector((store: RootState) => store.accessMgmt);

  const dispatch = useDispatch<AppDispatch>();

  useOnClickOutside(ref, () => setSelectedUserId(null));

  const navigate = useNavigate();

  useEffect(() => {
    if (!allUsers) {
      dispatch(getAllUsers());
    }
  }, [allUsers, dispatch]);

  useEffect(() => {
    setSkip((currentPage - 1) * limit);
  }, [currentPage, limit]);

  useEffect(() => {
    if (isFiltering) {
      setUsersData(filterList);
    } else if (isSearching) {
      setUsersData(searchList);
    } else {
      setUsersData(allUsers);
    }
  }, [isFiltering, allUsers, filterList, isSearching, searchList]);

  const userTableOptns = [
    {
      id: "1",
      icon: <Pencil />,
      text: "Edit",
      btnOnclick: function (userId: string) {
        navigate(`${userId}/edit`);
      },
    },
    {
      id: "2",
      icon: <Eye />,
      text: "View",
      btnOnclick: function (userId: string) {
        navigate(`${userId}`);
      },
    },
    {
      id: "3",
      icon: <Eye />,
      text: "Enable",
      btnOnclick: function () {
        setSelectedUserId(null);
        dispatch(
          openModal({
            icon: (
              <IoMdInformationCircleOutline
                size={28}
                className="text-[#374957]"
              />
            ),
            iconBg: "bg-[#D1FADF]",
            iconBorderColor: "border-[#ECFDF3]",
            iconColor: "text-[#374957]",
            primaryColor: "bg-[#008753]",
            title: "Enable Account",
            description: "Are you sure you want to enable this account?",
          })
        );
      },
    },
    {
      id: "4",
      icon: <CircleX />,
      text: "Disable",
      btnOnclick: function () {
        setSelectedUserId(null);
        dispatch(
          openModal({
            icon: <IoWarningOutline size={28} className="text-[#DC6803]" />,
            iconBg: "bg-[#FEF0C7]",
            iconBorderColor: "border-[#FFFAEB]",
            iconColor: "text-[#DC6803]",
            primaryColor: "bg-[#D83535]",
            title: "Disable User Account",
            description:
              "When you click on confirm, user will be automatically disabled",
          })
        );
      },
    },
  ];

  return (
    <TableBody>
      {isLoading && (
        <TableRow>
          <TableCell rowSpan={0}>
            <LoaderIcon height={30} width={30} />
          </TableCell>
        </TableRow>
      )}
      {!isLoading &&
        usersData &&
        usersData.length > 0 &&
        usersData.slice(skip, skip + limit).map((user, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>
            <TableCell className="font-medium p-6">
              <div className="flex gap-3 items-center">
                <UserAvater
                  imgUrl="/assets/images/user_avatar.svg"
                  name="User"
                />
                <div>
                  <h5 className="font-medium ">{user.name}</h5>
                </div>
              </div>
            </TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell className="font-medium flex flex-col items-center">
              <span>
                {" "}
                {user.created_at && format(user.created_at, "dd/LL/yyyy")}
              </span>
              <span className="text-[#999999]">
                {" "}
                {user.created_at && format(user.created_at, "hh:mm aaa")}
              </span>
            </TableCell>
            <TableCell>{user.role}</TableCell>
            <TableCell>
              <CustomBadge status={user.status} />
            </TableCell>
            <TableCell className="relative">
              <Button
                onClick={() => {
                  setSelectedUserId(user.id);
                  dispatch(setSelectedUser(usersData[index]));
                }}
                variant={"ghost"}
              >
                <SlOptionsVertical />
              </Button>
              {selectedUserId === user.id && (
                <div ref={ref}>
                  <OptsDropdown
                    options={userTableOptns}
                    selectedId={selectedUserId}
                    status={user.status}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      {!isLoading && usersData && usersData.length === 0 && (
        <TableRow>
          <TableCell rowSpan={0}>
            <h4 className="p-8 text-base font-semibold flex justify-center ">
              No User to display
            </h4>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default UsersTableBody;
