import axios, { AxiosError } from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { UserRole } from "../../../../utils/data/tableData";

const baseUrl ="https://api.database.noa.gov.ng/api";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAllUsers = createAppAsyncThunk(
  "accessMgmt/getAllUsers",
  async (_, thunkAPI) => {
    const { limit } = thunkAPI.getState().mda;
    try {
      const res = await axiosInstance.get(`${baseUrl}/user/all`);

      const numberOfPages = Math.ceil(res.data?.data?.user.length / limit);

      return { users: res.data.data.user, numberOfPages };
    } catch (error) {
      return thunkAPI.rejectWithValue("Could not Get All Groups");
    }
  }
);

export const toggleUserStatus = createAppAsyncThunk(
  "accessMgmt/toggleUserStatus",
  async (
    data: {
      status: boolean;
      user_id: string;
    },
    thunkAPI
  ) => {
    try {
      await axiosInstance.post(
        `${baseUrl}/user/enable-disable`,

        data
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Toggle User Status");
    }
  }
);

export const updateUserRole = createAppAsyncThunk(
  "accessMgmt/updateUserRole",
  async (
    data: {
      role: UserRole;
      user_id: string;
    },
    thunkAPI
  ) => {
    try {
      await axiosInstance.post(
        `${baseUrl}/user/update/role`,

        data
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Update User Role");
    }
  }
);

export const getAllRoles = createAppAsyncThunk(
  "accessMgmt/getAllRoles",
  async (_, thunkAPI) => {
    const { limit } = thunkAPI.getState().mda;
    try {
      const res = await axiosInstance.get(`${baseUrl}/roles`);

      const numberOfPages = Math.ceil(res.data?.data?.agencies.length / limit);

      return { data: res.data.data.agencies, numberOfPages };
    } catch (error) {
      return thunkAPI.rejectWithValue("Could not Get All Groups");
    }
  }
);
