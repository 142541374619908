import { FC } from "react";
import { Textarea } from "../../ui/TextArea";
import { Input } from "../../ui/Input";

interface LiteracyLevelFormProps {
  readonly?: boolean;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  desc: string;
  setDesc: React.Dispatch<React.SetStateAction<string>>;
}
const LiteracyLevelForm: FC<LiteracyLevelFormProps> = ({
  readonly,
  name,
  setName,
  desc,
  setDesc,
}) => {
  return (
    <div className="space-y-6 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Name</label>

        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          readOnly={readonly}
          placeholder="Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>

      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Description</label>

        <Textarea
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          name="desc"
          readOnly={readonly}
          placeholder="Description"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
    </div>
  );
};

export default LiteracyLevelForm;
