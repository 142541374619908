import { type Editor } from "@tiptap/react";
import { FC } from "react";
import {
  Bold,
  Strikethrough,
  Underline,
  Italic,
  List,
  ListOrdered,
  Heading1,
  
} from "lucide-react";
import { Toggle } from "../ui/toggle";

interface EditorToolbarProps {
  editor: Editor | null;
}
const EditorToolbar: FC<EditorToolbarProps> = ({ editor }) => {
  if (!editor) return null;
  return (
    <div className="py-8 bg-transparent rounded-xl">
      <Toggle
        size="lg"
        pressed={editor.isActive("heading")}
        onPressedChange={function () {
          editor.chain().focus().toggleHeading({ level: 1 }).run();
        }}
      >
        <Heading1 className="h-8 w-8" />
      </Toggle>
      <Toggle
        size="lg"
        pressed={editor.isActive("bold")}
        onPressedChange={function () {
          editor.chain().focus().toggleBold().run();
        }}
      >
        <Bold className="h-8 w-8" />
      </Toggle>
      <Toggle
        size="lg"
        pressed={editor.isActive("italic")}
        onPressedChange={function () {
          editor.chain().focus().toggleItalic().run();
        }}
      >
        <Italic className="h-8 w-8" />
      </Toggle>
      <Toggle
        size="lg"
        pressed={editor.isActive("underline")}
        onPressedChange={function () {
            editor.chain().focus().toggleUnderline().run()
        }}
      >
        <Underline className="h-8 w-8" />
      </Toggle>
      <Toggle
        size="lg"
        pressed={editor.isActive("strike")}
        onPressedChange={function () {
            editor.chain().focus().toggleStrike().run()
        }}
      >
        <Strikethrough className="h-8 w-8" />
      </Toggle>
      <Toggle
        size="lg"
        pressed={editor.isActive("bulletList")}
        onPressedChange={function () {
            editor.chain().focus().toggleBulletList().run()
        }}
      >
        <ListOrdered className="h-8 w-8" />
      </Toggle>
      <Toggle
        size="lg"
        pressed={editor.isActive("orderedList")}
        onPressedChange={function () {
            editor.chain().focus().toggleOrderedList().run()
        }}
      >
        <List className="h-8 w-8" />
      </Toggle>
    </div>
  );
};

export default EditorToolbar;
