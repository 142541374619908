import { FC, ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "../ui/Badge";
import { Button } from "../ui/Button";
import { TiArrowSortedDown } from "react-icons/ti";
import { cn } from "../../lib/shadcnUtils";

interface SidebarLinksProps {
  title: string;
  icon: ReactElement<any, any>;
  showBadge?: boolean;
  badgeInfo?: number;
  hasNestedLinks?: boolean;
  isNestedLink?: boolean;
  link: string;
  btnOnclick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const SidebarLinks: FC<SidebarLinksProps> = ({
  title,
  icon,
  showBadge = false,
  badgeInfo,
  link,
  hasNestedLinks,
  isNestedLink,
  btnOnclick,
}) => {
  const location = useLocation();

  return (
    <>
      {hasNestedLinks ? (
        <Button
          variant={"ghost"}
          onClick={btnOnclick}
          className={cn(
            "min-h-[4.8rem] rounded-none rounded-tl-[2rem] w-full flex justify-between items-center py-3 px-4 hover:bg-primary hover:text-white rounded-tl-8"
          )}
        >
          <div className="flex gap-4 items-center  color-neutral">
            {icon}
            <span className="montserrat-alternates-medium text-base">
              {title}
            </span>
          </div>
          <TiArrowSortedDown />
        </Button>
      ) : (
        <NavLink
          to={link}
          className={cn(
            "min-h-[4.8rem] rounded-tl-[2rem]  flex justify-between items-center py-3 px-4",
            isNestedLink
              ? "hover:bg-[#cef9e4] hover:text-black pl-12"
              : "hover:bg-primary hover:text-white",
            location.pathname === link ? "bg-primary text-white" : ""
          )}
        >
          <div className="flex gap-4 items-center  color-neutral">
            {icon}
            <span className="montserrat-alternates-medium text-base">
              {title}
            </span>
          </div>
          {showBadge ? (
            <Badge className="bg-rose-500">{badgeInfo}</Badge>
          ) : null}
        </NavLink>
      )}
    </>
  );
};

export default SidebarLinks;
