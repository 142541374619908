import { FC } from "react";
import PageHeader from "../components/shared/PageHeader";
import RolesTable from "../components/accessManagement/organisms/RolesTable";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import { useSelector } from "react-redux";
import { RootState } from "../lib/redux/store";

interface RoleMgmtProps {}
const RoleMgmt: FC<RoleMgmtProps> = () => {
  const { isOpen } = useSelector((store: RootState) => store.dialog);

  return (
    <div>
      <PageHeader
        title="Role Management"
        subTitle="Manage role and  permissions here."
      />

      <RolesTable />

      {isOpen && <ConfirmationDialog />}
    </div>
  );
};

export default RoleMgmt;
