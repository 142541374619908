import { FC, useEffect, useState } from "react";

import { Input } from "../ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import PrimaryOutlineBtns from "../shared/PrimaryOutlineBtns";
import { Pencil } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  addNewAgency,
  getAllMinistries,
  updateAgency,
} from "../../lib/redux/slices/mda/mdaThunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/lib/redux/store";
import { toast } from "../../hooks/useToast";

interface AgenciesFormProps {
  readonly?: boolean;
  mode: "edit" | "new" | "view";
}

const initialData = {
  id: 1,
  name: "",
  contact: "",
  ministry_id: "",
};

const AgenciesForm: FC<AgenciesFormProps> = ({ readonly, mode }) => {
  const [agencyDetails, setAgencyDetails] =
    useState<Omit<IAgencies, "ministry">>(initialData);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { allMinistries, selectedAgency, isLoading } = useSelector(
    (store: RootState) => store.mda
  );

  useEffect(() => {
    dispatch(getAllMinistries());

    if (selectedAgency && (mode === "edit" || mode === "view")) {
      setAgencyDetails(selectedAgency);
    }
  }, [dispatch, mode, selectedAgency]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setAgencyDetails({ ...agencyDetails, [name]: value });
  };
  const handleSelectChange = (value: string, name: string) => {
    setAgencyDetails({ ...agencyDetails, [name]: value });
  };


  const handleAddNewAgency = async () => {
    try {
      await dispatch(updateAgency(agencyDetails));

      setAgencyDetails(initialData);

      toast({
        title: "Agency Added  Successfully",
        description: "You haved successfully added a new Agency",
        variant: "success",
      });

      navigate("/agencies");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please Try again Latter",
        variant: "destructive",
      });
    }
  };

  const handleUpdateAgency = () => {
    try {
      dispatch(addNewAgency(agencyDetails));

      return toast({
        title: "Agency Added  Successfully",
        description: "You haved successfully added a new Agency",
        variant: "success",
      });
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please Try again Latter",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-8 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">Ministry</label>

        <Select
          value={agencyDetails?.ministry_id.toString()}
          disabled={readonly}
          onValueChange={(value) => handleSelectChange(value, "ministry_id")}
        >
          <SelectTrigger className="w-[70%] focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue  placeholder="-Select Zone-" />
          </SelectTrigger>
          <SelectContent>
            {allMinistries &&
              allMinistries?.slice(1).map((item, index) => (
                <SelectItem key={index} value={`${item.id}`}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Agency Name</label>

        <Input
          value={agencyDetails.name}
          onChange={handleInputChange}
          name="name"
          readOnly={readonly}
          placeholder="Agency Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Agency Contact</label>

        <Input
          value={agencyDetails.contact}
          onChange={handleInputChange}
          name="contact"
          readOnly={readonly}
          placeholder="Agency Contact"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />

      {!readonly && (
        <div className="flex justify-end ">
          <PrimaryOutlineBtns
            isLoading={isLoading}
            primaryBtnText="Save"
            primaryBtnNotLink
            primaryBtnOnclick={
              mode === "new"
                ? () => handleAddNewAgency()
                : () => handleUpdateAgency()
            }
            outlineBtnText="Cancel"
            outlineBtnOnclick={() => navigate(-1)}
            primaryBtnIcon={<Pencil />}
          />
        </div>
      )}
    </div>
  );
};

export default AgenciesForm;
