import { FC } from "react";

interface PageSecondaryHeadingsProps {
  title: string;
  description: string;
}
const PageSecondaryHeadings: FC<PageSecondaryHeadingsProps> = ({
  title,
  description,
}) => {
  return (
    <div className="mb-8">
      <h5 className="font-medium text-xl ">{title}</h5>
      <p className="font-normal text-base text-[#667085]">{description}</p>
    </div>
  );
};

export default PageSecondaryHeadings;
