import { Link } from "react-router-dom";
import AuthPageHeader from "../components/shared/AuthPageHeader";
import { Button, buttonVariants } from "../components/ui/Button";
import { cn } from "../lib/shadcnUtils";
import { Mail, MoveLeft } from "lucide-react";
import { FC } from "react";

interface EmailSentProps {}
const EmailSent: FC<EmailSentProps> = () => {
  return (
    <div className="h-screen w-screen flex items-center  bg-white">
      <div className=" mx-auto w-[30%] ">
        <div className="flex flex-col gap-12 items-center">
          <AuthPageHeader />
          <div className="flex flex-col gap-6 items-center">
            <div className="bg-[#D4EBE2] mx-auto w-fit h-fit rounded-full border-[#EAF9EC] border-[1rem] p-4">
              <Mail className="w-[2.8rem] h-[2.8rem]" />
            </div>
            <h6 className="font-bold text-[3rem] font-manjari">
              Check your email
            </h6>
            <p className="font-normal text-center text-[#344054] text-[1.8rem]">
              We sent a password reset link to olivia@pomismail.com
            </p>

            <Button size={"lg"} className="rounded-xl text-base w-full">
              Enter Code Manually
            </Button>
            <Link
              to={"/login"}
              className={cn(
                buttonVariants({ variant: "ghost" }),
                "text-[#667085] gap-2"
              )}
            >
              <MoveLeft />
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSent;
