import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from "chart.js";
import { ChartData } from "chart.js";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import { createDashboardChartTooltip } from "../../utils/helpers/customChartTooltip";

interface AppBarChartProps {
  chartData: ChartData<"bar">;
  aspRatio?: number;
  stepSz?: number;
  xLable?: string;
  yLable?: string;
  indexAxis?: "y" | "x";
}

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);

const AppBarChart: FC<AppBarChartProps> = ({
  chartData,
  indexAxis,
  aspRatio,
}) => {
  return (
    <Bar
      data={chartData}
      options={{
        aspectRatio: aspRatio || 2,
        indexAxis: indexAxis ? indexAxis : "y",
        font: {
          family: "'Manrope', 'Helvetica', 'Arial', sans-serif",
        },

        scales: {
          x: {
            grid: {
              display: false,
            },

            ticks: {
              font: {
                size: 14,
                weight: 400,
              },
            },
          },
          y: {
            border: {
              display: false,
            },

            beginAtZero: true,
            grid: {
              drawTicks: false,
            },
          },
        },

        plugins: {
          legend: {
            display: false,
          },

          tooltip: {
            enabled: false,
            caretSize: 10,
            external: createDashboardChartTooltip,
          },
        },
      }}
    />
  );
};

export default AppBarChart;
