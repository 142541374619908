import { FC } from "react";
import CustomBreadCrumb from "../shared/CustomBreadCrumb";
import { Outlet } from "react-router-dom";
import { Home } from "lucide-react";
import { BreadcrumbSeparator } from "../ui/BreadCrumb";

interface HeaderLayoutProps {}
const HeaderLayout: FC<HeaderLayoutProps> = () => {
  return (
    <div className="bg-[#FCFCFD] p-[3.2rem]">
      <CustomBreadCrumb
        homeElement={<Home size={20} className="text-gray-500" />}
        separator={<BreadcrumbSeparator className="" />}
        activeClasses="text-primary"
        containerClasses="flex items-center gap-4 py-3 bg-trasparent text-base text-slate-700 mb-6"
        listClasses="hover:underline mx-2 font-bold"
        capitalizeLinks
      />
      <Outlet />
    </div>
  );
};

export default HeaderLayout;
