import { FC } from "react";
import { Input } from "../ui/Input";
import { Mail } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { updateUserDetails } from "../../lib/redux/slices/auth/authSlice";
import PrimaryOutlineBtns from "../shared/PrimaryOutlineBtns";
import { toast } from "../../hooks/useToast";
import { updateUserProfileDetails } from "../../lib/redux/slices/auth/authThunk";
import { AxiosError } from "axios";

interface UserProfileFormProps {
  readonly?: boolean;
  setIsEditingMode: Function;
}
const UserProfileForm: FC<UserProfileFormProps> = ({
  readonly,
  setIsEditingMode,
}) => {
  const { userProfile, isLoading } = useSelector(
    (store: RootState) => store.auth
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    dispatch(updateUserDetails({ name, value }));
  };

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!userProfile?.email || !userProfile?.name) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }

      const res = await dispatch(updateUserProfileDetails(userProfile));
      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });
      toast({
        title: "Profile Update Success",
        description: "You have Updated your profile successfully",
        variant: "success",
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 || error.response?.status === 401) {
          return toast({
            title: "Error Signing Up",
            description: error.response?.data,
            variant: "destructive",
          });
        }
      }
    }
  };
  return (
    <form  onSubmit={handleUpdate} className="space-y-6 py-12 text-base border-t border-b font-medium">
      {userProfile && (
        <>
          <div className="grid items-center grid-cols-[1fr_2fr]">
            <label htmlFor="">Name</label>
            <div className="flex gap-4 items-center w-[70%]">
              <Input
                value={userProfile.name}
                onChange={handleChange}
                readOnly={readonly}
                name="name"
                placeholder="Name"
                className=" focus-visible:ring-0 focus-visible:ring-offset-0"
              />
            </div>
          </div>

          <hr />
          <div className="grid items-center grid-cols-[1fr_2fr]">
            <label htmlFor="">Email Address</label>

            <div className="border rounded-lg flex items-center gap-3 px-6 py-4  h-18 w-[70%]">
              <Mail className="text-gray-500" />
              <Input
                value={userProfile.email}
                onChange={handleChange}
                name="email"
                readOnly={readonly}
                placeholder="Email"
                className=" focus-visible:ring-0 focus-visible:ring-offset-0 border-0 py-0 m-0"
              />
            </div>
          </div>
          <hr />
          <div className="grid items-center grid-cols-[1fr_2fr]">
            <label htmlFor="">Role</label>

            <Input
              value={userProfile.role}
              onChange={handleChange}
              name="role"
              disabled
              readOnly={true}
              placeholder="Role"
              className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
            />
          </div>

          {!readonly && (
            <div className="flex justify-end ">
              <PrimaryOutlineBtns
                primaryBtnNotLink
                isLoading={isLoading}
                primaryBtnText="Save Changes"
                primaryBtnOnclick={function(){}}
                outlineBtnText="Cancel"
                outlineBtnOnclick={() => setIsEditingMode(false)}
              />
            </div>
          )}
        </>
      )}
    </form>
  );
};

export default UserProfileForm;
