import { FC, useEffect, useState } from "react";
import TabsBox from "../components/locationManagement/molecules/TabsBox";
import PageHeader from "../components/shared/PageHeader";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import { useNavigate } from "react-router-dom";
import { demographyPageTitles } from "../constants/textConstants";
import CustomTable from "../components/shared/customTable/CustomTable";
import AgeGroupTableBody from "../components/demography/molecules/AgeGroupTableBody";
import LiteracyLevelTableBody from "../components/demography/molecules/LiteracyLevelTableBody";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";

import { IfilteOptns } from "../types/custom";
import TableContainerHeader from "../components/accessManagement/molecules/TableContainerHeader";
import OccupationTableBody from "../components/demography/molecules/OccupationTableBody";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import {
  deleteAgeGroup,
  deleteLiteracyLevel,
  deleteOccupation,
  getAllAgeGroups,
  getAllLiteracyLevels,
  getAllOccupations,
} from "../lib/redux/slices/demography/demographyThunk";
import {
  moveNextPage,
  movePrevPage,
  selectPage,
  updateDemographyStateValues,
} from "../lib/redux/slices/demography/demographySlice";
import Fuse from "fuse.js";

interface DemographyProps {
  activeIndex: number;
}
const Demography: FC<DemographyProps> = ({ activeIndex }) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<IfilteOptns>({});
  const [dataList, setDataList] = useState<
    IAgeGroup[] | ILiteracyLevel[] | IOccupation[] | null
  >([]);

  const {
    currentPage,
    numberOfPages,
    allAgeGroup,
    selectedAgeGroup,
    selectedLiteracyLevel,
    selectedOccupation,
    search,
    allLiteracyLevels,
    allOccupation,
  } = useSelector((store: RootState) => store.demography);
  const { isOpen } = useSelector((store: RootState) => store.dialog);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (activeIndex === 0) {
      setDataList(allAgeGroup);
    } else if (activeIndex === 1) {
      setDataList(allLiteracyLevels);
    } else if (activeIndex === 2) {
      setDataList(allOccupation);
    }
  }, [activeIndex, allAgeGroup, allLiteracyLevels, allOccupation]);

  useEffect(() => {
    if (search === "") {
      dispatch(
        updateDemographyStateValues({
          name: "searchList",
          value:
            activeIndex === 0
              ? allAgeGroup
              : activeIndex === 1
              ? allLiteracyLevels
              : allOccupation,
        })
      );
    }
  }, [
    activeIndex,
    allAgeGroup,
    allLiteracyLevels,
    allOccupation,
    dispatch,
    search,
  ]);

  const handleSearch = async () => {
    if (dataList) {
      const fuse = new Fuse(dataList, options);

      if (search.length === 0) {
        return;
      }

      const results = fuse.search(search);

      const items = results.map((result) => result.item);

      dispatch(
        updateDemographyStateValues({
          name: "searchList",
          value: items,
        })
      );
    }
  };

  const handledeleteAgeGroup = async () => {
    if (selectedAgeGroup) {
      await dispatch(deleteAgeGroup(selectedAgeGroup.id.toString()));
    }

    await dispatch(getAllAgeGroups());
  };

  const handledeleteLiteracyLevel = async () => {
    if (selectedLiteracyLevel) {
      await dispatch(deleteLiteracyLevel(selectedLiteracyLevel.id.toString()));
    }

    await dispatch(getAllLiteracyLevels());
  };

  const handledeleteOccupation = async () => {
    if (selectedOccupation) {
      await dispatch(deleteOccupation(selectedOccupation.id.toString()));
    }

    await dispatch(getAllOccupations());
  };

  return (
    <div>
      <PageHeader
        title={demographyPageTitles[activeIndex].name}
        subTitle="Manage Demography here."
      />
      <div className="mb-8">
        <TabsBox
          activeIndex={activeIndex}
          btnOnclick={(index) =>
            navigate(
              `/${demographyPageTitles[index].name
                .trim()
                .split(" ")
                .join("-")
                .toLowerCase()}`
            )
          }
          tabs={demographyPageTitles.map((item) => item.name)}
        />
      </div>
      <SearchFilterContainer
        handleSearch={handleSearch}
        search={search}
        setSearch={(value) =>
          dispatch(
            updateDemographyStateValues({
              name: "search",
              value,
            })
          )
        }
        filterOpts={filter}
        setFilterOptns={setFilter}
      />
      <div className="p-8 border bg-slate-50 shadow-sm rounded-xl">
        <TableContainerHeader
          btnLink="new"
          btnText={`Add ${demographyPageTitles[activeIndex].name}`}
        />
        {activeIndex === 0 ? (
          <CustomTable
            tableBody={<AgeGroupTableBody />}
            headingList={ageGroupTableHeadings}
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            handleSelectPage={(index: number) => dispatch(selectPage(index))}
            handlePrevPage={() => dispatch(movePrevPage())}
            handleNextPage={() => dispatch(moveNextPage())}
          />
        ) : activeIndex === 1 ? (
          <CustomTable
            tableBody={<LiteracyLevelTableBody />}
            headingList={literacyLevelTableHeadings}
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            handleSelectPage={(index: number) => dispatch(selectPage(index))}
            handlePrevPage={() => dispatch(movePrevPage())}
            handleNextPage={() => dispatch(moveNextPage())}
          />
        ) : (
          <CustomTable
            tableBody={<OccupationTableBody />}
            headingList={occupationTableHeadings}
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            handleSelectPage={(index: number) => dispatch(selectPage(index))}
            handlePrevPage={() => dispatch(movePrevPage())}
            handleNextPage={() => dispatch(moveNextPage())}
          />
        )}
      </div>
      {isOpen && (
        <ConfirmationDialog
          confirmModal={
            activeIndex === 0
              ? handledeleteAgeGroup
              : activeIndex === 1
              ? handledeleteLiteracyLevel
              : handledeleteOccupation
          }
        />
      )}
    </div>
  );
};

export default Demography;

const ageGroupTableHeadings = [
  {
    text: "Name",
  },
  {
    text: "Upper band",
  },
  {
    text: "Lower band",
  },

  {
    text: "",
  },
];
const literacyLevelTableHeadings = [
  {
    text: "Name",
  },
  {
    text: "Description",
  },

  {
    text: "",
  },
];
const occupationTableHeadings = [
  {
    text: "Name",
  },

  {
    text: "",
  },
];

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
