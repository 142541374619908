import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import AgeGroupForm from "../components/demography/organisms/AgeGroupForm";
import { demographyPageTitles } from "../constants/textConstants";
import CustomBadge from "../components/shared/atoms/CustomBadge";
import { DemographyStatus } from "../utils/data/demographyTableData";
import { Pencil } from "lucide-react";
import LiteracyLevelForm from "../components/demography/organisms/LiteracyLevelForm";
import OccupationForm from "../components/demography/organisms/OccupationForm";

interface ViewDemographyProps {
  activeIndex: number;
}
const ViewDemography: FC<ViewDemographyProps> = ({ activeIndex }) => {
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const navigate = useNavigate();

  const demographyFormList = [
    <AgeGroupForm
      max={max}
      setMax={setMax}
      min={min}
      setMin={setMin}
      name={name}
      setName={setName}
    />,

    <LiteracyLevelForm
      name={name}
      setName={setName}
      desc={desc}
      setDesc={setDesc}
    />,
    <OccupationForm name={name} setName={setName} />,
  ];
  return (
    <div>
      <PageHeader
        title={` ${demographyPageTitles[activeIndex].name} Details`}
        subTitle={`Manage ${demographyPageTitles[activeIndex].name} Here`}
      />
      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          primaryBtnText="Edit"
          primaryBtnLink={`edit`}
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
          primaryBtnIcon={<Pencil />}
        />
      </div>
      <div className="mb-8">
        <div className="flex gap-4">
          <h5 className="font-medium text-xl ">{`${demographyPageTitles[activeIndex].name} Info`}</h5>
          <CustomBadge status={DemographyStatus.active} />
        </div>
        <p className="font-normal text-base text-[#667085]">
          {demographyPageTitles[activeIndex].pageSecondarySubText}
        </p>
      </div>
      <hr className="mb-8 " />

      <div className="mb-20">{demographyFormList[activeIndex]}</div>
    </div>
  );
};

export default ViewDemography;
