import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../lib/redux/store";
import { TableBody, TableCell, TableRow } from "../ui/Table";
import LoaderIcon from "../shared/atoms/LoaderIcon";

interface GroupTableBodyProps {}
const GroupTableBody: FC<GroupTableBodyProps> = () => {
  const { allGroups, isLoading, pagination } = useSelector(
    (store: RootState) => store.group
  );

  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}

      {!isLoading &&
        allGroups &&
        allGroups.length > 0 &&
        pagination &&
        allGroups.map((group, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">
              {pagination.current_page * (index + 1)}
            </TableCell>

            <TableCell className="font-medium">{group.name}</TableCell>
            <TableCell className="font-medium">
              {group.group_leader_name}
            </TableCell>
            <TableCell className="font-medium">
              {group.group_sec_name}
            </TableCell>
            <TableCell>{group.state}</TableCell>
            <TableCell className="font-medium">{group.purpose}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default GroupTableBody;
