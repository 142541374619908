import { toast } from "../hooks/useToast";
import AuthPageHeader from "../components/shared/AuthPageHeader";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../lib/redux/store";
import { Checkbox } from "../components/ui/Checkbox";
import { login } from "../lib/redux/slices/auth/authThunk";

interface LoginProps {}
const Login: FC<LoginProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!email || !password) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }

      const res = await dispatch(
        login({
          email: email,
          password: password,
        })
      );
      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });
      toast({
        title: "Login Successful",
        description: "You have logged in successfully",
        variant: "success",
      });

      navigate("/");
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 || error.response?.status === 401) {
          return toast({
            title: "Error Signing Up",
            description: error.response?.data,
            variant: "destructive",
          });
        }
      }

      toast({
        title: "Error Login in",
        description: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="py-[4rem] w-screen flex items-center  bg-white">
      <div className=" mx-auto w-[30%]">
        <div className="flex flex-col items-center ">
          <AuthPageHeader />
          <h6 className="font-bold text-[3rem] font-manjari mb-[3rem]">
            Login
          </h6>
          <p className="font-normal text-[#344054] text-[1.6rem]">
            Welcome back! Please enter your details.
          </p>
          <form onSubmit={handleLogin} className="space-y-12 my-12 w-full">
            <div className="spaace-y-4">
              <label
                className="text-normal mb-3 flex  text-base text-[#344054]"
                htmlFor="email"
              >
                Email:{" "}
              </label>
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email"
              />
            </div>
            <div className="w-full">
              <label
                className="text-normal flex mb-3 text-base text-[#344054]"
                htmlFor="email"
              >
                Password:{" "}
              </label>
              <Input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your Password"
              />
            </div>

            <div className="flex justify-between items-center">
              <div className="flex  gap-4 items-center">
                <Checkbox className="w-[1.6rem] h-[1.6rem] rounded-lg border-gray-600" />
                <span className="font-medium text-base text-[#344054]">
                  Remember Password
                </span>
              </div>
              <Link
                className="text-[#41B405] font-medium text-base"
                to="/forgot-password"
              >
                Forgot password
              </Link>
            </div>

            <Button
              isLoading={isLoading}
              type="submit"
              size={"lg"}
              className="rounded-xl w-full"
            >
              Sign In
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
