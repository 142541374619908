// import { mapDataNigeria } from "@/utils/data/highMapsNg";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FC, useEffect, useState } from "react";
import mapModule from "highcharts/modules/map";
import ngMapsTopo from "../../../utils/data/ngMapsTopo.json";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { getDigitizationAnalytics } from "../../../lib/redux/slices/analytics/analyticsThunk";
import { convertToShortScale } from "../../../utils/helpers/convertToShortScale";
import { toast } from "../../../hooks/useToast";

mapModule(Highcharts);

const data = [
  ["ng-ri", "Rivers", 4.9979, 6.81969, 0],
  ["ng-kt", "Katsina", 12.5056, 7.58609, 0],
  ["ng-so", "Sokoto", 13.0603, 5.4366, 0],
  ["ng-za", "Zamfara", 12.0084, 6.12834, 0],
  ["ng-yo", "Yobe", 12.3156, 11.5881, 0],
  ["ng-ke", "Kebbi", 11.7712, 4.06548, 0],
  ["ng-ad", "Adamawa", 9.479061, 12.5768, 0],
  ["ng-bo", "Borno", 12.0019, 13.1533, 0],
  ["ng-ak", "Akwa Ibom", 4.89926, 7.90963, 0],
  ["ng-ab", "Abia", 5.57402, 7.56019, 0],
  ["ng-im", "Abia", 5.55004, 7.01557, 0],
  ["ng-by", "Bayelsa", 4.73907, 5.97421, 0],
  ["ng-be", "Benue", 7.48719, 8.70736, 0],
  ["ng-cr", "Cross River", 5.93762, 8.67873, 0],
  ["ng-ta", "Taraba", 7.93608, 10.5428, 0],
  ["ng-kw", "Kwara", 8.804449999999999, 4.46906, 0],
  ["ng-la", "Lagos", 6.43313, 3.52478, 0],
  ["ng-ni", "Niger", 9.95553, 5.45494, 0],
  ["ng-fc", "Abuja", 8.78852, 7.18889, 0],
  ["ng-og", "Ogun", 7.01845, 3.29255, 0],
  ["ng-on", "Ondo", 7.1444, 5.19165, 0],
  ["ng-ek", "Ekiti", 7.67104, 5.35929, 0],
  ["ng-os", "Osun", 7.47613, 4.54911, 0],
  ["ng-oy", "Oyo", 8.189690000000001, 3.61681, 0],
  ["ng-an", "Anambra", 6.30827, 6.98472, 0],
  ["ng-ba", "Bauchi", 10.4101, 9.88584, 0],
  ["ng-go", "Gombe", 10.4342, 11.2421, 0],
  ["ng-de", "Delta", 5.44265, 5.88195, 0],
  ["ng-ed", "Edo", 6.52416, 5.84934, 0],
  ["ng-en", "Enugu", 6.55396, 7.38408, 0],
  ["ng-eb", "Ebonyi", 6.32648, 7.98254, 0],
  ["ng-kd", "Kaduna", 10.3294, 7.43906, 0],
  ["ng-ko", "Kogi", 7.78485, 6.6142, 0],
  ["ng-pl", "Plateau", 8.95327, 9.60601, 0],
  ["ng-na", "Nassarawa", 8.50412, 8.27694, 0],
  ["ng-ji", "Jigawa", 12.0714, 9.36225, 0],
  ["ng-kn", "Jigawa", 11.7323, 8.541460000000001, 0],
];

const gpzData = [
  ["ng-za", "North West", 12.0084, 6.12834, 0],
  ["ng-yo", "North East", 12.3156, 11.5881, 0],
  ["ng-cr", "South South", 5.93762, 8.67873, 0],
  ["ng-fc", "North Central", 8.78852, 7.18889, 0],
  ["ng-oy", "South West", 8.189690000000001, 3.61681, 3],
  ["ng-an", "South East", 6.30827, 6.98472, 0],
];

interface MapChartProps {}
const MapChart: FC<MapChartProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [stateMapData, setStateMapData] = useState(data);
  // const [gpzMapData, setGpzMapData] = useState(data);

  const { digitizationAnalytics, filter } = useSelector(
    (store: RootState) => store.analytics
  );

  useEffect(() => {
    const getData = async () => {
      try {
        await dispatch(getDigitizationAnalytics());
      } catch (error) {
        if (error instanceof AxiosError) {
          if (
            error.response?.status === 400 ||
            error.response?.status === 401
          ) {
            return toast({
              title: "Error Geting Data",
              description: error.response?.data,
              variant: "destructive",
            });
          }
        }

        toast({
          title: "Error Geting Data",
          description: "Something went wrong",
          variant: "destructive",
        });
      }
    };

    getData();
  }, [dispatch]);

  useEffect(() => {
    setStateMapData(
      data.map((stateInfo) => {
        digitizationAnalytics?.state_counts.forEach((item) => {
          if (stateInfo[1] === item.state_name) {
            stateInfo[4] = convertToShortScale(item.total);
          }
        });
        return stateInfo;
      })
    );
  }, [digitizationAnalytics]);

  const mapOptions = {
    title: {
      text: "",
    },
    chart: {
      map: ngMapsTopo,
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    plotOptions: {
      mappoint: {
        keys: ["id", "name", "lat", "lon", "value"],
        marker: {
          lineWidth: 10,
          fillColor: "#05711C",
          lineColor: "#22974533",
          symbol: "circle",
          radius: 6,
        },
        dataLabels: {
          enabled: filter === "State" ? false : true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat:
        '<span style="color:{point.color}">\u25CF</span> ' +
        "<span  style='color: gray;'>{point.key}:</span><br/>",
      pointFormat: `<span style='display:inline-block; color: #F55425; font-weight: bold; margin-top:1rem'>{point.value} Respondant</span></b>`,

      className: "map__tooltip",
      padding: 14,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        allAreas: true,
        name: "Basemap",
        borderColor: "#05711C",
        nullColor: "#BBD9C2",
        showInLegend: false,
      },

      {
        name: "States",
        borderColor: "#05711C",
        color: "#BBD9C2",
        data: filter === "State" ? stateMapData : gpzData,
        type: "mappoint",
      },
    ],
  };

  return (
    <div className="w-full h-full">
      <HighchartsReact
        options={mapOptions}
        constructorType={"mapChart"}
        highcharts={Highcharts}
      />
    </div>
  );
};

export default MapChart;
