import { CircleX, Eye, Pencil } from "lucide-react";
import { FC, useEffect, useRef, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { openModal } from "../../../lib/redux/slices/dialogSlice";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { Button } from "../../ui/Button";
import { setSelectedLga } from "../../../lib/redux/slices/geolocation/locationSlice";
import { SlOptionsVertical } from "react-icons/sl";
import OptsDropdown from "../../shared/OptsDropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import { getAllLGA } from "../../../lib/redux/slices/geolocation/locationThunk";
import LoaderIcon from "../../../components/shared/atoms/LoaderIcon";

interface LGATableBodyProps {}
const LGATableBody: FC<LGATableBodyProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const ref = useRef(null);
  const dispatch = useDispatch<AppDispatch>();

  const { allLgas, isLoading } = useSelector(
    (store: RootState) => store.geolocation
  );

  useEffect(() => {
    if (!allLgas) {
      dispatch(getAllLGA());
    }
  }, [allLgas, dispatch]);

  useOnClickOutside(ref, () => setSelectedId(null));

  const navigate = useNavigate();

  const lGATableOptions = [
    {
      id: "1",
      icon: <Pencil />,
      text: "Edit",
      btnOnclick: function (gpzId: string) {
        navigate(`${gpzId}/edit`);
      },
    },
    {
      id: "2",
      icon: <Eye />,
      text: "View",
      btnOnclick: function (gpzId: string) {
        navigate(`${gpzId}`);
      },
    },

    {
      id: "4",
      icon: <CircleX />,
      text: "Disable",
      btnOnclick: function () {
        dispatch(
          openModal({
            icon: <IoWarningOutline size={28} className="text-[#DC6803]" />,
            iconBg: "bg-[#FEF0C7]",
            iconBorderColor: "border-[#FFFAEB]",
            iconColor: "text-[#DC6803]",
            primaryColor: "bg-[#D83535]",
            title: "Delete LGA Record",
            description:
              "When you click on confirm, LGA Record will be automatically deleted",
          })
        );
      },
    },
  ];
  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {!isLoading &&
        allLgas &&
        allLgas.length > 0 &&
        allLgas.map((lga, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">
              {lga.geo_political_zone_id}
            </TableCell>
            <TableCell className="font-medium">{lga.state.name}</TableCell>
            <TableCell className="font-medium">{lga.name}</TableCell>

            <TableCell className="relative">
              <Button
                onClick={() => {
                  setSelectedId(lga.id);
                  dispatch(
                    setSelectedLga(allLgas.find((item) => item.id === lga.id))
                  );
                }}
                variant={"ghost"}
              >
                <SlOptionsVertical />
              </Button>
              {selectedId === lga.id && (
                <div ref={ref}>
                  <OptsDropdown
                    options={lGATableOptions}
                    selectedId={selectedId}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default LGATableBody;
