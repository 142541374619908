import { RootState } from "../../../lib/redux/store";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TitledButtonHeading from "../molecules/TitledButtonHeading";
import AppBarChart from "../../charts/AppBarChart";
import { generateCSVFile } from "../../../utils/helpers/generateCSVFile";

interface OccupationChartProps {}

const OccupationChart: FC<OccupationChartProps> = () => {
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<number[]>([]);

  const { digitizationAnalytics } = useSelector(
    (store: RootState) => store.analytics
  );

  useEffect(() => {
    if (digitizationAnalytics) {
      setLabels(
        digitizationAnalytics.occupation_counts.map(
          (item) => item.occupation_name
        )
      );
      setDatasets(
        digitizationAnalytics.occupation_counts.map((item) => item.total)
      );
    }
  }, [digitizationAnalytics]);
  return (
    <div className="p-8 bg-backgroundLight rounded-lg mb-8 border border-[#EAECF0] cardShadow">
      <TitledButtonHeading
        title="Occupation-wise Distribution"
        buttonText="Response Report"
        dowloadLink={
          digitizationAnalytics?.occupation_counts
            ? generateCSVFile([...digitizationAnalytics?.occupation_counts])
            : ""
        }
      />
      <hr className="my-8" />

      
      <AppBarChart
        aspRatio={4}
        chartData={{
          labels: labels,
          datasets: [
            {
              label: "Occuption",
              data: datasets,
              backgroundColor: "#00AA55",
              borderColor: "#41B405",
              borderWidth: 0,
              borderRadius: 10,
              barPercentage: 0.4,
              categoryPercentage:1 ,
            },
          ],
        }}
      />
    </div>
  );
};

export default OccupationChart;
