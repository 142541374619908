import { FC, useEffect, useState } from "react";
import { Pencil } from "lucide-react";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import PageHeader from "../components/shared/PageHeader";
import { useNavigate } from "react-router-dom";
import GPZForm from "../components/locationManagement/organisms/GPZForm";
import StateForm from "../components/locationManagement/organisms/StateForm";
import { locationPageTitles } from "../constants/textConstants";
import LGAForm from "../components/locationManagement/organisms/LGAForm";
import SettlementsForm from "../components/locationManagement/organisms/SettlementsForm";
import { RootState } from "../lib/redux/store";
import { useSelector } from "react-redux";

interface ViewLocationProps {
  activeIndex: number;
}
const ViewLocation: FC<ViewLocationProps> = ({ activeIndex }) => {
  const [state_id, setState_Id] = useState("");
  const [settlement_id, setSettlement_Id] = useState("");
  const [name, setName] = useState("");
  const [geo_political_zone_id, setGeo_political_zone_id] = useState("");
  const [desc, setDesc] = useState("");

  const { selectedLga, selectedSettlement } = useSelector(
    (store: RootState) => store.geolocation
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (activeIndex === 2 && selectedLga) {
      setName(selectedLga?.name);
      setState_Id(selectedLga?.state_id as string);
      setGeo_political_zone_id(selectedLga?.geo_political_zone_id as string);
      setSettlement_Id(selectedLga?.settlement_id as string);
    }
    if (activeIndex === 3 && selectedSettlement) {
      setName(selectedSettlement?.name as string);
      setDesc(selectedSettlement?.desc as string);
    }
  }, [activeIndex, selectedLga, selectedSettlement]);

  const locationFormList = [
    <GPZForm readonly />,
    <StateForm readonly />,
    <LGAForm
      readonly
      geo_political_zone_id={geo_political_zone_id}
      setGeo_political_zone_id={setGeo_political_zone_id}
      setSettlement_Id={setSettlement_Id}
      settlement_id={settlement_id}
      setState_Id={setState_Id}
      state_id={state_id}
      name={name}
      setName={setName}
    />,
    <SettlementsForm
      readonly
      name={name}
      setName={setName}
      desc={desc}
      setDesc={setDesc}
    />,
  ];

  return (
    <div>
      <PageHeader
        title={` ${locationPageTitles[activeIndex].name} Details`}
        subTitle={`Manage ${locationPageTitles[activeIndex].name} Here`}
      />
      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          primaryBtnText="Edit"
          primaryBtnLink={`edit`}
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
          primaryBtnIcon={<Pencil />}
        />
      </div>
      <div className="mb-8">
        <div className="flex gap-4">
          <h5 className="font-medium text-xl ">{`${locationPageTitles[activeIndex].name} Info`}</h5>
        </div>
        <p className="font-normal text-base text-[#667085]">
          {locationPageTitles[activeIndex].pageSecondarySubText}
        </p>
      </div>
      <hr className="mb-8 " />

      <div className="mb-20">{locationFormList[activeIndex]}</div>
    </div>
  );
};

export default ViewLocation;
