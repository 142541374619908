import axios, { AxiosError } from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";

const baseUrl = "https://api.database.noa.gov.ng/api";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAllAgencies = createAppAsyncThunk(
  "mda/getAllAgencies",
  async (_, thunkAPI) => {
    const { limit } = thunkAPI.getState().mda;
    try {
      const res = await axiosInstance.get(`${baseUrl}/agency`);

      const numberOfPages = Math.ceil(res.data?.data?.agencies.length / limit);

      return { data: res.data.data.agencies, numberOfPages };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not get all Agencies");
    }
  }
);

export const addNewAgency = createAppAsyncThunk(
  "mda/addNewAgency",
  async (data: Omit<IAgencies, "ministry">, thunkAPI) => {
    try {
      await axiosInstance.post(
        `${baseUrl}/agency/create`,

        data
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Add New Agency");
    }
  }
);
export const updateAgency = createAppAsyncThunk(
  "mda/addNewAgency",
  async (data: Omit<IAgencies, "ministry">, thunkAPI) => {
    try {
      await axiosInstance.post(
        `${baseUrl}/agency/update`,

        {
          ...data,
          agency_id: data.id.toString(),
          ministry_id: data.ministry_id.toString(),
        }
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Update Agency");
    }
  }
);

export const deleteAgency = createAppAsyncThunk(
  "mda/deleteAgency",
  async (agencyId: string, thunkAPI) => {
    try {
      await axiosInstance.delete(`${baseUrl}/agency/delete/${agencyId}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Delete Agency");
    }
  }
);

export const getAllMinistries = createAppAsyncThunk(
  "mda/getAllMinistries",
  async (_, thunkAPI) => {
    const { limit } = thunkAPI.getState().mda;
    try {
      const res = await axiosInstance.get(`${baseUrl}/ministry`);

      const numberOfPages = Math.ceil(res.data?.data?.ministry.length / limit);
      return { data: res.data.data.ministry, numberOfPages };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not get all Ministry");
    }
  }
);
