import { FC } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import { UserRole, UserStatus } from "../../utils/data/tableData";
import { Button } from "../ui/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../lib/redux/store";
import { IfilteOptns } from "../../types/custom";

interface FilterPopOverProps {
  filterOpts: IfilteOptns;
  handleFilter?: Function;
  clearFilters?: Function;
  setFilterOptns: React.Dispatch<React.SetStateAction<IfilteOptns>>;
}

const FilterPopOver: FC<FilterPopOverProps> = ({
  filterOpts,
  setFilterOptns,
  handleFilter,
  clearFilters,
}) => {
  const { allGPZ, allStates } = useSelector(
    (store: RootState) => store.geolocation
  );
  const { allMinistries } = useSelector((store: RootState) => store.mda);

  const hanldelOnchange = (name: any, value: string) => {
    setFilterOptns({ ...filterOpts, [name]: value });
  };

  return (
    <div className=" p-12  bg-white z-20 space-y-8  w-[36rem]">
      <h4 className="font-medium text-[2.8rem] text-[#3F434A]">Filter</h4>
      {filterOpts.role !== undefined && (
        <div className="space-y-3">
          <label className="font-normal text-base" htmlFor="">
            Role
          </label>

          <Select
            value={filterOpts.role}
            onValueChange={(value) => hanldelOnchange("role", value)}
          >
            <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
              <SelectValue placeholder="-Select Role-" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(UserRole).map((item, index) => (
                <SelectItem className="text-base" key={index} value={item}>
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      {filterOpts.zone !== undefined && (
        <div className="space-y-3">
          <label className="font-normal text-base" htmlFor="">
            Zone
          </label>

          <Select
            value={filterOpts.role}
            onValueChange={(value) => hanldelOnchange("zone", value)}
          >
            <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
              <SelectValue placeholder="-Select Role-" />
            </SelectTrigger>
            <SelectContent>
              {allGPZ &&
                allGPZ.map((item, index) => (
                  <SelectItem key={index} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
      )}
      {filterOpts.state !== undefined && (
        <div className="space-y-3">
          <label className="font-normal text-base" htmlFor="">
            State
          </label>

          <Select
            value={filterOpts.role}
            onValueChange={(value) => hanldelOnchange("state", value)}
          >
            <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
              <SelectValue placeholder="-Select Role-" />
            </SelectTrigger>
            <SelectContent>
              {allStates &&
                allStates.map((item, index) => (
                  <SelectItem key={index} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
      )}
      {filterOpts.ministry !== undefined && (
        <div className="space-y-3">
          <label className="font-normal text-base" htmlFor="">
            Ministry
          </label>

          <Select
            value={filterOpts.ministry}
            onValueChange={(value) => hanldelOnchange("ministry", value)}
          >
            <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
              <SelectValue placeholder="-Select Ministry-" />
            </SelectTrigger>
            <SelectContent>
              {allMinistries &&
                allMinistries.map((item, index) => (
                  <SelectItem key={index} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {filterOpts.status !== undefined && (
        <div className="space-y-3">
          <label className="font-normal text-base" htmlFor="status">
            Status
          </label>
          <Select
            name="status"
            value={filterOpts.status as string}
            onValueChange={(value) => hanldelOnchange("status", value)}
          >
            <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
              <SelectValue placeholder="-Select Status-" />
            </SelectTrigger>
            <SelectContent>
              {Object.keys(UserStatus)
                .filter((k) => isNaN(Number(k)))
                .map((item, index) => (
                  <SelectItem key={index} value={item === "active" ? "1" : "0"}>
                    {item}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
        </div>
      )}

      <div className="flex justify-between gap-6 items-center mt-6">
        <Button
          className="bg-rose-200 border-rose-500 text-rose-500 hover:bg-rose-300"
          onClick={() => clearFilters && clearFilters()}
        >
          Clear
        </Button>
        <Button onClick={() => handleFilter && handleFilter()}>Save</Button>
      </div>
    </div>
  );
};

export default FilterPopOver;
