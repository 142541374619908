import AuthPageHeader from "../components/shared/AuthPageHeader";
import { FC, useState } from "react";
import { Key, MoveLeft } from "lucide-react";
import { Input } from "../components/ui/Input";
import { Button, buttonVariants } from "../components/ui/Button";
import { Link, Navigate } from "react-router-dom";
import { cn } from "../lib/shadcnUtils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/lib/redux/store";
import { toast } from "../hooks/useToast";
import {
  IResetPassword,
  resetPassword,
} from "../lib/redux/slices/auth/authThunk";

interface ResetPasswordProps {}
const ResetPassword: FC<ResetPasswordProps> = () => {
  const { otp, isLoading } = useSelector((store: RootState) => store.auth);
  const dispatch = useDispatch<AppDispatch>();

  const [passwordInput, setPasswordsInput] = useState<IResetPassword>({
    password: "",
    otp: otp,
    password_confirmation: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setPasswordsInput({ ...passwordInput, [name]: value });
  };

  const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!passwordInput.password || !passwordInput.password_confirmation) {
        return toast({
          title: "Incomplete Details",
          description: "Please enter all Fields",
          variant: "destructive",
        });
      }
      if (passwordInput.password !== passwordInput.password_confirmation) {
        return toast({
          title: "Your Password do not Match",
          description: "Please Ensure your Passwords Match ",
          variant: "destructive",
        });
      }

      const res = await dispatch(resetPassword(passwordInput));
      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });
      return toast({
        title: "Password Reset Successful",
        description: "You have successfully reset your password",
        variant: "success",
      });
    } catch (error) {
      console.log(error, "error");
      return toast({
        title: "Something went wrong",
        description: "Entry trying to reset Password ",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      {!otp && <Navigate to={"/auth-code"} />}
      <div className="py-[5rem] w-screen flex items-center  bg-white">
        <div className=" mx-auto w-[30%] ">
          <div className="flex flex-col gap-6 items-center">
            <AuthPageHeader />
            <div className="bg-[#D4EBE2] mx-auto w-fit h-fit rounded-full border-[#EAF9EC] border-[1rem] p-4">
              <Key className="w-[2.8rem] h-[2.8rem]" />
            </div>
            <h6 className="font-bold text-[3rem] font-manjari mb-[3rem]">
              Reset password
            </h6>
            <p className="font-normal text-[#344054] text-[1.6rem]">
              As a first time user we’ll send you reset instructions.
            </p>

            <form
              onSubmit={handleResetPassword}
              className="space-y-6 mt-12 w-full"
            >
              <div className="spaace-y-4 mb-4">
                <label
                  className="text-normal mb-3 flex  text-base text-[#344054]"
                  htmlFor="newPassword"
                >
                  New Password:
                </label>
                <Input
                  onChange={handleChange}
                  value={passwordInput.password}
                  type="password"
                  name="password"
                  className="h-20"
                  placeholder="New Password"
                />
              </div>
              <div className="spaace-y-4 mb-4">
                <label
                  className="text-normal mb-3 flex  text-base text-[#344054]"
                  htmlFor="email"
                >
                  Confirm Password:
                </label>
                <Input
                  onChange={handleChange}
                  value={passwordInput.password_confirmation}
                  className="h-20"
                  type="password"
                  name="password_confirmation"
                  placeholder="Confirm Password"
                />
              </div>

              <Button
                type="submit"
                isLoading={isLoading}
                size={"lg"}
                className="rounded-xl w-full"
              >
                Reset Password
              </Button>
            </form>
            <Link
              to={"/login"}
              className={cn(
                buttonVariants({ variant: "ghost" }),
                "text-[#667085] gap-2"
              )}
            >
              <MoveLeft />
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
