import { Search } from "lucide-react";
import { FC } from "react";
import { Input } from "../../ui/Input";
import { Button } from "../../ui/Button";
import { IoClose } from "react-icons/io5";

interface SearchbarProps {
  showFullBorder?: boolean;
  placeholderText?: string;
  search: string;
  handleSearch: Function;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}
const Searchbar: FC<SearchbarProps> = ({
  showFullBorder,
  placeholderText,
  search,
  setSearch,
  handleSearch,
}) => {
  const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch();
  };
  return (
    <form
      onSubmit={submitSearch}
      className="flex items-center gap-4 h-full w-full"
    >
      <div
        className={`flex items-center w-full shadow-sm border border-l-0 rounded-tr-lg rounded-br-lg h-full p-2 ${
          showFullBorder && "border-l rounded-xl"
        }`}
      >
        <Search className="text-gray-500 " />
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholderText || "Search"}
          className="border-0  focus-visible:ring-0 focus-visible:ring-offset-0"
        />
        {search && (
          <Button
            type="button"
            onClick={() => setSearch("")}
            variant={"ghost"}
            className="hover:bg-transparent"
          >
            <IoClose size={20} className="text-rose-600" />
          </Button>
        )}
      </div>
      <Button
        type="submit"
        className="bg-[#263238] h-full  border border-[#9A9AA6]"
      >
        Search
      </Button>
    </form>
  );
};

export default Searchbar;
