import { FC, useRef, useState } from "react";
import { Button } from "../../ui/Button";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { Separator } from "../../ui/Seperator";
import { ChevronDown } from "lucide-react";

interface TableFilterProps {
  filterList: string[];
}
const TableFilter: FC<TableFilterProps> = ({ filterList }) => {
  const [selected, setSelected] = useState(filterList[0]);
  const [showOpts, setShowOpts] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowOpts(false));

  const handleOnSelect = (filter: string) => {
    setSelected(filter);
    setShowOpts(false);
  };
  return (
    <div ref={ref} className="relative min-w-[16.5rem]">
      <div
        onClick={() => setShowOpts(true)}
        className="flex h-[5.3rem] bg-primary rounded-tl-lg rounded-bl-lg"
      >
        <Button className="w-full  h-full ">{selected}</Button>
        <Separator orientation="vertical" />
        <Button className=" h-full ">
          <ChevronDown size={20} />
        </Button>
      </div>

      {showOpts && (
        <div className=" absolute w-full p-4 shadow-md rounded-lg bg-white">
          {filterList.map((filter, index) => (
            <Button
              variant={"ghost"}
              key={index}
              onClick={() => handleOnSelect(filter)}
              className="hover:bg-[#C0F2DD] w-full text-left"
            >
              {filter}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableFilter;
