import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import { CircleX, Eye, Pencil } from "lucide-react";
import { openModal } from "../../../lib/redux/slices/dialogSlice";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { Button } from "../../ui/Button";
import {
  setSelectedOccupation,
  updateDemographyStateValues,
} from "../../../lib/redux/slices/demography/demographySlice";
import { SlOptionsVertical } from "react-icons/sl";
import OptsDropdown from "../../shared/OptsDropdown";
import { IoWarningOutline } from "react-icons/io5";
import { getAllOccupations } from "../../../lib/redux/slices/demography/demographyThunk";
import LoaderIcon from "../../../components/shared/atoms/LoaderIcon";

interface OccupationTableBodyProps {}
const OccupationTableBody: FC<OccupationTableBodyProps> = () => {
  const [selectedOccupationId, setOccupationId] = useState<string | null>(null);
  const [occupationData, setOccupationData] = useState<IOccupation[] | null>(
    null
  );

  const { allOccupation, searchList, search, isLoading } = useSelector(
    (store: RootState) => store.demography
  );

  const ref = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  useOnClickOutside(ref, () => setOccupationId(null));
  const navigate = useNavigate();

  useEffect(() => {
    if (!allOccupation) {
      dispatch(getAllOccupations());
    }
  }, [allOccupation, dispatch]);

  useEffect(() => {
    if (search && searchList) {
      setOccupationData(searchList as IOccupation[]);
    } else {
      setOccupationData(allOccupation);
    }
  }, [allOccupation, search, searchList]);

  useEffect(() => {
    const resetSearch = () => {
      dispatch(
        updateDemographyStateValues({
          name: "search",
          value: "",
        })
      );
      dispatch(
        updateDemographyStateValues({
          name: "searchList",
          value: null,
        })
      );
    };
    resetSearch();
    return () => {
      resetSearch();
    };
  }, [dispatch]);

  const occupationTableOptions = [
    {
      id: "1",
      icon: <Pencil />,
      text: "Edit",
      btnOnclick: function (gpzId: string) {
        navigate(`${gpzId}/edit`);
      },
    },
    {
      id: "2",
      icon: <Eye />,
      text: "View",
      btnOnclick: function (gpzId: string) {
        navigate(`${gpzId}`);
      },
    },

    {
      id: "3",
      icon: <CircleX />,
      text: "Delete",
      btnOnclick: function () {
        dispatch(
          openModal({
            icon: <IoWarningOutline size={28} className="text-[#DC6803]" />,
            iconBg: "bg-[#FEF0C7]",
            iconBorderColor: "border-[#FFFAEB]",
            iconColor: "text-[#DC6803]",
            primaryColor: "bg-[#D83535]",
            title: "Delete Occupation Record",
            description:
              "When you click on confirm, The Occupation will be automatically deletedz",
          })
        );
      },
    },
  ];

  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {!isLoading &&
        occupationData &&
        occupationData.length > 0 &&
        occupationData.map((occupation, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">{occupation.name}</TableCell>

            <TableCell className="relative">
              <Button
                onClick={() => {
                  setOccupationId(occupation.id.toString());
                  dispatch(
                    setSelectedOccupation(
                      occupationData.find((item) => item.id === occupation.id)
                    )
                  );
                }}
                variant={"ghost"}
              >
                <SlOptionsVertical />
              </Button>
              {selectedOccupationId === occupation.id.toString() && (
                <div ref={ref}>
                  <OptsDropdown
                    options={occupationTableOptions}
                    selectedId={selectedOccupationId.toString()}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}

      {!isLoading && occupationData && occupationData.length === 0 && (
        <TableRow>
          <TableCell rowSpan={0}>
            <h4 className="p-8 text-base font-semibold flex justify-center ">
              No Occupation to Display
            </h4>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default OccupationTableBody;
