export enum GroupStatus {
  active = "Active",
  inactive = "Inactive",
}

export const groupData = [
  {
    id: "1",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "2",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "3",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "4",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "5",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "6",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "7",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
  {
    id: "8",
    nameOfGroup: "David Johnson",
    nameOfGroupLeader: "David Johnson",
    nameOfGroupSec: "David Johnson",
    state: "David Johnson",
    purpose: "David Johnson",
    status: GroupStatus.active,
  },
];
