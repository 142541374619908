import BiodataTableBody from "../components/biodata/BiodataTableBody";
import PageHeader from "../components/shared/PageHeader";
import CustomTable from "../components/shared/customTable/CustomTable";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import {
  moveNextPage,
  movePrevPage,
  selectPage,
  updateBiodataStateValues,
} from "../lib/redux/slices/biodata/biodataSlice";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import BiodataActionsPopover from "../components/biodata/BiodataActionsPopover";
import { IBiodataFilterOptns } from "../types/custom";
import {
  getAllBioData,
  getFilteredBioData,
} from "../lib/redux/slices/biodata/biodataThunk";
import Fuse from "fuse.js";

interface BioDataProps {}

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["first_name", "last_name	", "other_name", "email"],
};

const initFilter = {
  gender: "",
  state_id: "",
  geo_political_zone_id: "",
  age_group_id: "",
  literacy_level_id: "",
};

const BioData: FC<BioDataProps> = () => {
  const [search, setSearch] = useState("");
  const [filterOpts, setFilterOptns] =
    useState<IBiodataFilterOptns>(initFilter);

  const { currentPage, numberOfPages, allBioData } = useSelector(
    (store: RootState) => store.biodata
  );

  const dispatch = useDispatch<AppDispatch>();

  const handleFilterBiodata = async () => {
    try {
      await dispatch(getFilteredBioData(filterOpts));
    } catch (error) {
      console.log(error);
    }
  };

  const clearFilterBiodata = async () => {
    try {
      setFilterOptns(initFilter);
      await dispatch(getAllBioData());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      return await dispatch(getAllBioData());
    };
    if (search.length === 0) {
      getData();
    }
  }, [dispatch, search]);

  const handleSearch = () => {
    if (allBioData) {
      const fuse = new Fuse(allBioData, options);

      if (search.length === 0) {
        dispatch(getAllBioData());
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateBiodataStateValues({
          name: "allBioData",
          value: items,
        })
      );
    }
  };

  return (
    <div>
      <PageHeader
        title="Bio Data"
        subTitle="Manage National Database Bio Data"
      />

      <SearchFilterContainer
        handleSearch={handleSearch}
        setBioFilterOptns={setFilterOptns}
        search={search}
        setSearch={setSearch}
        bioFilterOpts={filterOpts}
        handleFilter={handleFilterBiodata}
        clearFilters={clearFilterBiodata}
      />

      <div className="border border-[#EAECF0] p-8 shadow-sm rounded-lg bg-white ">
        <div className="flex justify-end items-center mb-8">
          <BiodataActionsPopover />
        </div>

        <CustomTable
          tableBody={<BiodataTableBody />}
          headingList={nationalDBTableHeadings}
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          handleSelectPage={(index: number) => dispatch(selectPage(index))}
          handlePrevPage={() => dispatch(movePrevPage())}
          handleNextPage={() => dispatch(moveNextPage())}
        />
      </div>
    </div>
  );
};

export default BioData;

const nationalDBTableHeadings = [
  {
    text: "Name",
  },
  {
    text: "Email",
  },

  {
    text: "Phone No.",
  },

  {
    text: "State",
  },

  {
    text: "Occupation",
  },
  {
    text: "Association",
  },
  {
    text: "",
  },
];
