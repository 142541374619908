import { FC, useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/Button";
import { BiodataTableWithCheckBox } from "./BiodataTableWithCheckBox";
import { getAllBioData } from "../../lib/redux/slices/biodata/biodataThunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../lib/redux/store";
import { CircleX } from "lucide-react";
import { IBiodataFilterOptns } from "../../types/custom";

interface RecipientFilterDialogProps {
  setFilterOptns: React.Dispatch<React.SetStateAction<IBiodataFilterOptns>>;
  filterOptns: IBiodataFilterOptns;
}

const RecipientFilterDialog: FC<RecipientFilterDialogProps> = ({
  filterOptns,
  setFilterOptns,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getAllBioData());
  }, [dispatch]);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Dialog open={openDialog}>
      <DialogTrigger className="font-base h-full">
        <Button
          onClick={() => setOpenDialog(!openDialog)}
          type="button"
          variant={"ghost"}
          className="space-x-4 px-0 w-full justify-start"
        >
          <span className="text-green-500 font-semibold">Select recipient</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[90%] py-12 ">
        <div className="relative">
          <div className="flex mb-2 justify-end">
            <Button
              variant={"ghost"}
              className="text-rose-500"
              onClick={() => setOpenDialog(false)}
            >
              Close
              <CircleX />
            </Button>
          </div>
          <BiodataTableWithCheckBox
            setOpenDialog={setOpenDialog}
            filterOptns={filterOptns}
            setFilterOptns={setFilterOptns}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RecipientFilterDialog;
