import { FC } from "react";
import { Textarea } from "../../ui/TextArea";
import { Input } from "../../ui/Input";
import { IROle } from "../../../types/role";

interface RoleFormProps {
  roleDetails: Omit<IROle, "id">;
  handleInputChange: React.ChangeEventHandler | undefined;
  readonly?: boolean;
}
const RoleForm: FC<RoleFormProps> = ({
  roleDetails,
  handleInputChange,
  readonly,
}) => {
  return (
    <div className="space-y-6 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Title</label>

        <Input
          value={roleDetails.title}
          onChange={handleInputChange}
          name="title"
          readOnly={readonly}
          placeholder="Title"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>

      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Description</label>

        <Textarea
          value={roleDetails.description}
          onChange={handleInputChange}
          name="description"
          readOnly={readonly}
          placeholder="Description"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
    </div>
  );
};

export default RoleForm;
