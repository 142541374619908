import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAllUsers, getAllRoles, updateUserRole } from "./accessMgmtThunk";
import { IUser } from "../../../../types/users";
import { IROle } from "../../../../types/role";

interface AccessMgmtSliceState {
  [key: string]: any;
  selectedUser: IUser | null;
  selectedRole: IROle | null;
  allUsers: IUser[] | null;
  allRoles: IROle[] | null;
  currentPage: number;
  isLoading: boolean;
  isFiltering: boolean;
  isSearching: boolean;
  searchList: IUser[] | null;
  filterList: IUser[] | null;
  limit: number;
  error?: string;
  numberOfPages: number;
}

interface UpdateStatePayload {
  name: keyof AccessMgmtSliceState;
  value: any;
}

const initialState: AccessMgmtSliceState = {
  selectedUser: null,
  selectedRole: null,
  allUsers: null,
  allRoles: null,
  isFiltering: false,
  isSearching: false,
  filterList: null,
  searchList: null,
  currentPage: 1,
  isLoading: false,
  limit: 10,
  numberOfPages: 0,
};

const AccessMgmtSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    updateStateValues: (state, action: PayloadAction<UpdateStatePayload>) => {
      state[action.payload.name] = action.payload.value;
    },
    selectPage: (state, action) => {
      if (action.payload <= state.numberOfPages && action.payload >= 0) {
        state.currentPage = action.payload;
      }
    },
    moveNextPage: (state) => {
      if (state.currentPage < state.numberOfPages) {
        state.currentPage += 1;
      }
    },
    movePrevPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRoles = action.payload.data.roles;
        state.numberOfPages = action.payload.data.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateUserRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateUserRole.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.allUsers = action.payload.users;
        state.numberOfPages = action.payload.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  setSelectedUser,
  setSelectedRole,
  updateStateValues,
  selectPage,
  moveNextPage,
  movePrevPage,
} = AccessMgmtSlice.actions;

export default AccessMgmtSlice.reducer;
