import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { TableBody, TableCell, TableRow } from "../ui/Table";
import LoaderIcon from "../shared/atoms/LoaderIcon";
import { getAllBioData } from "../../lib/redux/slices/biodata/biodataThunk";
import { Eye, Pen } from "lucide-react";
import OptsDropdown from "../shared/OptsDropdown";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Button } from "../ui/Button";
import {
  setSelectedBiodata,
  updateBiodataStateValues,
} from "../../lib/redux/slices/biodata/biodataSlice";
import { SlOptionsVertical } from "react-icons/sl";

interface BiodataTableBodyProps {
  fromDialog?: boolean;
}
const BiodataTableBody: FC<BiodataTableBodyProps> = ({ fromDialog }) => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const { allBioData, limit, currentPage, isLoading } = useSelector(
    (store: RootState) => store.biodata
  );

  const ref = useRef(null);

  const [skip, setSkip] = useState(0);

  const navigate = useNavigate();

  useOnClickOutside(ref, () => setSelectedUserId(null));

  useEffect(() => {
    dispatch(getAllBioData());
  }, [dispatch]);

  useEffect(() => {
    setSkip((currentPage - 1) * limit);
  }, [currentPage, limit]);

  const biodataTableOptns = [
    {
      id: "2",
      icon: <Eye />,
      text: "View Details",
      btnOnclick: function (userId: string) {
        navigate(`${userId}`);
      },
    },
    {
      id: "3",
      icon: <Pen />,
      text: "Edit Details",
      btnOnclick: function (userId: string) {
        dispatch(
          updateBiodataStateValues({ name: "isEditBiodata", value: true })
        );
        navigate(`${userId}`);
      },
    },
  ];

  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {allBioData && allBioData?.length === 0 && (
        <h3 className="text-center">No Data to Display</h3>
      )}
      {!isLoading &&
        allBioData &&
        allBioData.length > 0 &&
        allBioData.slice(skip, skip + limit).map((biodata, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">
              {biodata.last_name} {biodata.first_name}
            </TableCell>
            <TableCell className="font-medium">{biodata.email}</TableCell>
            <TableCell>{biodata.phone_number}</TableCell>
            <TableCell className="font-medium">{biodata.state?.name}</TableCell>
            <TableCell className="font-medium">
              {biodata.occupation?.name}
            </TableCell>
            <TableCell className="font-medium">{biodata.association}</TableCell>
            {!fromDialog && (
              <TableCell>
                <Button
                  onClick={() => {
                    setSelectedUserId(biodata?.id?.toString() || null);
                    dispatch(setSelectedBiodata(allBioData[index]));
                  }}
                  variant={"ghost"}
                >
                  <SlOptionsVertical />
                </Button>
              </TableCell>
            )}

            {selectedUserId === biodata?.id?.toString() && (
              <div ref={ref}>
                <OptsDropdown
                  options={biodataTableOptns}
                  selectedId={selectedUserId}
                />
              </div>
            )}
          </TableRow>
        ))}
    </TableBody>
  );
};

export default BiodataTableBody;
