import { FC, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { Input } from "../../ui/Input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import {
  getAllGPZ,
  getAllSettlements,
  getAllStates,
} from "../../../lib/redux/slices/geolocation/locationThunk";

interface LGAFormProps {
  readonly?: boolean;
  geo_political_zone_id: string;
  setGeo_political_zone_id: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  settlement_id: string;
  setSettlement_Id: React.Dispatch<React.SetStateAction<string>>;
  state_id: string;
  setState_Id: React.Dispatch<React.SetStateAction<string>>;
}

const LGAForm: FC<LGAFormProps> = ({
  readonly,
  geo_political_zone_id,
  setGeo_political_zone_id,
  name,
  setName,
  settlement_id,
  setSettlement_Id,
  state_id,
  setState_Id,
}) => {
  const { allGPZ, allStates, allSettlements } = useSelector(
    (store: RootState) => store.geolocation
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!allGPZ) {
          await dispatch(getAllGPZ());
        }
        if (!allStates) {
          await dispatch(getAllStates());
        }
        if (!allSettlements) {
          await dispatch(getAllSettlements());
        }
      } catch (error) {}
    };

    getData();
  }, [allGPZ, allSettlements, allStates, dispatch]);

  return (
    <div className="space-y-8 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">Geo Political Zone</label>

        <Select
          disabled={readonly}
          value={geo_political_zone_id}
          onValueChange={(value) => setGeo_political_zone_id(value)}
        >
          <SelectTrigger className="w-[70%] focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select Zone-" />
          </SelectTrigger>
          <SelectContent>
            {allGPZ &&
              allGPZ.map((item, index) => (
                <SelectItem key={index} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">State</label>

        <Select
          disabled={readonly}
          value={state_id}
          onValueChange={(value) => setState_Id(value)}
        >
          <SelectTrigger className="w-[70%] focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select State-" />
          </SelectTrigger>
          <SelectContent>
            {allStates &&
              allStates.map((item, index) => (
                <SelectItem key={index} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">Settlement Type</label>

        <Select
          disabled={readonly}
          value={settlement_id}
          onValueChange={(value) => setSettlement_Id(value)}
        >
          <SelectTrigger className="w-[70%] focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select State-" />
          </SelectTrigger>
          <SelectContent>
            {allSettlements &&
              allSettlements.map((item, index) => (
                <SelectItem key={index} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Name</label>

        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          readOnly={readonly}
          placeholder="Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
    </div>
  );
};

export default LGAForm;
