import { FC } from "react";
import ProgressBar from "../../shared/atoms/ProgressBar";

interface TitledProgressBarProps {
  width: number;
  title: string;
}
const TitledProgressBar: FC<TitledProgressBarProps> = ({ title, width }) => {
  return (
    <div className="space-y-2 mb-3 text-base font-medium">
      <span className=" text-navyBlue">{title}</span>

      <div className="grid grid-cols-[1fr_3rem] items-center gap-4 w-full">
        <ProgressBar width={width} />
        <span className="text-[#344054]">{width}%</span>
      </div>
    </div>
  );
};

export default TitledProgressBar;
