import { FC, useEffect, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import SettlementsTable from "../components/locationManagement/organisms/SettlementsTable";
import { IfilteOptns } from "../types/custom";
import { Link } from "react-router-dom";
import { cn } from "../lib/shadcnUtils";
import { buttonVariants } from "../components/ui/Button";
import { Plus } from "lucide-react";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import {
  deleteSettlement,
  getAllSettlements,
} from "../lib/redux/slices/geolocation/locationThunk";
import Fuse from "fuse.js";
import { updateLocationStateValues } from "../lib/redux/slices/geolocation/locationSlice";

interface SettlementsProps {}
const Settlements: FC<SettlementsProps> = () => {
  const [filterOpts, setFilterOptns] = useState<IfilteOptns>({});
  const [search, setSearch] = useState("");

  const { isOpen } = useSelector((store: RootState) => store.dialog);
  const { selectedSettlement, allSettlements } = useSelector(
    (store: RootState) => store.geolocation
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      return await dispatch(getAllSettlements());
    };
    if (search.length === 0) {
      getData();
    }
  }, [dispatch, search]);

  const handleSearch = () => {
    if (allSettlements) {
      const fuse = new Fuse(allSettlements, options);

      if (search.length === 0) {
        dispatch(getAllSettlements());
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateLocationStateValues({
          name: "allSettlements",
          value: items,
        })
      );
    }
  };

  const confirmModal = async () => {
    if (selectedSettlement) {
      await dispatch(deleteSettlement(selectedSettlement.id.toString()));
    }

    await dispatch(getAllSettlements());
  };

  return (
    <div>
      <PageHeader title="Settlements" subTitle="Manage Settlements here." />
      <SearchFilterContainer
        handleSearch={handleSearch}
        setFilterOptns={setFilterOptns}
        search={search}
        setSearch={setSearch}
        filterOpts={filterOpts}
      />

      <div className="bg-white shadow-sm border rounded-xl">
        <div className="flex gap-4 m-8  justify-end">
          <Link
            to="new"
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-[#0C3C2A]"
            )}
          >
            <Plus />
            Add New Settlement
          </Link>
        </div>

        <SettlementsTable />
      </div>

      {isOpen && <ConfirmationDialog confirmModal={confirmModal} />}
    </div>
  );
};

export default Settlements;

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
