import { FC, useEffect, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import AgeGroupForm from "../components/demography/organisms/AgeGroupForm";
import { demographyPageTitles } from "../constants/textConstants";
import LiteracyLevelForm from "../components/demography/organisms/LiteracyLevelForm";
import { AppDispatch, RootState } from "../lib/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAgeGroups,
  getAllLiteracyLevels,
  getAllOccupations,
  updateAgeGroup,
  updateLiteracyLevel,
  updateOccuption,
} from "../lib/redux/slices/demography/demographyThunk";
import { toast } from "../hooks/useToast";
import OccupationForm from "../components/demography/organisms/OccupationForm";

interface EditDemographyProps {
  activeIndex: number;
}
const EditDemography: FC<EditDemographyProps> = ({ activeIndex }) => {
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const {
    selectedAgeGroup,
    selectedLiteracyLevel,
    selectedOccupation,
    isLoading,
  } = useSelector((store: RootState) => store.demography);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (activeIndex === 0 && selectedAgeGroup) {
      setName(selectedAgeGroup?.name);
      setMin(selectedAgeGroup?.min.toString());
      setMax(selectedAgeGroup?.max.toString());
    }
    if (activeIndex === 1 && selectedLiteracyLevel) {
      setName(selectedLiteracyLevel?.name as string);
      setDesc(selectedLiteracyLevel?.desc as string);
    }
    if (activeIndex === 2 && selectedOccupation) {
      setName(selectedOccupation?.name as string);
    }
  }, [
    activeIndex,
    selectedAgeGroup,
    selectedOccupation,
    selectedLiteracyLevel,
  ]);

  const handleUpdateAgeGroup = async () => {
    try {
      if (!name || !min || !max) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        updateAgeGroup({
          min: min.toString(),
          max: max.toString(),
          name,
          age_group_id: selectedAgeGroup?.id.toString() as string,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      dispatch(getAllAgeGroups());

      setMin("");
      setMax("");
      setName("");

      toast({
        title: "Age Group Updated  Successfully",
        description: "You have successfully Updated an Age Group",
        variant: "success",
      });

      navigate("/age-group");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };
  const handleUpadateLiteracyLevel = async () => {
    try {
      if (!name || !desc) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        updateLiteracyLevel({
          desc,
          name,
          literacy_level_id: selectedLiteracyLevel?.id.toString() as string,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      dispatch(getAllLiteracyLevels());
      setDesc("");
      setName("");

      toast({
        title: " Literay Level Updated  Successfully",
        description: "You have successfully updated a Literay Level",
        variant: "success",
      });

      navigate("/level-of-literacy");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };

  const handleUpdateOccupation = async () => {
    try {
      if (!name) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        updateOccuption({
          name,
          occupation_id: selectedOccupation?.id.toString() as string,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      dispatch(getAllOccupations());

      setName("");

      toast({
        title: "Occupation Updated Successfully",
        description: "You have successfully Updated an Occupation",
        variant: "success",
      });

      navigate("/occupation");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };

  const demographyFormList = [
    <AgeGroupForm
      max={max}
      setMax={setMax}
      min={min}
      setMin={setMin}
      name={name}
      setName={setName}
    />,
    <LiteracyLevelForm
      name={name}
      setName={setName}
      desc={desc}
      setDesc={setDesc}
    />,
    <OccupationForm name={name} setName={setName} />,
  ];

  return (
    <div>
      <PageHeader
        title={`Edit ${demographyPageTitles[activeIndex].name}`}
        subTitle={`Manage ${demographyPageTitles[activeIndex].name} Here`}
      />

      <PageSecondaryHeadings
        title={`${demographyPageTitles[activeIndex].name} Info`}
        description={demographyPageTitles[activeIndex].pageSecondarySubText}
      />

      <hr className="my-12" />

      <div className="mb-20">{demographyFormList[activeIndex]}</div>

      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          isLoading={isLoading}
          primaryBtnText="Save"
          primaryBtnNotLink
          primaryBtnOnclick={
            activeIndex === 0
              ? handleUpdateAgeGroup
              : activeIndex === 1
              ? handleUpadateLiteracyLevel
              : handleUpdateOccupation
          }
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default EditDemography;
