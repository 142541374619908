import { FC, useEffect, useState } from "react";
import UsersTable from "../components/accessManagement/organisms/UsersTable";
import PageHeader from "../components/shared/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import ConfirmationDialog from "../components/shared/ConfirmationDialog";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import { IfilteOptns } from "../types/custom";
import {
  getAllUsers,
  toggleUserStatus,
} from "../lib/redux/slices/accessMgmt/accessMgmtThunk";
import Fuse from "fuse.js";
import { updateStateValues } from "../lib/redux/slices/accessMgmt/accessMgmtSlice";
import { closeModal } from "../lib/redux/slices/dialogSlice";

const initFilter = {
  role: "",
  status: "",
};

const UserMgmt: FC = () => {
  const [filterOpts, setFilterOptns] = useState<IfilteOptns>(initFilter);
  const [search, setSearch] = useState("");

  const { selectedUser, allUsers } = useSelector(
    (store: RootState) => store.accessMgmt
  );
  const { isOpen } = useSelector((store: RootState) => store.dialog);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (search.length === 0) {
      dispatch(
        updateStateValues({
          name: "isSearching",
          value: false,
        })
      );
    }
  }, [dispatch, search]);

  const handleSearch = () => {
    dispatch(
      updateStateValues({
        name: "isSearching",
        value: true,
      })
    );
    if (allUsers) {
      const fuse = new Fuse(allUsers, options);

      if (search.length === 0) {
        dispatch(
          updateStateValues({
            name: "isSearching",
            value: false,
          })
        );
        dispatch(getAllUsers());
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateStateValues({
          name: "searchList",
          value: items,
        })
      );
    }
  };

  const handleFilter = () => {
    dispatch(
      updateStateValues({
        name: "isFiltering",
        value: true,
      })
    );
    if (allUsers) {
      const items = allUsers.filter((user) => {
        let isSameRole = true;
        let isSameStatus = true;
        if (filterOpts.role) {
          isSameRole = user.role === filterOpts.role;
        }
        if (filterOpts.status) {
          return (isSameStatus = user.status?.toString() === filterOpts.status);
        }
        return isSameRole && isSameStatus;
      });

      dispatch(
        updateStateValues({
          name: "filterList",
          value: items,
        })
      );
    }
  };

  const clearFilters = () => {
    setFilterOptns(initFilter);
    dispatch(
      updateStateValues({
        name: "isFiltering",
        value: false,
      })
    );

    
    dispatch(closeModal());
  };

  const confirmModal = async () => {
    if (selectedUser?.status !== undefined && selectedUser?.id) {
      await dispatch(
        toggleUserStatus({
          status: !selectedUser?.status,
          user_id: selectedUser?.id.toString(),
        })
      );
    }

    await dispatch(getAllUsers());
  };

  return (
    <div className="">
      <PageHeader
        title="User Management"
        subTitle="Manage Staff and their Role"
      />
      <SearchFilterContainer
        handleSearch={handleSearch}
        setFilterOptns={setFilterOptns}
        search={search}
        setSearch={setSearch}
        filterOpts={filterOpts}
        handleFilter={() => handleFilter()}
        clearFilters={() => clearFilters()}
      />

      <UsersTable />

      {isOpen && <ConfirmationDialog confirmModal={confirmModal} />}
    </div>
  );
};

export default UserMgmt;

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
