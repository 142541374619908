import { FC } from "react";
import { Button, buttonVariants } from "../../ui/Button";
import { AlignJustify, CloudDownload } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { setShowSidebar } from "../../../lib/redux/slices/dialogSlice";
import { generateCSVFile } from "../../../utils/helpers/generateCSVFile";
import { cn } from "../../../lib/shadcnUtils";

interface DashBoardHeaderProps {}
const DashBoardHeader: FC<DashBoardHeaderProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showSidebar } = useSelector((store: RootState) => store.dialog);
  const { digitizationAnalytics, biodataByMonth } = useSelector(
    (store: RootState) => store.analytics
  );

  return (
    <div className="space-y-8 mb-8">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          {!showSidebar && (
            <Button
              onClick={() => dispatch(setShowSidebar(true))}
              variant={"ghost"}
            >
              <AlignJustify />
            </Button>
          )}
          <h4 className="text-3xl font-semibold">Welcome back, Frankie</h4>
        </div>
        <div className="flex items-center gap-4 text-base">
          <a
            className={cn(buttonVariants({ variant: "default" }))}
            download
            href={
              biodataByMonth && digitizationAnalytics
                ? generateCSVFile([digitizationAnalytics, ...biodataByMonth])
                : ""
            }
          >
            <CloudDownload className="mr-2 h-8 w-8" /> Export
          </a>
        </div>
      </div>

      {/* <div className="flex items-center justify-between">
        <DateFilter />

        <div className="flex items-center gap-4 text-base">
          <Button variant={"outline"}>
            <Calendar className="mr-2 h-8 w-8 text-base" /> Select Dates
          </Button>

          <Button variant={"outline"}>
            <ListFilter className="mr-2 h-8 w-8 text-base" /> Filters
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default DashBoardHeader;
