import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IGPZ,
  ILGA,
  ISettlementDetails,
  IStateDetails,
} from "../../../../types/location";
import {
  addNewLGA,
  addNewSettlements,
  getAllGPZ,
  getAllLGA,
  getAllSettlements,
  getAllStateLGA,
  getAllStates,
  updateLGA,
  updateSettlements,
} from "./locationThunk";

interface AccessMgmtSliceState {
  [key: string]: any;
  selectedGPZ: IGPZ | null;
  selectedState: IStateDetails | null;
  selectedLga: ILGA | null;
  selectedSettlement: ISettlementDetails | null;
  allGPZ: IGPZ[] | null;
  allStates: IStateDetails[] | null;
  allLgas: ILGA[] | null;
  allStateLGA: ILGA[] | null;
  allSettlements: ISettlementDetails[] | null;
  currentGPZPage: number;
  currentStatePage: number;
  currentLgaPage: number;
  currentPage: number;
  isLoading: boolean;
  error?: string;
  limit: number;
  numberOfPages: number;
}

interface UpdateStatePayload {
  name: keyof AccessMgmtSliceState;
  value: any;
}

const initialState: AccessMgmtSliceState = {
  selectedGPZ: null,
  selectedState: null,
  selectedLga: null,
  selectedSettlement: null,
  allGPZ: null,
  allStates: null,
  allSettlements: null,
  allLgas: null,
  allStateLGA: null,
  currentGPZPage: 1,
  currentStatePage: 1,
  currentLgaPage: 1,
  currentPage: 1,
  isLoading: false,
  error: "",
  limit: 10,
  numberOfPages: 0,
};

const AccessMgmtSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedGPZ: (state, action) => {
      state.selectedGPZ = action.payload;
    },
    setSelectedState: (state, action) => {
      state.selectedState = action.payload;
    },
    setSelectedLga: (state, action) => {
      state.selectedLga = action.payload;
    },
    setSelectedSettlement: (state, action) => {
      state.selectedSettlement = action.payload;
    },
    updateLocationStateValues: (
      state,
      action: PayloadAction<UpdateStatePayload>
    ) => {
      state[action.payload.name] = action.payload.value;
    },
    selectPage: (state, action) => {
      if (action.payload <= state.numberOfPages && action.payload >= 0) {
        state.currentPage = action.payload;
      }
    },
    moveNextPage: (state) => {
      if (state.currentPage < state.numberOfPages) {
        state.currentPage += 1;
      }
    },
    movePrevPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },

    changeStateValue: (state, action) => {
      state = { ...state, [action.payload.name]: action.payload.value };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGPZ.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGPZ.fulfilled, (state, action) => {
        state.allGPZ = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllGPZ.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllLGA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllLGA.fulfilled, (state, action) => {
        state.allLgas = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllLGA.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllStateLGA.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getAllStateLGA.fulfilled, (state, action) => {
        state.allStateLGA = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllStateLGA.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(addNewLGA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewLGA.fulfilled, (state, action) => {
        state.allLgas = action.payload.data;
        state.isLoading = false;
      })
      .addCase(addNewLGA.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateLGA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLGA.fulfilled, (state, action) => {
        state.allLgas = action.payload.data;
        state.isLoading = false;
      })
      .addCase(updateLGA.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAllSettlements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSettlements.fulfilled, (state, action) => {
        state.allSettlements = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllSettlements.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addNewSettlements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewSettlements.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addNewSettlements.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateSettlements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSettlements.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateSettlements.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getAllStates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStates.fulfilled, (state, action) => {
        state.allStates = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getAllStates.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedGPZ,
  setSelectedState,
  setSelectedLga,
  setSelectedSettlement,
  updateLocationStateValues,
  selectPage,
  moveNextPage,
  movePrevPage,
  changeStateValue,
} = AccessMgmtSlice.actions;

export default AccessMgmtSlice.reducer;
