import { FC } from "react";
import { Input } from "../../ui/Input";


interface GPZFormProps {
  readonly?: boolean;
}
const GPZForm: FC<GPZFormProps> = ({ readonly }) => {
  return (
    <div className="space-y-12 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">Name</label>

        <Input disabled={readonly} />
      </div>
      <hr />
    </div>
  );
};

export default GPZForm;
