import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import { RoleStatus } from "../utils/data/roleData";
import CustomBadge from "../components/shared/atoms/CustomBadge";
import RoleForm from "../components/accessManagement/organisms/RoleForm";
import { IROle } from "../types/role";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import PermissionsWrapper from "../components/accessManagement/organisms/PermissionsWrapper";
import { Pencil } from "lucide-react";

interface ViewRoleProps {}
const ViewRole: FC<ViewRoleProps> = () => {
  const [roleDetails, setRoleDetails] = useState<Omit<IROle, "id">>({
    title: "Administrator",
    description:
      "This role is responsible for managing user accounts, permissions, and system configurations.",
  });

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setRoleDetails({ ...roleDetails, [name]: value });
  };
  return (
    <div>
      <PageHeader
        title="Role Details"
        subTitle="Manage your team members and their account permissions here."
      />
      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          primaryBtnText="Edit"
          primaryBtnLink={`edit`}
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
          primaryBtnIcon={<Pencil />}
        />
      </div>
      <div className="mb-8">
        <div className="flex gap-4">
          <h5 className="font-medium text-xl ">Role Info</h5>
          <CustomBadge status={RoleStatus.active} />
        </div>
        <p className="font-normal text-base text-[#667085]">
          View and edit role details here.
        </p>
      </div>
      <hr className="mb-8 " />

      <RoleForm
        roleDetails={roleDetails}
        handleInputChange={handleInputChange}
        readonly
      />

      <hr className="my-10 " />
      <h5 className="text-base font-medium text-[#344054]">Permissions</h5>
      <hr className="my-10 " />

      <PermissionsWrapper hideSearchBar hideCheck />
    </div>
  );
};

export default ViewRole;
