import { FC } from "react";
import Sidebar from "../sidebar/Sidebar";
import { Navigate, Outlet } from "react-router-dom";
import { cn } from "../../lib/shadcnUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../lib/redux/store";

interface BaseLayoutProps {}
const BaseLayout: FC<BaseLayoutProps> = () => {
  const { showSidebar } = useSelector((store: RootState) => store.dialog);

  const { userProfile } = useSelector((store: RootState) => store.auth);

  return (
    <>
      {userProfile ? (
        <div className={cn(showSidebar && "grid grid-cols-[28rem_1fr]")}>
          {showSidebar && <Sidebar />}

          <Outlet />
        </div>
      ) : (
        <Navigate to={"/login"} />
      )}
    </>
  );
};

export default BaseLayout;
