import { FC, useEffect, useState } from "react";
import TabsBox from "../components/locationManagement/molecules/TabsBox";
import PageHeader from "../components/shared/PageHeader";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import CustomTable from "../components/shared/customTable/CustomTable";
import GPZTableBody from "../components/locationManagement/molecules/GPZTableBody";
import StateTableBody from "../components/locationManagement/molecules/StateTableBody";
import LGATableBody from "../components/locationManagement/molecules/LGATableBody";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import {
  moveNextPage,
  movePrevPage,
  selectPage,
  updateLocationStateValues,
} from "../lib/redux/slices/geolocation/locationSlice";
import { IfilteOptns } from "../types/custom";
import { Link, useNavigate } from "react-router-dom";
import { cn } from "../lib/shadcnUtils";
import { buttonVariants } from "../components/ui/Button";
import { Plus } from "lucide-react";
import Fuse from "fuse.js";
import {
  getAllGPZ,
  getAllLGA,
  getAllStates,
} from "../lib/redux/slices/geolocation/locationThunk";

interface GeoLocationProps {
  activeIndex: number;
}
const GeoLocation: FC<GeoLocationProps> = ({ activeIndex }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const [filterGPZ, setFilterGPZ] = useState<IfilteOptns>({});
  const [filterState, setFilterState] = useState<IfilteOptns>({
    zone: "",
  });
  const [filterLGA, setFilterLGA] = useState<IfilteOptns>({
    zone: "",
    state: "",
  });

  const { currentPage, numberOfPages, allLgas, allStates, allGPZ } =
    useSelector((store: RootState) => store.geolocation);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      return await dispatch(
        activeIndex === 0
          ? getAllGPZ()
          : activeIndex === 1
          ? getAllStates()
          : getAllLGA()
      );
    };
    if (search.length === 0) {
      getData();
    }
  }, [activeIndex, dispatch, search]);

  const searchGPZ = () => {
    if (allGPZ) {
      const fuse = new Fuse(allGPZ, options);

      if (search.length === 0) {
        dispatch(getAllGPZ());
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateLocationStateValues({
          name: "allGPZ",
          value: items,
        })
      );
    }
  };
  const searchState = () => {
    if (allStates) {
      const fuse = new Fuse(allStates, options);

      if (search.length === 0) {
        dispatch(getAllStates());
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateLocationStateValues({
          name: "allStates",
          value: items,
        })
      );
    }
  };
  const searchLGA = () => {
    if (allLgas) {
      const fuse = new Fuse(allLgas, options);

      if (search.length === 0) {
        dispatch(getAllLGA());
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateLocationStateValues({
          name: "allLgas",
          value: items,
        })
      );
    }
  };
  const handleSearch = () => {
    return activeIndex === 0
      ? searchGPZ()
      : activeIndex === 1
      ? searchState()
      : searchLGA();
  };

  const geoLocationsCategories = [
    {
      name: "Geo Political Zone",
      filter: filterGPZ,
      setFilter: setFilterGPZ,
    },
    {
      name: "State",
      filter: filterState,
      setFilter: setFilterState,
    },
    {
      name: "LGA",
      filter: filterLGA,
      setFilter: setFilterLGA,
    },
  ];

  return (
    <div>
      <PageHeader
        title={geoLocationsCategories[activeIndex].name}
        subTitle="Manage Geopolitical Zones, State and LGAs here."
      />
      <div className="mb-8">
        <TabsBox
          activeIndex={activeIndex}
          btnOnclick={(index) =>
            navigate(
              `/${geoLocationsCategories[index].name
                .trim()
                .split(" ")
                .join("-")
                .toLowerCase()}`
            )
          }
          tabs={geoLocationsCategories.map((item) => item.name)}
        />
      </div>

      <div className="bg-white space-y-16 shadow-sm p-8 border rounded-xl">
        <SearchFilterContainer
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          filterOpts={geoLocationsCategories[activeIndex].filter}
          setFilterOptns={geoLocationsCategories[activeIndex].setFilter}
        />

        {activeIndex === 2 && (
          <div className="flex gap-4 justify-end">
            <Link
              to={`/${geoLocationsCategories[activeIndex].name
                .split(" ")
                .join("-")
                .toLowerCase()}/new`}
              className={cn(
                buttonVariants({ variant: "default" }),
                "bg-[#0C3C2A] h-[5rem] "
              )}
            >
              <Plus />
              Add New {geoLocationsCategories[activeIndex].name}
            </Link>
          </div>
        )}
        <div className="mt-8">
          {activeIndex === 0 ? (
            <CustomTable
              tableBody={<GPZTableBody />}
              headingList={gpzTableHeadings}
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              handleSelectPage={(index: number) => dispatch(selectPage(index))}
              handlePrevPage={() => dispatch(movePrevPage())}
              handleNextPage={() => dispatch(moveNextPage())}
            />
          ) : activeIndex === 1 ? (
            <CustomTable
              tableBody={<StateTableBody />}
              headingList={stateTableHeadings}
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              handleSelectPage={(index: number) => dispatch(selectPage(index))}
              handlePrevPage={() => dispatch(movePrevPage())}
              handleNextPage={() => dispatch(moveNextPage())}
            />
          ) : (
            <CustomTable
              tableBody={<LGATableBody />}
              headingList={LGATableHeadings}
              numberOfPages={numberOfPages}
              currentPage={currentPage}
              handleSelectPage={(index: number) => dispatch(selectPage(index))}
              handlePrevPage={() => dispatch(movePrevPage())}
              handleNextPage={() => dispatch(moveNextPage())}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GeoLocation;

const gpzTableHeadings = [
  {
    text: "Name",
  },
];

const stateTableHeadings = [
  {
    text: "Zone",
  },
  {
    text: "State",
  },
];

const LGATableHeadings = [
  {
    text: "Zone",
  },
  {
    text: "State",
  },
  {
    text: "LGA",
  },

  {
    text: "",
  },
];

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
