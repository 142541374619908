import AuthPageHeader from "../components/shared/AuthPageHeader";
import { buttonVariants } from "../components/ui/Button";
import { cn } from "../lib/shadcnUtils";
import { CircleCheckBig } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";

interface ResetSuccessProps {}
const ResetSuccess: FC<ResetSuccessProps> = () => {
  return (
    <div className="h-screen w-screen flex items-center  bg-white">
      <div className=" mx-auto w-[30%] ">
        <div className="flex flex-col gap-6 items-center">
          <AuthPageHeader />
          <div className="bg-[#D4EBE2] mx-auto w-fit h-fit rounded-full border-[#EAF9EC] border-[1rem] p-4">
            <CircleCheckBig className="w-[2.8rem] h-[2.8rem]" />
          </div>
          <h6 className="font-bold text-[3rem] font-manjari mb-4">
            Password Reset Success
          </h6>
          <p className="font-normal text-[#344054] text-[1.6rem]">
            As a first time user we’ll send you reset instructions.
          </p>

          <Link
            to={"/login"}
            className={cn(buttonVariants({ size: "lg" }), "mt-4 gap-2 text-base rounded-xl w-full")}
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccess;
