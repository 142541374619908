import axios, { AxiosError } from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";

const baseUrl = "https://api.database.noa.gov.ng/api";

export const getDigitizationAnalytics = createAppAsyncThunk(
  "digitization/getDigitizationAnalytics", 
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(
        `${baseUrl}/analytics?secret_key=${process.env.REACT_APP_SECRET_KEY}`
      );

      return res.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue(
        "Could not get Content Factory Analytics"
      );
    }
  }
);

export const getBiodataByMonth = createAppAsyncThunk(
  "digitization/getBiodataByMonth",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(
        `${baseUrl}/analytics/biodata-by-month?secret_key=${process.env.REACT_APP_SECRET_KEY}`
      );

      return res.data.bio_data_by_month;
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue(
        "Could not get Content Factory Analytics"
      );
    }
  }
);
