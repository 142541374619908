import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import { CircleX, Eye, Pencil } from "lucide-react";
import { openModal } from "../../../lib/redux/slices/dialogSlice";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { Button } from "../../ui/Button";
import {
  setSelectedLiteracyLevel,
  updateDemographyStateValues,
} from "../../../lib/redux/slices/demography/demographySlice";
import { SlOptionsVertical } from "react-icons/sl";
import OptsDropdown from "../../shared/OptsDropdown";
import { IoWarningOutline } from "react-icons/io5";
import LoaderIcon from "../../../components/shared/atoms/LoaderIcon";
import { getAllLiteracyLevels } from "../../../lib/redux/slices/demography/demographyThunk";

interface LiteracyLevelTableBodyProps {}
const LiteracyLevelTableBody: FC<LiteracyLevelTableBodyProps> = () => {
  const [selectedLevelId, setSelectedLevelId] = useState<string | null>(null);
  const [literacyLevelData, setLiteracyLevel] = useState<ILiteracyLevel[] | null>(
    null
  );
  const { allLiteracyLevels, search, searchList, isLoading } = useSelector(
    (store: RootState) => store.demography
  );

  const dispatch = useDispatch<AppDispatch>();

  const ref = useRef(null);
  useOnClickOutside(ref, () => setSelectedLevelId(null));

  const navigate = useNavigate();

  useEffect(() => {
    if (!allLiteracyLevels) {
      dispatch(getAllLiteracyLevels());
    }
  }, [allLiteracyLevels, dispatch]);

  useEffect(() => {
    if (search && searchList) {
      setLiteracyLevel(searchList as ILiteracyLevel[]);
    } else {
      setLiteracyLevel(allLiteracyLevels);
    }
  }, [allLiteracyLevels, search, searchList]);

  useEffect(() => {
    const resetSearch = () => {
      dispatch(
        updateDemographyStateValues({
          name: "search",
          value: "",
        })
      );
      dispatch(
        updateDemographyStateValues({
          name: "searchList",
          value: null,
        })
      );
    };
    resetSearch();
    return () => {
      resetSearch();
    };
  }, [dispatch]);

  const literacyLevelOptns = [
    {
      id: "1",
      icon: <Pencil />,
      text: "Edit",
      btnOnclick: function (literacyLevelId: string) {
        navigate(`${literacyLevelId}/edit`);
      },
    },
    {
      id: "2",
      icon: <Eye />,
      text: "View",
      btnOnclick: function (literacyLevelId: string) {
        navigate(`${literacyLevelId}`);
      },
    },

    {
      id: "3",
      icon: <CircleX />,
      text: "Delete",
      btnOnclick: function () {
        dispatch(
          openModal({
            icon: <IoWarningOutline size={28} className="text-[#DC6803]" />,
            iconBg: "bg-[#FEF0C7]",
            iconBorderColor: "border-[#FFFAEB]",
            iconColor: "text-[#DC6803]",
            primaryColor: "bg-[#D83535]",
            title: "Delete Literacy Level",
            description:
              "When you click on confirm, This Literacy Level will be automatically deleted",
          })
        );
      },
    },
  ];
  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {!isLoading &&
        literacyLevelData &&
        literacyLevelData.length > 0 &&
        literacyLevelData.map((literacyLevel, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">{literacyLevel.name}</TableCell>
            <TableCell>{literacyLevel.desc}</TableCell>

            <TableCell className="relative">
              <Button
                onClick={() => {
                  setSelectedLevelId(literacyLevel.id.toString());
                  dispatch(
                    setSelectedLiteracyLevel(
                      literacyLevelData.find(
                        (item) => item.id === literacyLevel.id
                      )
                    )
                  );
                }}
                variant={"ghost"}
              >
                <SlOptionsVertical />
              </Button>
              {selectedLevelId === literacyLevel.id.toString() && (
                <div ref={ref}>
                  <OptsDropdown
                    options={literacyLevelOptns}
                    selectedId={selectedLevelId}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      {!isLoading && literacyLevelData && literacyLevelData.length === 0 && (
        <TableRow>
          <TableCell rowSpan={0}>
            <h4 className="p-8 text-base font-semibold flex justify-center ">
              No Age Group has been Added yet
            </h4>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default LiteracyLevelTableBody;
