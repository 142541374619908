import { FC } from "react";
// src/Tiptap.tsx
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import EditorToolbar from "./EditorToolbar";

interface TipTapProps {
  message: string;
  setMessage: (text: string) => void;
}
// define your extension array
const extensions = [StarterKit.configure(), Underline];

const TipTap: FC<TipTapProps> = ({ message, setMessage }) => {
  const editor = useEditor({
    extensions,
    content: message,
    editorProps: {
      attributes: {
        class:
          "flex flex-col min-h-[150px] w-full rounded-xl border border-input bg-transparent px-6 py-4 text-base ring-offset-background file:border-0 file:bg-transparent file:text-base file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      },
    },
    onUpdate({ editor }) {
      setMessage(editor.getHTML());
    },
  });

  return (
    <div className={`flex flex-col justify-stretch gap-4 min-h-[250px] `}>
      <EditorToolbar editor={editor} />
      <EditorContent editor={editor} className="tipTapWrapper" />
    </div>
  );
};

export default TipTap;
