import SendEmailForm from "../components/biodata/SendEmailForm";
import PageHeader from "../components/shared/PageHeader";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import { FC } from "react";

interface BiodataSendMailProps {}
const BiodataSendMail: FC<BiodataSendMailProps> = () => {
  return (
    <div>
      <PageHeader title="Bio Data " subTitle="Manage Bio Data Here" />

      <PageSecondaryHeadings
        title={"Send Message "}
        description={"Compose Message here"}
      />

      <SendEmailForm />
    </div>
  );
};

export default BiodataSendMail;
