import { FC } from "react";
import ZoneRespondantSummary from "../molecules/ZoneRespondantSummary";
import MapChart from "../molecules/MapChart";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { setFilter } from "../../../lib/redux/slices/analytics/analyticsSlice";

interface RegionalDistributionProps {}
const RegionalDistribution: FC<RegionalDistributionProps> = () => {
  const { filter } = useSelector((store: RootState) => store.analytics);

  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="p-8 bg-backgroundLight rounded-lg mb-8">
      <div className="flex justify-between items-center mb-[3rem]">
        <h4 className="text-navyBlue text-2xl font-medium">
          NOA Distribution by Region
        </h4>

        <Select
          value={filter}
          onValueChange={(value) => dispatch(setFilter(value))}
        >
          <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 w-[20rem]">
            <SelectValue placeholder="-Select View-" />
          </SelectTrigger>
          <SelectContent>
            {["State", "Geopolitical Zone"].map((item, index) => (
              <SelectItem className="text-base" key={index} value={item}>
                <span className="capitalize"> {item}</span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex gap-[7rem]">
        <div className="flex-1 grid place-items-center">
          <MapChart />
        </div>
        <div className="w-[28.4rem]">
          <ZoneRespondantSummary />
        </div>
      </div>
    </div>
  );
};

export default RegionalDistribution;
