import { FC } from "react";
import { Button, buttonVariants } from "../../ui/Button";
import { cn } from "../../../lib/shadcnUtils";

interface TitledButtonHeadingProps {
  title: string;
  btnOnClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonText: string;
  dowloadLink?: string;
}
const TitledButtonHeading: FC<TitledButtonHeadingProps> = ({
  title,
  btnOnClick,
  dowloadLink,
  buttonText,
}) => {
  return (
    <div className=" flex justify-between items-center">
      <h4 className="text-2xl font-medium text-darkBlue leading-[2.8rem]">
        {title}
      </h4>

      {!dowloadLink ? (
        <Button variant={"outline"} onClick={btnOnClick}>
          {buttonText}
        </Button>
      ) : (
        <a
          className={cn(buttonVariants({ variant: "outline" }))}
          download
          href={dowloadLink}
        >
          {buttonText}
        </a>
      )}
    </div>
  );
};

export default TitledButtonHeading;
