import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import TabsBox from "../components/locationManagement/molecules/TabsBox";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import UserProfileForm from "../components/userSettings/UserProfileForm";
import UserPasswordForm from "../components/userSettings/UserPasswordForm";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { Pencil } from "lucide-react";

interface UserSettingsProps {}
const UserSettings: FC<UserSettingsProps> = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isEditingMode, setIsEditingMode] = useState(false);

  return (
    <div>
      <PageHeader
        title="User Settings"
        subTitle="Update your Profile and Password"
      />

      <div className="mb-8">
        <TabsBox
          activeIndex={activeTab}
          btnOnclick={(index) => setActiveTab(index)}
          tabs={tabsHeadings.map((tab) => tab.title)}
        />
      </div>
      {!isEditingMode && (
        <div className="flex justify-end ">
          <PrimaryOutlineBtns
            primaryBtnNotLink
            primaryBtnText="Edit"
            primaryBtnOnclick={() => setIsEditingMode(true)}
            primaryBtnIcon={<Pencil />}
          />
        </div>
      )}

      <div className=" p-8  ">
        <PageSecondaryHeadings
          title={tabsHeadings[activeTab].title}
          description={tabsHeadings[activeTab].subtitle}
        />

        {activeTab === 0 ? (
          <UserProfileForm
            setIsEditingMode={setIsEditingMode}
            readonly={!isEditingMode}
          />
        ) : (
          <UserPasswordForm
            setIsEditingMode={setIsEditingMode}
            readonly={!isEditingMode}
          />
        )}
      </div>
    </div>
  );
};

export default UserSettings;

const tabsHeadings = [
  {
    title: "Profile",
    subtitle: "Update your photo and personal details here.",
  },
  {
    title: "Password",
    subtitle: "Please enter your current password to change your password.",
  },
];
