import { FC } from "react";
import PageHeader from "../components/shared/PageHeader";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import { Pencil } from "lucide-react";
import { useSelector } from "react-redux";
import { RootState } from "../lib/redux/store";
import AgenciesForm from "../components/agencies/AgenciesForm";

interface CreateViewEditAgenciesProps {
  mode: "edit" | "new" | "view";
}
const CreateViewEditAgencies: FC<CreateViewEditAgenciesProps> = ({ mode }) => {
  const navigate = useNavigate();

  const { selectedMinistry } = useSelector((store: RootState) => store.mda);

  return (
    <div>
      <PageHeader title={`${mode} Agencies`} subTitle="Manage Agencies Here" />
      {mode !== "edit" && mode !== "new" && (
        <div className="flex justify-end ">
          <PrimaryOutlineBtns
            primaryBtnText="Edit"
            primaryBtnLink={`/agencies/${selectedMinistry?.id}/edit`}
            outlineBtnText="Cancel"
            outlineBtnOnclick={() => navigate(-1)}
            primaryBtnIcon={<Pencil />}
          />
        </div>
      )}
      <div className="mb-8">
        <div className="flex gap-4">
          <h5 className="font-medium text-xl ">Agency Info</h5>
        </div>
        <p className="font-normal text-base text-[#667085]">
          View Agency details here.
        </p>
      </div>
      <hr className="mb-8 " />

      <div className="mb-20">
        <AgenciesForm mode={mode} readonly={mode === "view"} />
      </div>
    </div>
  );
};

export default CreateViewEditAgencies;
