import axios, { AxiosError } from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";

const baseUrl = "https://api.database.noa.gov.ng/api";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAllStates = createAppAsyncThunk(
  "location/getAllStates",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/state`);

      return { data: res.data.data.states };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve Users Details");
    }
  }
);

export const getAllLGA = createAppAsyncThunk(
  "location/getAllLGA",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/lga`);

      return { data: res.data.data.lgas };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);
export const getAllStateLGA = createAppAsyncThunk(
  "location/getAllStateLGA",
  async (stateId: string, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/lga/state/${stateId}`);

      return { data: res.data.data.lgas };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);
export const addNewLGA = createAppAsyncThunk(
  "location/addNewLGA",
  async (
    newLGA: {
      name: string;
      state_id: string;
      settlement_id?: string;
      geo_political_zone_id: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(`${baseUrl}/lga/create`, newLGA);

      return { data: res.data.data.Lga };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);
export const updateLGA = createAppAsyncThunk(
  "location/updateLGA",
  async (
    newLGA: {
      name: string;
      state_id: string;
      settlement_id?: string;
      geo_political_zone_id: string;
      lga_id: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(`${baseUrl}/lga/update`, newLGA);

      return { data: res.data.data.Lga };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);

export const getAllGPZ = createAppAsyncThunk(
  "location/getAllGPZ",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/geo-political-zone`);

      return { data: res.data.data.gpz };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);

export const getAllSettlements = createAppAsyncThunk(
  "location/getAllSettlements",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/settlement`);

      return { data: res.data.data.settlements };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);
export const addNewSettlements = createAppAsyncThunk(
  "location/addNewSettlements",
  async (
    newSettlement: {
      name: string;
      desc: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/settlement/create`,
        newSettlement
      );

      return { data: res.data.data.settlement };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);
export const updateSettlements = createAppAsyncThunk(
  "location/updateSettlements",
  async (
    settlementDetails: {
      name: string;
      desc: string;
      settlement_id: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/settlement/update`,
        settlementDetails
      );

      return { data: res.data.data.settlement };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all User Role");
    }
  }
);

export const deleteSettlement = createAppAsyncThunk(
  "mda/deleteAgency",
  async (settlement_id: string, thunkAPI) => {
    try {
      await axiosInstance.delete(
        `${baseUrl}/settlement/delete/${settlement_id}`
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Delete Agency");
    }
  }
);
