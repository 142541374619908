import { FC } from "react";
import { IoMdHelpCircleOutline } from "react-icons/io";
import UsersTableBody from "../molecules/UsersTableBody";
import { Table } from "../../ui/Table";
import CustomTableHeader from "../../shared/customTable/CustomTableHeader";
import { IoArrowDown } from "react-icons/io5";
import TablePaginations from "../../shared/TablePaginations";
import TableContainerHeader from "../molecules/TableContainerHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import {
  movePrevPage,
  selectPage,
  moveNextPage,
} from "../../../lib/redux/slices/accessMgmt/accessMgmtSlice";

const UsersTableHeadings = [
  {
    text: "Name",
  },
  {
    text: "Email Address",
  },
  {
    text: "Date Added",
  },
  {
    text: "Role",
    icon: <IoMdHelpCircleOutline className="ml-2" />,
  },
  {
    text: "Status",
    icon: <IoArrowDown className="ml-2" />,
  },
  {
    text: "",
  },
];

interface UsersTableProps {}
const UsersTable: FC<UsersTableProps> = () => {
  const { currentPage, allUsers, numberOfPages } = useSelector(
    (store: RootState) => store.accessMgmt
  );

  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className="border border-[#EAECF0] p-8 shadow-sm rounded-lg bg-white ">
      <TableContainerHeader
        title="users"
        badgeValue={allUsers ? allUsers?.length : 0}
        btnLink="new"
        btnText="Add User"
      />

      <Table className="border-y mb-6">
        <CustomTableHeader headingList={UsersTableHeadings} />
        <UsersTableBody />
      </Table>
      <TablePaginations
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        handleSelectPage={(index: number) => dispatch(selectPage(index))}
        handlePrevPage={() => dispatch(movePrevPage())}
        handleNextPage={() => dispatch(moveNextPage())}
      />
    </div>
  );
};

export default UsersTable;
