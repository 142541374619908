import { FC } from "react";
import { Input } from "../../ui/Input";

interface AgeGroupFormProps {
  readonly?: boolean;
  min: string;
  setMin: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  max: string;
  setMax: React.Dispatch<React.SetStateAction<string>>;
}
const AgeGroupForm: FC<AgeGroupFormProps> = ({
  readonly,
  name,
  setName,
  min,
  setMin,
  max,
  setMax,
}) => {
  return (
    <div className="space-y-12 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Name</label>

        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          readOnly={readonly}
          placeholder="Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="max">Max age</label>

        <Input
          value={max}
          onChange={(e) => setMax(e.target.value)}
          name="max"
          type="number"
          readOnly={readonly}
          placeholder="Max Age"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Min Age</label>

        <Input
          value={min}
          onChange={(e) => setMin(e.target.value)}
          name="lowerBand"
          type="number"
          readOnly={readonly}
          placeholder="Min Age"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
    </div>
  );
};

export default AgeGroupForm;
