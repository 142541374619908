import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import { Button } from "../components/ui/Button";
import UserForm from "../components/accessManagement/organisms/UserForm";
import { IUser } from "../types/users";
import { UserRole } from "../utils/data/tableData";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import { regiterAdmin } from "../lib/redux/slices/auth/authThunk";
import { toast } from "../hooks/useToast";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "../lib/redux/slices/accessMgmt/accessMgmtThunk";

interface NewUserProps {}
const NewUser: FC<NewUserProps> = () => {
  const [userDetails, setUserDetails] = useState<Omit<IUser, "id">>({
    name: "",
    email: "",
    role: UserRole.admin,
  });

  const { isLoading } = useSelector((store: RootState) => store.auth);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleRoleChange = (role: UserRole) => {
    setUserDetails({
      ...userDetails,
      role: role,
    });
  };

  const handleAddNewUser = async () => {
    console.log(userDetails.role, "userDetails.role");
    try {
      if (!userDetails.name || !userDetails.email || !userDetails.role)
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      await dispatch(regiterAdmin(userDetails));
      await dispatch(getAllUsers());

      toast({
        title: "Congratulations!!",
        description: "User was Successfully Added",
        variant: "success",
      });

      navigate("/users-management");
    } catch (err) {
      console.log(err);

      return toast({
        title: "Error Signing Up",
        description: "Something Went Wrong",
        variant: "destructive",
      });
    }
  };
  return (
    <div>
      <PageHeader
        title="New User"
        subTitle="Manage your team members and their account permissions here."
      />

      <PageSecondaryHeadings
        title={"Personal Info"}
        description={"Update photo and personal details here."}
      />
      <hr className="mb-8" />
      <div>
        <UserForm
          userDetails={userDetails}
          handleInputChange={handleInputChange}
          handleRoleChange={handleRoleChange}
        />
        <div className="flex justify-end ">
          <div className="flex gap-4 items-center ">
            <Button
              onClick={() => navigate(-1)}
              variant={"outline"}
              className="bg-transparent"
            >
              Cancel
            </Button>
            <Button isLoading={isLoading} onClick={handleAddNewUser}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
