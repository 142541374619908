import axios, { AxiosError } from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";

const baseUrl = "https://api.database.noa.gov.ng/api";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAllAgeGroups = createAppAsyncThunk(
  "demography/getAllAgeGroups",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/age-group`);

      return { data: res.data.data.groups };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve Age Group");
    }
  }
);

export const addNewAgeGroup = createAppAsyncThunk(
  "demography/addNewAgeGroup",
  async (
    newAgeGroup: {
      name: string;
      min: string;
      max: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/age-group/create`,
        newAgeGroup
      );

      return { data: res.data.data.group };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }
      return thunkAPI.rejectWithValue("Could not add New Age Group");
    }
  }
);

export const updateAgeGroup = createAppAsyncThunk(
  "demography/updateAgeGroup",
  async (
    data: {
      name: string;
      min: string;
      max: string;
      age_group_id: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(`${baseUrl}/age-group/update`, data);

      return { data: res.data.data.group };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not Update Age Group");
    }
  }
);

export const deleteAgeGroup = createAppAsyncThunk(
  "demography/deleteAgeGroup",
  async (groupId: string, thunkAPI) => {
    try {
      await axiosInstance.delete(`${baseUrl}/age-group/delete/${groupId}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Delete Age Group");
    }
  }
);

export const getAllLiteracyLevels = createAppAsyncThunk(
  "demography/getAllLiteracyLevels",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/literacy-level`);

      return { data: res.data.data.literacy_levels };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all Literacy Level");
    }
  }
);

export const addNewLiteracyLevel = createAppAsyncThunk(
  "demography/addNewLiteracyLevel",
  async (
    newLiteracyLevel: {
      name: string;
      desc: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/literacy-level/create`,
        newLiteracyLevel
      );

      return { data: res.data.data.literacy_level };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }
      return thunkAPI.rejectWithValue("Could not add New Literacy Level");
    }
  }
);

export const updateLiteracyLevel = createAppAsyncThunk(
  "demography/updateLiteracyLevel",
  async (
    data: {
      name: string;
      desc: string;
      literacy_level_id: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/literacy-level/update`,
        data
      );

      return { data: res.data.data.literacy_level };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not Update Literacy Levels");
    }
  }
);

export const deleteLiteracyLevel = createAppAsyncThunk(
  "demography/deleteLiteracyLevel",
  async (literacy_level_id: string, thunkAPI) => {
    try {
      await axiosInstance.delete(
        `${baseUrl}/literacy-level/delete/${literacy_level_id}`
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Delete Literacy Level");
    }
  }
);

export const getAllOccupations = createAppAsyncThunk(
  "demography/getAllOccupations",
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstance.get(`${baseUrl}/occupation`);

      return { data: res.data.data.occupations };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not retrieve all Occupation");
    }
  }
);

export const addNewOccupation = createAppAsyncThunk(
  "demography/addNewOccupation",
  async (
    newOccupation: {
      name: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/occupation/create`,
        newOccupation
      );

      return { data: res.data.data.occupation };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }
      return thunkAPI.rejectWithValue("Could not add New Occupation");
    }
  }
);

export const updateOccuption = createAppAsyncThunk(
  "demography/updateOccuption",
  async (
    data: {
      name: string;
      occupation_id: string;
    },
    thunkAPI
  ) => {
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/occupation/update`,
        data
      );

      return { data: res.data.data.occupation };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      return thunkAPI.rejectWithValue("Could not Update Occupation");
    }
  }
);

export const deleteOccupation = createAppAsyncThunk(
  "demography/deleteOccupation",
  async (occupationId: string, thunkAPI) => {
    try {
      await axiosInstance.delete(
        `${baseUrl}/occupation/delete/${occupationId}`
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Delete Occupation");
    }
  }
);
