import { FC } from "react";
import RegionalDistribution from "../components/dashbaord/organisms/RegionalDistribution";
import DigitizationCardGrid from "../components/dashbaord/organisms/DigitizationCardGrid";
import OccupationChart from "../components/dashbaord/organisms/OccupationChart";
import AgeGroupDistribution from "../components/dashbaord/organisms/AgeGroupDistrubution,";
import LiteracyLevelDistriution from "../components/dashbaord/organisms/LiteracyLevelDistribution";
import BiodataByMonth from "../components/dashbaord/organisms/BiodataByMonth";
import DashBoardHeader from "../components/dashbaord/molecules/DashBoardHeader";

interface DashboardProps {}
const Dashboard: FC<DashboardProps> = () => {
  return (
    <div className="p-8">
      <DashBoardHeader />
      <DigitizationCardGrid />
      <RegionalDistribution />

      <div className="grid grid-cols-[2fr_1fr] gap-8">
        <OccupationChart />
        <div className="grid">
          <div>
            <AgeGroupDistribution />
          </div>

          <div>
            <LiteracyLevelDistriution />
          </div>
        </div>
      </div>

      <BiodataByMonth />

      {/* <RegionalGenderResponse /> */}

      {/* <NOAInteraction /> */}

      {/* <InteractionRateTable /> */}
    </div>
  );
};

export default Dashboard;
