import { FC } from "react";
import { Button } from "../../ui/Button";

interface TabsBoxProps {
  tabs: string[];
  btnOnclick: (index: number) => void;
  activeIndex: number;
}

const TabsBox: FC<TabsBoxProps> = ({ tabs, btnOnclick, activeIndex }) => {
  return (
    <div>
      {tabs.map((tab, index) => (
        <Button
          key={index}
          onClick={() => btnOnclick(index)}
          variant={"ghost"}
          className={`h-24  hover:bg-transparent border-b-4 border-transparent rounded-none hover:border-[hsl(138,63%,36%)] ${
            index === activeIndex && "border-[hsl(138,63%,36%)]"
          }`}
        >
          {tab}
        </Button>
      ))}
      <hr />
    </div>
  );
};

export default TabsBox;
