import { FC } from "react";
import { Table } from "../../ui/Table";
import CustomTableHeader from "../../shared/customTable/CustomTableHeader";
import { IoArrowDown } from "react-icons/io5";
import TablePaginations from "../../shared/TablePaginations";
import RoleTableBody from "../molecules/RoleTableBody";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import {
  movePrevPage,
  selectPage,
  moveNextPage,
} from "../../../lib/redux/slices/accessMgmt/accessMgmtSlice";

const rolesTableHeading = [
  {
    text: "Role",
  },
  {
    text: "Description",
  },

  {
    text: "Status",
    icon: <IoArrowDown className="ml-2" />,
  },
];

interface RolesTableProps {}
const RolesTable: FC<RolesTableProps> = () => {
  const { currentPage, numberOfPages } = useSelector(
    (store: RootState) => store.accessMgmt
  );

  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="border border-[#EAECF0] p-8 shadow-sm rounded-lg bg-white ">
      <Table className="border-y mb-6 min-h-[50vh]">
        <CustomTableHeader headingList={rolesTableHeading} />
        <RoleTableBody />
      </Table>
      <TablePaginations
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        handleSelectPage={(index: number) => dispatch(selectPage(index))}
        handlePrevPage={() => dispatch(movePrevPage())}
        handleNextPage={() => dispatch(moveNextPage())}
      />
    </div>
  );
};

export default RolesTable;
