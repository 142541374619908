import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import AgeGroupForm from "../components/demography/organisms/AgeGroupForm";
import { demographyPageTitles } from "../constants/textConstants";
import LiteracyLevelForm from "../components/demography/organisms/LiteracyLevelForm";
import { AppDispatch, RootState } from "../lib/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAgeGroup,
  addNewLiteracyLevel,
  addNewOccupation,
  getAllAgeGroups,
  getAllLiteracyLevels,
  getAllOccupations,
} from "../lib/redux/slices/demography/demographyThunk";
import { toast } from "../hooks/useToast";
import OccupationForm from "../components/demography/organisms/OccupationForm";

interface NewDemographyProps {
  activeIndex: number;
}
const NewDemography: FC<NewDemographyProps> = ({ activeIndex }) => {
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const navigate = useNavigate();

  const { isLoading } = useSelector((store: RootState) => store.demography);

  const dispatch = useDispatch<AppDispatch>();

  const handleAddAgeGroup = async () => {
    try {
      if (!name || !min || !max) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        addNewAgeGroup({
          min: min.toString(),
          max: max.toString(),
          name,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      dispatch(getAllAgeGroups());

      setMin("");
      setMax("");
      setName("");

      toast({
        title: "Age Group Added  Successfully",
        description: "You have successfully added a new Age Group",
        variant: "success",
      });

      navigate("/age-group");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };

  const handleAddLiteracyLevel = async () => {
    try {
      if (!name || !desc) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        addNewLiteracyLevel({
          desc,
          name,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      dispatch(getAllLiteracyLevels());
      setDesc("");
      setName("");

      toast({
        title: "Literay Level Added Successfully",
        description: "You have successfully added a new Literay Level",
        variant: "success",
      });

      navigate("/level-of-literacy");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };

  const handleAddOccupation = async () => {
    try {
      if (!name) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        addNewOccupation({
          name,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      dispatch(getAllOccupations());

      setName("");

      toast({
        title: "Occupation Added Successfully",
        description: "You have successfully added a new Occupation",
        variant: "success",
      });

      navigate("/occupation");
    } catch (error) {
      return toast({
        title: "Something went wrong",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };

  const demographyFormList = [
    <AgeGroupForm
      max={max}
      setMax={setMax}
      min={min}
      setMin={setMin}
      name={name}
      setName={setName}
    />,
    <LiteracyLevelForm
      name={name}
      setName={setName}
      desc={desc}
      setDesc={setDesc}
    />,
    <OccupationForm name={name} setName={setName} />,
  ];

  return (
    <div>
      <PageHeader
        title={`Add ${demographyPageTitles[activeIndex].name}`}
        subTitle={`Manage ${demographyPageTitles[activeIndex].name} Here`}
      />

      <PageSecondaryHeadings
        title={`${demographyPageTitles[activeIndex].name} Info`}
        description={demographyPageTitles[activeIndex].pageSecondarySubText}
      />

      <hr className="my-12" />

      <div className="mb-20">{demographyFormList[activeIndex]}</div>

      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          isLoading={isLoading}
          primaryBtnText="Save"
          primaryBtnNotLink
          primaryBtnOnclick={
            activeIndex === 0
              ? handleAddAgeGroup
              : activeIndex === 1
              ? handleAddLiteracyLevel
              : handleAddOccupation
          }
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default NewDemography;
