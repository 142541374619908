import { FC } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/Avater";

interface UserAvaterProps {
  imgUrl: string;
  name: string;
}
const UserAvater: FC<UserAvaterProps> = ({ imgUrl, name }) => {
  return (
    <Avatar className="w-[4rem] h-[4rem]">
      <AvatarImage src={imgUrl} alt={name} />
      <AvatarFallback>CN</AvatarFallback>
    </Avatar>
  );
};

export default UserAvater;
