import { ListFilter } from "lucide-react";
import { FC } from "react";
import { buttonVariants } from "../../ui/Button";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { useDispatch, useSelector } from "react-redux";
import TableFilter from "../molecules/TableFilter";
import {
  closeModal,
  setShowFilter,
} from "../../../lib/redux/slices/dialogSlice";
import FilterPopOver from "../../shared/FilterPopOver";
import Searchbar from "../molecules/Searchbar";
import { IBiodataFilterOptns, IfilteOptns } from "../../../types/custom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/Popover";
import { cn } from "../../../lib/shadcnUtils";
import BiodataTableFilter from "../../shared/BiodataTableFilter";

interface SearchFilterContainerProps {
  filterOpts?: IfilteOptns;
  setFilterOptns?: React.Dispatch<React.SetStateAction<IfilteOptns>>;
  bioFilterOpts?: IBiodataFilterOptns;
  setBioFilterOptns?: React.Dispatch<React.SetStateAction<IBiodataFilterOptns>>;
  searchFilterList?: string[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  handleFilter?: Function;
  clearFilters?: Function;
  handleSearch: Function;
}
const SearchFilterContainer: FC<SearchFilterContainerProps> = ({
  filterOpts,
  searchFilterList,
  handleFilter,
  clearFilters,
  setFilterOptns,
  bioFilterOpts,
  setBioFilterOptns,
  search,
  setSearch,

  handleSearch,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showFilterDialog } = useSelector((store: RootState) => store.dialog);

  return (
    <div className="flex items-center w-full h-[5.3rem] gap-6 mb-8 relative">
      <div className="flex items-center w-full h-full">
        <TableFilter filterList={searchFilterList || ["Name"]} />
        <Searchbar
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
        />
      </div>

      {(filterOpts && Object.keys(filterOpts).length > 0) ||
      (bioFilterOpts && Object.keys(bioFilterOpts).length > 0) ? (
        <Popover
          open={showFilterDialog}
          onOpenChange={() =>
            showFilterDialog
              ? dispatch(closeModal())
              : dispatch(setShowFilter())
          }
        >
          <PopoverTrigger
            className={cn(buttonVariants({ variant: "outline" }), "h-full ")}
          >
            <ListFilter size={35} />
            <span>Filters</span>
          </PopoverTrigger>
          <PopoverContent side={bioFilterOpts ? "left" : "bottom"}>
            {bioFilterOpts && setBioFilterOptns ? (
              <BiodataTableFilter
                setFilterOptns={setBioFilterOptns}
                filterOptns={bioFilterOpts}
                clearFilters={clearFilters}
                handleFilter={handleFilter}
              />
            ) : filterOpts && setFilterOptns ? (
              <FilterPopOver
                clearFilters={clearFilters}
                handleFilter={handleFilter}
                filterOpts={filterOpts}
                setFilterOptns={setFilterOptns}
              />
            ) : null}
          </PopoverContent>
        </Popover>
      ) : null}
    </div>
  );
};

export default SearchFilterContainer;
