import { FC } from "react";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../lib/shadcnUtils";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/DatePicker";
import { Button } from "../ui/Button";
import { Calendar } from "../ui/Calender";

interface SelectDateProps {
  date?: Date;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  placeholder?: string;
}
const SelectDate: FC<SelectDateProps> = ({ date, setDate, placeholder }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-[1.6rem] w-[1.6rem]" />
          {date ? (
            format(date, "PPP")
          ) : (
            <span>{placeholder || "Pick a Date"}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export default SelectDate;
