import { FC, useEffect } from "react";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { getAllStates } from "../../../lib/redux/slices/geolocation/locationThunk";
import LoaderIcon from "../../../components/shared/atoms/LoaderIcon";

interface StateTableBodyProps {}
const StateTableBody: FC<StateTableBodyProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { allStates, isLoading } = useSelector(
    (store: RootState) => store.geolocation
  );

  useEffect(() => {
    dispatch(getAllStates());
  }, [dispatch]);

  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {!isLoading &&
        allStates &&
        allStates.length > 0 &&
        allStates.map((state, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium capitalize">
              {state.geo_political_zone.name}
            </TableCell>
            <TableCell className="font-medium">{state.name}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default StateTableBody;
