import { FC } from "react";
import { Link } from "react-router-dom";
import { Badge } from "../../ui/Badge";
import { cn } from "../../../lib/shadcnUtils";
import { buttonVariants } from "../../ui/Button";
import { Plus } from "lucide-react";

interface TableContainerHeaderProps {
  title?: string;
  btnLink: string;
  btnText: string;
  badgeValue?: number;
}
const TableContainerHeader: FC<TableContainerHeaderProps> = ({
  title,
  btnLink,
  badgeValue,
  btnText,
}) => {
  return (
    <div className="flex justify-between items-center mb-8">
      {title ? (
        <div className="flex items-center gap-4">
          <h4 className="font-medium text-xl text-darkBlue capitalize">
            {title}
          </h4>
          <Badge className="bg-[#F1FCF6] text-[#229745] hover:bg-default">
            {badgeValue} {title}
          </Badge>
        </div>
      ) : (
        <div />
      )}

      <Link
        to={btnLink}
        className={cn(
          buttonVariants({ variant: "default" }),
          "bg-[#0C3C2A] border-[#0C3C2A]"
        )}
      >
        <Plus />
        <span>{btnText}</span>
      </Link>
    </div>
  );
};

export default TableContainerHeader;
