import { FC, useEffect, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import CustomTable from "../components/shared/customTable/CustomTable";
import MinistriesTableBody from "../components/ministries/MinistriesTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import {
  moveNextPage,
  movePrevPage,
  selectPage,
  updateMdaStateValues,
} from "../lib/redux/slices/mda/mdaSlice";
import { IfilteOptns } from "../types/custom";
import { getAllMinistries } from "../lib/redux/slices/mda/mdaThunk";
import Fuse from "fuse.js";

interface MinisteriesProps {}
const Ministeries: FC<MinisteriesProps> = () => {
  const [filterOpts, setFilterOptns] = useState<IfilteOptns>({});

  const { currentPage, limit, allMinistries } = useSelector(
    (store: RootState) => store.mda
  );
  const [search, setSearch] = useState("");
  const [numberOfPages, setNumberOfPages] = useState(0);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      return await dispatch(getAllMinistries());
    };
    if (search.length === 0) {
      getData();
    }
  }, [dispatch, search]);

  useEffect(() => {
    setNumberOfPages(
      allMinistries ? Math.ceil(allMinistries?.length / limit) : 0
    );
  }, [allMinistries, limit]);

  useEffect(() => {}, [search]);



  const handleSearch = () => {
    if (allMinistries) {
      const fuse = new Fuse(allMinistries, options);

      if (search.length === 0) {
        dispatch(getAllMinistries());
      }

      const results = fuse.search(search);

      const items = results.map((result) => result.item);

      dispatch(
        updateMdaStateValues({
          name: "allMinistries",
          value: items,
        })
      );
    }
  };

  return (
    <div>
      <PageHeader title="Ministeries" subTitle="Manage Ministeries here" />
      <SearchFilterContainer
        handleSearch={handleSearch}
        setFilterOptns={setFilterOptns}
        search={search}
        setSearch={setSearch}
        filterOpts={filterOpts}
      />

      <div className="border border-[#EAECF0] p-8 shadow-sm rounded-lg bg-white ">
        <CustomTable
          tableBody={<MinistriesTableBody />}
          headingList={groupTableHeadings}
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          handleSelectPage={(index: number) =>
            dispatch(selectPage({ newPage: index, numberOfPages }))
          }
          handlePrevPage={() => dispatch(movePrevPage())}
          handleNextPage={() => dispatch(moveNextPage(numberOfPages))}
        />
      </div>
    </div>
  );
};

export default Ministeries;

const groupTableHeadings = [
  {
    text: "Name of Ministry",
  },
  {
    text: "Contact",
  },
];

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
