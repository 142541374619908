import { FC } from "react";
import { rolesData } from "../../../utils/data/roleData";
import TextCheckbox from "../molecules/TextCheckbox";

interface PermissionsWrapperProps {
  hideSearchBar?: boolean;
  hideCheck?: boolean;
}

const PermissionsWrapper: FC<PermissionsWrapperProps> = ({
  hideSearchBar,
  hideCheck,
}) => {
  return (
    <div className="rounded-lg  border">
      <div className="bg-[#F9FAFB] p-8 rounded-t-lg ">
        {!hideSearchBar && (
          <div className="mb-8">
            {/* <Searchbar
              search={search}
              setSearch={setSearch}
              showFullBorder
              placeholderText={"Search Permisson"}
            /> */}
          </div>
        )}
        <span className="font-medium text-[12px]">
          Role has access to 8 Permissions
        </span>
      </div>

      <hr />

      <div className="grid grid-cols-3 gap-8 space-y-8  bg-white p-8 rounded-b-lg">
        {rolesData.map((item, index) => (
          <>
            <TextCheckbox hideCheck={hideCheck} key={item.id} {...item} />
          </>
        ))}
      </div>
    </div>
  );
};

export default PermissionsWrapper;
