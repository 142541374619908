import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getAllGroups } from "./groupThunk";
import { IGroup } from "../../../../types/group";
import Fuse from "fuse.js";
import { IPagination } from "@/types/custom";

interface groupSliceState {
  [key: string]: any;
  selectedGroup: IGroup | null;
  allGroups: IGroup[] | null;
  isLoading: boolean;
  error?: string;
  pagination: IPagination | null;
}

interface UpdateStatePayload {
  name: keyof groupSliceState;
  value: any;
}

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};

// type UserProfileKeys = keyof UserProfile;

const initialState: groupSliceState = {
  selectedGroup: null,
  allGroups: null,
  isLoading: false,
  error: "",
  pagination: null,
};

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    updateGroupStateValues: (
      state,
      action: PayloadAction<UpdateStatePayload>
    ) => {
      state[action.payload.name] = action.payload.value;
    },

    handleSearchGroup: (state, action) => {
      if (state.allGroups) {
        const fuse = new Fuse(state.allGroups, options);
        const value = action.payload;

        if (value.length === 0) {
          return;
        }

        const results = fuse.search(value);
        const items = results.map((result) => result.item);
        state.allGroups = items;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.allGroups = action.payload.data;
        state.pagination = action.payload.pagination;

        state.isLoading = false;
      })
      .addCase(getAllGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateGroupStateValues, handleSearchGroup } = groupSlice.actions;

export default groupSlice.reducer;
