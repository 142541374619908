import StackedBarChart from "../../charts/StackedBar";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TitledButtonHeading from "../molecules/TitledButtonHeading";
import { getBiodataByMonth } from "../../../lib/redux/slices/analytics/analyticsThunk";
import { AxiosError } from "axios";
import { toast } from "../../../hooks/useToast";
import { generateCSVFile } from "../../../utils/helpers/generateCSVFile";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
interface BiodataByMonthProps {}
const BiodataByMonth: FC<BiodataByMonthProps> = () => {
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<number[]>([]);

  const { biodataByMonth } = useSelector((store: RootState) => store.analytics);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      try {
        await dispatch(getBiodataByMonth());
      } catch (error) {
        if (error instanceof AxiosError) {
          if (
            error.response?.status === 400 ||
            error.response?.status === 401
          ) {
            return toast({
              title: "Error Geting Data",
              description: error.response?.data,
              variant: "destructive",
            });
          }
        }

        toast({
          title: "Error Geting Data",
          description: "Something went wrong",
          variant: "destructive",
        });
      }
    };

    getData();
  }, [dispatch]);
  useEffect(() => {
    if (biodataByMonth) {
      setLabels(biodataByMonth.map((item) => months[item.month - 1]));
      setDatasets(biodataByMonth.map((item) => item.total));
    }
  }, [biodataByMonth]);
  return (
    <div className="p-8 bg-backgroundLight rounded-lg mb-8 border border-[#EAECF0] cardShadow ">
      <TitledButtonHeading
        title="Bio data by Month distribution"
        buttonText="Response Report"
        dowloadLink={biodataByMonth ? generateCSVFile([...biodataByMonth]) : ""}
      />
      <hr className="my-8" />

     
      <StackedBarChart
        aspRatio={4}
        chartData={{
          labels: labels,
          datasets: [
            {
              label: "Bio data by Month",
              data: datasets,
              backgroundColor: "#00AA55",
              borderColor: "#41B405",
              borderWidth: 0,
              borderRadius: 10,
              barPercentage: 0.3,
              categoryPercentage: 0.1,
            },
          ],
        }}
      />
    </div>
  );
};

export default BiodataByMonth;
