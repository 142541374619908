import { FC, useEffect, useState } from "react";

import { TableBody, TableCell, TableRow } from "../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { getAllMinistries } from "../../lib/redux/slices/mda/mdaThunk";
import LoaderIcon from "../shared/atoms/LoaderIcon";

interface MinistriesTableBodyProps {}
const MinistriesTableBody: FC<MinistriesTableBodyProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { allMinistries, currentPage, limit, isLoading } = useSelector(
    (store: RootState) => store.mda
  );

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    dispatch(getAllMinistries());
  }, [dispatch]);

  useEffect(() => {
    setSkip((currentPage - 1) * limit);
  }, [currentPage, limit]);

  return (
    <TableBody>
      {isLoading && (
        <TableRow>
          <TableCell rowSpan={0}>
            <LoaderIcon height={30} width={30} />
          </TableCell>
        </TableRow>
      )}

      {!isLoading &&
        allMinistries &&
        allMinistries.length > 0 &&
        allMinistries.slice(skip, skip + limit).map((ministry, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">{ministry.name}</TableCell>
            <TableCell className="font-medium">{ministry.contact}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default MinistriesTableBody;
