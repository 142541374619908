import { FC } from "react";
import { Input } from "../../ui/Input";
import { Textarea } from "../../ui/TextArea";

interface SettlementsProps {
  readonly?: boolean;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  desc: string;
  setDesc: React.Dispatch<React.SetStateAction<string>>;
}
const Settlements: FC<SettlementsProps> = ({
  name,
  setName,
  desc,
  setDesc,
  readonly,
}) => {
  return (
    <div className="space-y-12 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Name</label>

        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          readOnly={readonly}
          placeholder="Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Description</label>

        <Textarea
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          name="description"
          readOnly={readonly}
          placeholder="Description"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
    </div>
  );
};

export default Settlements;
