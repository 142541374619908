import { FC, useEffect, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import GPZForm from "../components/locationManagement/organisms/GPZForm";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import StateForm from "../components/locationManagement/organisms/StateForm";
import LGAForm from "../components/locationManagement/organisms/LGAForm";
import { locationPageTitles } from "../constants/textConstants";
import SettlementsForm from "../components/locationManagement/organisms/SettlementsForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import { toast } from "../hooks/useToast";
import {
  getAllLGA,
  getAllSettlements,
  updateLGA,
  updateSettlements,
} from "../lib/redux/slices/geolocation/locationThunk";

interface EditLocationProps {
  activeIndex: number;
}
const EditLocation: FC<EditLocationProps> = ({ activeIndex }) => {
  const [state_id, setState_Id] = useState("");
  const [settlement_id, setSettlement_Id] = useState("");
  const [name, setName] = useState("");
  const [geo_political_zone_id, setGeo_political_zone_id] = useState("");
  const [desc, setDesc] = useState("");

  const { selectedLga, selectedSettlement, isLoading } = useSelector(
    (store: RootState) => store.geolocation
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (activeIndex === 2 && selectedLga) {
      setName(selectedLga?.name);
      setState_Id(selectedLga?.state_id as string);
      setGeo_political_zone_id(selectedLga?.geo_political_zone_id as string);
      setSettlement_Id(selectedLga?.settlement_id as string);
    }
    if (activeIndex === 3 && selectedSettlement) {
      setName(selectedSettlement?.name as string);
      setDesc(selectedSettlement?.desc as string);
    }
  }, [activeIndex, selectedLga, selectedSettlement]);

  const handleEditLGA = async () => {
    try {
      if (!name || !state_id || !geo_political_zone_id) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        updateLGA({
          state_id: state_id.toString(),
          settlement_id: settlement_id.toString(),
          name,
          geo_political_zone_id: geo_political_zone_id.toString(),
          lga_id: selectedLga?.id.toString() as string,
        })
      );
      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      await dispatch(getAllLGA());

      setState_Id("");
      setSettlement_Id("");
      setName("");
      setGeo_political_zone_id("");

      toast({
        title: "LGA Updated  Successfully",
        description: "You have successfully Updated LGA Details",
        variant: "success",
      });

      navigate("/lga");
    } catch (error) {
      return toast({
        title: "Something went wront",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };
  const handleEditSettlement = async () => {
    try {
      if (!name || !desc) {
        return toast({
          title: "Invalid Input",
          description: "You have not entered all fields",
          variant: "destructive",
        });
      }
      const res = await dispatch(
        updateSettlements({
          name,
          desc,
          settlement_id: selectedSettlement?.id.toString() as string,
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      await dispatch(getAllSettlements());

      setName("");
      setDesc("");

      toast({
        title: "Settlement Updated  Successfully",
        description: "You haved successfully Updated Settlement Details",
        variant: "success",
      });

      navigate("/settlement");
    } catch (error) {
      return toast({
        title: "Something went wront",
        description: "Please try again Latter",
        variant: "destructive",
      });
    }
  };

  const locationFormList = [
    <GPZForm />,
    <StateForm />,
    <LGAForm
      geo_political_zone_id={geo_political_zone_id}
      setGeo_political_zone_id={setGeo_political_zone_id}
      setSettlement_Id={setSettlement_Id}
      settlement_id={settlement_id}
      setState_Id={setState_Id}
      state_id={state_id}
      name={name}
      setName={setName}
    />,
    <SettlementsForm
      name={name}
      setName={setName}
      desc={desc}
      setDesc={setDesc}
    />,
  ];

  return (
    <div>
      <PageHeader
        title={`Edit ${locationPageTitles[activeIndex].name}`}
        subTitle={`Manage ${locationPageTitles[activeIndex].name} Here`}
      />

      <PageSecondaryHeadings
        title={`${locationPageTitles[activeIndex].name} Info`}
        description={locationPageTitles[activeIndex].pageSecondarySubText}
      />

      <hr className="my-12" />

      <div className="mb-20">{locationFormList[activeIndex]}</div>

      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          isLoading={isLoading}
          primaryBtnText="Save"
          primaryBtnNotLink
          primaryBtnOnclick={
            activeIndex === 2
              ? handleEditLGA
              : activeIndex === 3
              ? handleEditSettlement
              : function () {}
          }
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default EditLocation;
