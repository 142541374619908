import axios from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";

const baseUrl = "https://api.database.noa.gov.ng/api";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAllGroups = createAppAsyncThunk(
  "group/getAllGroups",
  async (newPage: number = 1, thunkAPI) => {
    try {
      const url = newPage
        ? `${baseUrl}/group?page=${newPage}`
        : `${baseUrl}/group`;
      const res = await axiosInstance.get(url);

      return {
        data: res.data.data.groups,
        pagination: res.data.data.pagination,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue("Could not Get All Groups");
    }
  }
);
