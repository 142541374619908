import { FC, useEffect, useState } from "react";
import UserForm from "../components/accessManagement/organisms/UserForm";
import { UserRole } from "../utils/data/tableData";
import { IUser } from "../types/users";
import PageHeader from "../components/shared/PageHeader";
import { Button } from "../components/ui/Button";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";
import { toast } from "../hooks/useToast";
import { updateUserRole } from "../lib/redux/slices/accessMgmt/accessMgmtThunk";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

interface EditUserProps {}
const EditUser: FC<EditUserProps> = () => {
  const { selectedUser, isLoading } = useSelector(
    (store: RootState) => store.accessMgmt
  );

  const [userDetails, setUserDetails] = useState<Omit<IUser, "id">>({
    name: "",
    email: "",
    role: UserRole.admin,
  });

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedUser) {
      setUserDetails({
        ...userDetails,
        name: selectedUser.name,
        email: selectedUser.email,
        role: selectedUser.role,
      });
    }
  }, [selectedUser, userDetails]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleRoleChange = (role: UserRole) => {
    setUserDetails({ ...userDetails, role: role });
  };

  const handleUpdateUserRole = async () => {
    try {
      if (!userDetails.role || !selectedUser) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }

      const res = await dispatch(
        updateUserRole({
          user_id: selectedUser?.id?.toString(),
          role: userDetails.role,
        })
      );
      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });
      toast({
        title: "Profile Update Success",
        description: "You have Updated your profile successfully",
        variant: "success",
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 400 || error.response?.status === 401) {
          return toast({
            title: "Error Signing Up",
            description: error.response?.data,
            variant: "destructive",
          });
        }
      }
    }
  };
  return (
    <div>
      <PageHeader
        title="Edit User"
        subTitle="Manage your team members and their account permissions here."
      />
      <PageSecondaryHeadings
        title={"Personal Info"}
        description={"Update photo and personal details here.F"}
      />
      <hr className="mb-8" />
      <div>
        <UserForm
          readonly={true}
          mode="edit"
          userDetails={userDetails}
          handleInputChange={handleInputChange}
          handleRoleChange={handleRoleChange}
        />
        <div className="flex justify-end ">
          <div className="flex gap-4 items-center ">
            <Button
              onClick={() => navigate(-1)}
              variant={"outline"}
              className="bg-transparent"
            >
              Cancel
            </Button>
            <Button isLoading={isLoading} onClick={handleUpdateUserRole}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
