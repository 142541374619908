import { FC, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import { UserRole } from "../utils/data/tableData";
import { IUser } from "../types/users";
import UserForm from "../components/accessManagement/organisms/UserForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../lib/redux/store";
import PrimaryOutlineBtns from "../components/shared/PrimaryOutlineBtns";
import { Pencil } from "lucide-react";
import PageSecondaryHeadings from "../components/shared/PageSecondaryHeadings";

interface ViewUserProps {}
const ViewUser: FC<ViewUserProps> = () => {
  const [userDetails, setUserDetails] = useState<Omit<IUser, "id">>({
    name: "Counsel Okpabi",
    email: "user@email.com",
    role: UserRole.admin,
  });

  const { selectedUser } = useSelector((store: RootState) => store.accessMgmt);

  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleRoleChange = (role: UserRole) => {
    setUserDetails({ ...userDetails, role: role });
  };
  return (
    <div>
      <PageHeader
        title="View User"
        subTitle="Manage your team members and their account permissions here."
      />

      <div className="flex justify-end ">
        <PrimaryOutlineBtns
          primaryBtnText="Edit"
          primaryBtnLink={`${selectedUser?.id}/edit`}
          outlineBtnText="Cancel"
          outlineBtnOnclick={() => navigate(-1)}
          primaryBtnIcon={<Pencil />}
        />
      </div>
      <PageSecondaryHeadings
        title={"Personal Info"}
        description={"Update photo and personal details here.F"}
      />
      <hr className="mb-8" />

      <UserForm
        readonly
        userDetails={userDetails}
        handleInputChange={handleInputChange}
        handleRoleChange={handleRoleChange}
      />
    </div>
  );
};
export default ViewUser;
