import { FC } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { stateData } from "../../../utils/data/locationTableData";
import { Input } from "../../ui/Input";

interface StateFormProps {
  readonly?: boolean;
}
const StateForm: FC<StateFormProps> = ({ readonly }) => {
  return (
    <div className="space-y-8 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr] mb-8">
        <label htmlFor="">Geo Political Zone</label>

        <Select disabled={readonly}>
          <SelectTrigger className="w-[70%] focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select Zone-" />
          </SelectTrigger>
          <SelectContent>
            {stateData.map((item, index) => (
              <SelectItem key={index} value={item.zone}>
                {item.zone}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Name</label>

        <Input
          name="name"
          readOnly={readonly}
          placeholder="Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Code</label>

        <Input
          name="code"
          readOnly={readonly}
          placeholder="Code"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
    </div>
  );
};

export default StateForm;
