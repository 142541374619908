import { FC } from "react";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { roleTableData } from "../../../utils/data/tableData";
import CustomBadge from "../../../components/shared/atoms/CustomBadge";

interface RoleTableBodyProps {}
const RoleTableBody: FC<RoleTableBodyProps> = () => {
 
  return (
    <TableBody>
      {roleTableData.map((roleItem, index) => (
        <TableRow
          className=" odd:bg-white even:bg-slate-100 text-base border-b-0"
          key={index}
        >
          <TableCell className="font-medium">{index + 1}</TableCell>

          <TableCell>{roleItem.role}</TableCell>
          <TableCell className="font-medium">{roleItem.decription}</TableCell>

          <TableCell>
            <CustomBadge status={roleItem.status} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default RoleTableBody;
