import { FC } from "react";
import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowUpDown, ListFilter } from "lucide-react";

import { Button, buttonVariants } from "../ui/Button";
import { Checkbox } from "../ui/Checkbox";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/PopoverInDialog";
import { cn } from "../../lib/shadcnUtils";
import BiodataTableFilter from "../shared/BiodataTableFilter";
import TableFilter from "../accessManagement/molecules/TableFilter";
import Searchbar from "../accessManagement/molecules/Searchbar";
import { IBioData } from "../../types/biodata";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { updateBiodataStateValues } from "../../lib/redux/slices/biodata/biodataSlice";
import { IBiodataFilterOptns } from "../../types/custom";
import {
  getAllBioData,
  getFilteredBioData,
} from "../../lib/redux/slices/biodata/biodataThunk";

export const columns: ColumnDef<IBioData>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        className="h-6 w-6"
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        className="h-6 w-6"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "name",
    accessorFn: (row) => `${row.first_name} ${row.last_name} ${row.other_name}`,
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },

  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => <div className="lowercase">{row.getValue("email")}</div>,
  },
  {
    accessorKey: "phone_number",
    header: "Email",
    cell: ({ row }) => <div>{row.getValue("phone_number")}</div>,
  },
  {
    accessorKey: "gender",
    header: "Gender",
    cell: ({ row }) => <div>{row.getValue("gender")}</div>,
  },
  {
    accessorKey: "dob",
    header: "Date Of Birth",
    cell: ({ row }) => <div>{row.getValue("dob")}</div>,
  },
  {
    accessorKey: "state.name",
    id: "stateName",
    header: "State",
    cell: ({ row }) => <div>{row.getValue("stateName")}</div>,
  },
  {
    accessorKey: "literacy_level.name",
    id: "literacy_levelName",
    header: "Literacy Level",
    cell: ({ row }) => <div>{row.getValue("literacy_levelName")}</div>,
  },
  //   {
  //     accessorKey: "settlement.name",
  //     id: "settlementName",
  //     header: "Settlement",
  //     cell: ({ row }) => <div>{row.getValue("settlementName")}</div>,
  //   },
  //   {
  //     accessorKey: "age_group.name",
  //     id: "age_groupName",
  //     header: "Age Group",
  //     cell: ({ row }) => <div>{row.getValue("age_groupName")}</div>,
  //   },

  {
    accessorKey: "occupation.name",
    id: "occupationName",
    header: "Occupation",
    cell: ({ row }) => <div>{row.getValue("occupationName")}</div>,
  },
  {
    accessorKey: "association",
    header: "Association",
    cell: ({ row }) => <div>{row.getValue("association")}</div>,
  },
];

interface BiodataTableWithCheckBoxProps {
  setFilterOptns: React.Dispatch<React.SetStateAction<IBiodataFilterOptns>>;
  filterOptns: IBiodataFilterOptns;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const initFilter = {
  gender: "",
  state_id: "",
  geo_political_zone_id: "",
  age_group_id: "",
  literacy_level_id: "",
};

export const BiodataTableWithCheckBox: FC<BiodataTableWithCheckBoxProps> = ({
  setFilterOptns,
  setOpenDialog,
  filterOptns,
}) => {
  const { allBioData } = useSelector((store: RootState) => store.biodata);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const ref = React.useRef(null);
  useOnClickOutside(ref, () => setOpenFilter(false));
  const dispatch = useDispatch<AppDispatch>();

  const table = useReactTable({
    data: allBioData || [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const handleSave = () => {
    dispatch(
      updateBiodataStateValues({
        name: "biodataRecipeint",
        value: table
          .getFilteredSelectedRowModel()
          .rows.map((item) => item._valuesCache),
      })
    );

    setOpenDialog(false);
  };

  const handleFilterBiodata = async () => {
    try {
      await dispatch(getFilteredBioData(filterOptns));
    } catch (error) {
      console.log(error);
    }
  };

  const clearFilterBiodata = async () => {
    try {
      setFilterOptns(initFilter);
      await dispatch(getAllBioData());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full space-y-8 relative">
      <div className="flex justify-between w-full items-center py-4">
        <div className="flex items-center w-[60%] h-full">
          <TableFilter filterList={["Name"]} />
          <Searchbar
            handleSearch={() => {}}
            search={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
            setSearch={(event) =>
              table.getColumn("name")?.setFilterValue(event)
            }
          />
        </div>

        <Popover open={openFilter}>
          <PopoverTrigger
            onClick={() => setOpenFilter(!openFilter)}
            className={cn(buttonVariants({ variant: "outline" }), "h-full ")}
          >
            <ListFilter size={20} />
            <span>Filters</span>
          </PopoverTrigger>
          <PopoverContent side="left">
            <div ref={ref} className="z-50">
              <BiodataTableFilter
                filterOptns={filterOptns}
                setFilterOptns={setFilterOptns}
                clearFilters={clearFilterBiodata}
                handleFilter={handleFilterBiodata}
              />
            </div>
          </PopoverContent>
        </Popover>
      </div>

      <div className="rounded-md border">
        <Table className="w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-base text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
      <div className="flex justify-end mt-8">
        <Button onClick={handleSave}>Save and Continue</Button>
      </div>
    </div>
  );
};
