import { CircleX, Eye, Pencil } from "lucide-react";
import { FC, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../../lib/redux/slices/dialogSlice";
import { SlOptionsVertical } from "react-icons/sl";
import { Button } from "../../ui/Button";
import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { setSelectedSettlement } from "../../../lib/redux/slices/geolocation/locationSlice";
import OptsDropdown from "../../shared/OptsDropdown";
import { IoWarningOutline } from "react-icons/io5";
import LoaderIcon from "../../../components/shared/atoms/LoaderIcon";
import { getAllSettlements } from "../../../lib/redux/slices/geolocation/locationThunk";

interface SettlementsTableBodyProps {}
const SettlementsTableBody: FC<SettlementsTableBodyProps> = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const ref = useRef(null);

  const { allSettlements, isLoading } = useSelector(
    (store: RootState) => store.geolocation
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!allSettlements) {
          await dispatch(getAllSettlements());
        }
      } catch (error) {}
    };

    getData();
  }, [allSettlements, dispatch]);

  useOnClickOutside(ref, () => setSelectedUserId(null));

  const navigate = useNavigate();

  const CommnuityTableOptions = [
    {
      id: "1",
      icon: <Pencil />,
      text: "Edit",
      btnOnclick: function (commnuityId: string) {
        navigate(`${commnuityId}/edit`);
      },
    },
    {
      id: "2",
      icon: <Eye />,
      text: "View",
      btnOnclick: function (commnuityId: string) {
        navigate(`${commnuityId}`);
      },
    },

    {
      id: "3",
      icon: <CircleX />,
      text: "Delete",
      btnOnclick: function () {
        dispatch(
          openModal({
            icon: <IoWarningOutline size={28} className="text-[#DC6803]" />,
            iconBg: "bg-[#FEF0C7]",
            iconBorderColor: "border-[#FFFAEB]",
            iconColor: "text-[#DC6803]",
            primaryColor: "bg-[#D83535]",
            title: "Delete Settlement",
            description:
              "When you click on confirm, Settlement will be automatically Deleted",
          })
        );
      },
    },
  ];

  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {!isLoading &&
        allSettlements &&
        allSettlements.map((settlement, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">{settlement.name}</TableCell>
            <TableCell>{settlement.desc}</TableCell>

            <TableCell className="relative">
              <Button
                onClick={() => {
                  setSelectedUserId(settlement.id);
                  dispatch(setSelectedSettlement(allSettlements[index]));
                }}
                variant={"ghost"}
              >
                <SlOptionsVertical />
              </Button>
              {selectedUserId === settlement.id && (
                <div ref={ref}>
                  <OptsDropdown
                    options={CommnuityTableOptions}
                    selectedId={selectedUserId}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default SettlementsTableBody;
