import { FC } from "react";

interface AuthPageHeaderProps {
  title?: string;
}
const AuthPageHeader: FC<AuthPageHeaderProps> = ({ title }) => {
  return (
    <div className="mx-auto flex flex-col gap-3 items-center">
      <img
        src="/assets/images/noa_logo.svg"
        alt="NOA LOGO"
        className="mb-[1rem]"
      />

      <h4 className="text-[5rem] font-manjari font-bold mb-[2rem]">
        {title || "Digitization"}
      </h4>
    </div>
  );
};

export default AuthPageHeader;
