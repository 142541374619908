import { FC, useState } from "react";
import { Input } from "../ui/Input";
import PrimaryOutlineBtns from "../shared/PrimaryOutlineBtns";
import { useNavigate } from "react-router-dom";
import { Pencil } from "lucide-react";

interface GroupFormProps {
  readonly?: boolean;
}
const GroupForm: FC<GroupFormProps> = ({ readonly }) => {
  const [groupDetails, setGroupDetails] = useState({
    groupName: "Counsel ",
    groupLeaderFN: "Okpabi",
    groupLeaderLN: "counsel",
    groupLeaderPhone: "user@email.com",
    groupAddress: "",
    state: "",
    lga: "",
    groupSecFN: "Okpabi",
    groupSecLN: "counsel",
    groupSecPhone: "user@email.com",
    anyOfficialFN: "Okpabi",
    anyOfficialLN: "counsel",
    anyOfficialPosition: "counsel",
    anyOfficialPhone: "counsel",
  });


  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setGroupDetails({ ...groupDetails, [name]: value });
  };

  return (
    <div className="space-y-12 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Group Name</label>

        <Input
          value={groupDetails.groupName}
          onChange={handleInputChange}
          name="groupName"
          readOnly={readonly}
          placeholder="Group Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Full name of group leader</label>

        <div className="flex gap-4 items-center w-[70%]">
          <Input
            value={groupDetails.groupLeaderFN}
            onChange={handleInputChange}
            readOnly={readonly}
            name="groupLeaderFN"
            placeholder="Firstname"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0"
          />
          <Input
            value={groupDetails.groupLeaderLN}
            onChange={handleInputChange}
            readOnly={readonly}
            placeholder="Lastname"
            name="groupLeaderLN"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0"
          />
        </div>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Phone number of group leader</label>

        <Input
          value={groupDetails.groupLeaderPhone}
          onChange={handleInputChange}
          name="groupLeaderPhone"
          readOnly={readonly}
          placeholder="Group Leader Phone"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Group Contact address</label>

        <Input
          value={groupDetails.groupAddress}
          onChange={handleInputChange}
          name="groupAddress"
          readOnly={readonly}
          placeholder="Group Contact Address"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">State</label>

        <Input
          value={groupDetails.state}
          onChange={handleInputChange}
          name="state"
          readOnly={readonly}
          placeholder="State"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Lga</label>

        <Input
          value={groupDetails.lga}
          onChange={handleInputChange}
          name="lga"
          readOnly={readonly}
          placeholder="Lga"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Full names of Group Secretary</label>

        <div className="flex gap-4 items-center w-[70%]">
          <Input
            value={groupDetails.groupSecFN}
            onChange={handleInputChange}
            name="groupSecFN"
            readOnly={readonly}
            placeholder="First Name"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
          />
          <Input
            value={groupDetails.groupSecLN}
            onChange={handleInputChange}
            readOnly={readonly}
            placeholder="Lastname"
            name="groupSecLN"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0"
          />
        </div>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Phone number of group secretary</label>

        <Input
          value={groupDetails.groupSecPhone}
          onChange={handleInputChange}
          name="groupSecPhone"
          readOnly={readonly}
          placeholder="Group Sec Phone"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />

      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Name of any other official</label>

        <div className="flex gap-4 items-center w-[70%]">
          <Input
            value={groupDetails.anyOfficialFN}
            onChange={handleInputChange}
            name="groupSecFN"
            readOnly={readonly}
            placeholder="First Name"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
          />
          <Input
            value={groupDetails.anyOfficialLN}
            onChange={handleInputChange}
            readOnly={readonly}
            placeholder="Lastname"
            name="anyOfficialLN"
            className=" focus-visible:ring-0 focus-visible:ring-offset-0"
          />
        </div>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> officials designation or position </label>

        <Input
          value={groupDetails.anyOfficialPosition}
          onChange={handleInputChange}
          name="anyOfficialPosition"
          readOnly={readonly}
          placeholder="Official Designation or Position"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor=""> Phone number of Official </label>

        <Input
          value={groupDetails.anyOfficialPhone}
          onChange={handleInputChange}
          name="anyOfficialPhone"
          readOnly={readonly}
          placeholder="Official Phone"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
      <hr />

      {!readonly && (
        <div className="flex justify-end ">
          <PrimaryOutlineBtns
            primaryBtnText="Save"
            primaryBtnNotLink
            primaryBtnOnclick={function () {}}
            outlineBtnText="Cancel"
            outlineBtnOnclick={() => navigate(-1)}
            primaryBtnIcon={<Pencil />}
          />
        </div>
      )}
    </div>
  );
};

export default GroupForm;
