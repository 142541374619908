import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../lib/redux/store";

import { TableBody, TableCell, TableRow } from "../../ui/Table";
import { getAllGPZ } from "../../../lib/redux/slices/geolocation/locationThunk";
import LoaderIcon from "../../../components/shared/atoms/LoaderIcon";

interface GolocationTableBodyProps {}
const GolocationTableBody: FC<GolocationTableBodyProps> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { allGPZ, isLoading } = useSelector(
    (store: RootState) => store.geolocation
  );

  useEffect(() => {
    dispatch(getAllGPZ());
  }, [dispatch]);

  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}
      {!isLoading &&
        allGPZ &&
        allGPZ.length > 0 &&
        allGPZ.map((location, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium capitalize">
              {location.name}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

export default GolocationTableBody;
