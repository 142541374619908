import { FC, useEffect } from "react";
import {
  getAllSettlements,
  getAllStateLGA,
  getAllStates,
} from "../../lib/redux/slices/geolocation/locationThunk";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLiteracyLevels,
  getAllOccupations,
} from "../../lib/redux/slices/demography/demographyThunk";
import { IBioData } from "../../types/biodata";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import { Mail } from "lucide-react";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import SelectDate from "../shared/SelectDate";

interface BiodataFormProps {
  handleFormSubmit: () => void;
  inputDetails: Omit<IBioData, "id">;
  date: Date | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setInputDetils: React.Dispatch<React.SetStateAction<Omit<IBioData, "id">>>;
}
const BiodataForm: FC<BiodataFormProps> = ({
  handleFormSubmit,
  inputDetails,
  setInputDetils,
  date,
  setDate,
}) => {
  const { allStateLGA, allSettlements, allStates } = useSelector(
    (store: RootState) => store.geolocation
  );

  const { allOccupation, allLiteracyLevels } = useSelector(
    (store: RootState) => store.demography
  );
  const { isLoading } = useSelector((store: RootState) => store.biodata);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getAllSettlements());
    dispatch(getAllOccupations());
    dispatch(getAllLiteracyLevels());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllStateLGA(inputDetails.state_id));
  }, [dispatch, inputDetails.state_id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputDetils({ ...inputDetails, [name]: value });
  };

  const handleSelectChange = (value: string, name: string) => {
    setInputDetils({ ...inputDetails, [name]: value });
  };

  return (
    <div className="space-y-12">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Name
        </label>
        <div className="flex gap-4 items-center">
          <Input
            value={inputDetails.first_name}
            onChange={handleChange}
            name="first_name"
            placeholder="Firstname"
            className="flex-1 focus-visible:ring-0 focus-visible:ring-offset-0"
          />
          <Input
            value={inputDetails.last_name}
            onChange={handleChange}
            placeholder="Lastname"
            name="last_name"
            className="flex-1 focus-visible:ring-0 focus-visible:ring-offset-0"
          />
          <Input
            value={inputDetails.other_name}
            onChange={handleChange}
            placeholder="Other Names"
            name="other_name"
            className="flex-1 focus-visible:ring-0 focus-visible:ring-offset-0"
          />
        </div>
      </div>
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Gender & Date of Bith
        </label>
        <div className="flex gap-4 items-center">
          <div className="space-y-3 flex-1">
            <label htmlFor="dob">Gender</label>

            <Select
              value={inputDetails.gender}
              onValueChange={(value) => handleSelectChange(value, "gender")}
            >
              <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
                <SelectValue placeholder="-Select Gender-" />
              </SelectTrigger>
              <SelectContent>
                {["male", "female"].map((item, index) => (
                  <SelectItem key={index} value={item}>
                    <span className="capitalize"> {item}</span>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-3 flex-1">
            <label htmlFor="dob">Date Of Birth</label>

            <SelectDate
              date={date}
              setDate={setDate}
              placeholder="Date Of Birth"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Contact
        </label>
        <div className="flex gap-4 items-center">
          <div className="space-y-3 flex-1">
            <label htmlFor="phone_number">Email</label>
            <div className="border rounded-lg flex items-center gap-3 px-6 py-4  h-18">
              <Mail className="text-gray-500" />
              <Input
                value={inputDetails.email}
                onChange={handleChange}
                name="email"
                placeholder="Email"
                className=" focus-visible:ring-0 focus-visible:ring-offset-0 border-0 py-0 m-0"
              />
            </div>
          </div>
          <div className="space-y-3 flex-1">
            <label htmlFor="phone_number">Phone</label>

            <Input
              value={inputDetails.phone_number}
              onChange={handleChange}
              name="phone_number"
              placeholder="Phone"
              className=" focus-visible:ring-0 focus-visible:ring-offset-0"
            />
          </div>
        </div>
      </div>
      <hr />

      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Residence{" "}
        </label>
        <div className="flex items-center gap-4">
          <div className="space-y-3 flex-1">
            <label className="font-normal text-base" htmlFor="">
              Residence State
            </label>
            <Select
             value={inputDetails.state_id?.toString()}
              onValueChange={(value) => handleSelectChange(value, "state_id")}
            >
              <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
                <SelectValue placeholder="-Select State-" />
              </SelectTrigger>
              <SelectContent>
                {allStates &&
                  allStates.map((item, index) => (
                    <SelectItem key={index} value={item.id.toString()}>
                      {item.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-3 flex-1">
            <label className="font-normal text-base" htmlFor="">
              Residence LGA
            </label>
            <Select
             value={inputDetails.lga_id?.toString()}
              onValueChange={(value) => handleSelectChange(value, "lga_id")}
            >
              <SelectTrigger
                disabled={!allStateLGA || allStateLGA.length === 0}
                className="focus-visible:ring-0 focus-visible:ring-offset-0 "
              >
                <SelectValue placeholder="-Select LGA-" />
              </SelectTrigger>
              <SelectContent>
                {allStateLGA &&
                  allStateLGA.map((item, index) => (
                    <SelectItem key={index} value={item.id.toString()}>
                      {item.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Settlement Type
        </label>

        <Select
         value={inputDetails.settlement_id?.toString()}
          onValueChange={(value) => handleSelectChange(value, "settlement_id")}
        >
          <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select Settlement-" />
          </SelectTrigger>
          <SelectContent>
            {allSettlements &&
              allSettlements.map((item, index) => (
                <SelectItem key={index} value={item.id.toString()}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Occupation
        </label>

        <Select
         value={inputDetails.occupation_id?.toString()}
          onValueChange={(value) => handleSelectChange(value, "occupation_id")}
        >
          <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select Occupation-" />
          </SelectTrigger>
          <SelectContent>
            {allOccupation &&
              allOccupation.map((item, index) => (
                <SelectItem key={index} value={item.id.toString()}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Literacy Level
        </label>

        <Select
         value={inputDetails.literacy_level_id?.toString()}
          onValueChange={(value) =>
            handleSelectChange(value, "literacy_level_id")
          }
        >
          <SelectTrigger className="focus-visible:ring-0 focus-visible:ring-offset-0 ">
            <SelectValue placeholder="-Select Literacy Level-" />
          </SelectTrigger>
          <SelectContent>
            {allLiteracyLevels &&
              allLiteracyLevels.map((item, index) => (
                <SelectItem key={index} value={item.id.toString()}>
                  {item.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label className="font-medium" htmlFor="">
          Association
        </label>

        <Input
          value={inputDetails.association}
          onChange={handleChange}
          name="association"
          placeholder="Association"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0"
        />
      </div>
      <hr />

      <div className="flex justify-end ">
        <Button isLoading={isLoading} onClick={() => handleFormSubmit()}>
          Save Info
        </Button>
      </div>
    </div>
  );
};

export default BiodataForm;
