import { createSlice } from "@reduxjs/toolkit";

interface RoutingSliceState {
  activeIndex: number;
}

const initialState: RoutingSliceState = {
  activeIndex: 0,
};

const routingSlice = createSlice({
  name: "Routing",
  initialState,
  reducers: {
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
    },
  },
});

export const { setActiveIndex } = routingSlice.actions;

export default routingSlice.reducer;
