import { FC } from "react";
import { Input } from "../../ui/Input";

interface OccupationFormProps {
  readonly?: boolean;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}
const OccupationForm: FC<OccupationFormProps> = ({
  readonly,
  name,
  setName,
}) => {
  return (
    <div className="space-y-6 text-base font-medium">
      <div className="grid items-center grid-cols-[1fr_2fr]">
        <label htmlFor="">Occupation Name</label>

        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          readOnly={readonly}
          placeholder="Name"
          className=" focus-visible:ring-0 focus-visible:ring-offset-0 w-[70%]"
        />
      </div>
    </div>
  );
};

export default OccupationForm;
