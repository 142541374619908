import { RootState } from "../../../lib/redux/store";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DoughnutChart from "../../charts/DoughnutChart";
import { GoDotFill } from "react-icons/go";

const colorList = [
  "#00AA55",
  "#41B405",
  "#61CE70",
  "#CAEAB9",
  "#98FB98",
  "#9fe2bf",
  "#90ee90",
];


interface LiteracyLevelDistriutionProps {}

const LiteracyLevelDistriution: FC<LiteracyLevelDistriutionProps> = () => {
  const [labels, setLabels] = useState<string[]>([]);
  const [datasets, setDatasets] = useState<number[]>([]);

  const { digitizationAnalytics } = useSelector(
    (store: RootState) => store.analytics
  );


  useEffect(() => {
    if (digitizationAnalytics) {
      setLabels(
        digitizationAnalytics.literacy_level_counts.map(
          (item) => item.literacy_level_name
        )
      );
      setDatasets(
        digitizationAnalytics.literacy_level_counts.map((item) => item.total)
      );
    }
  }, [digitizationAnalytics]);
  return (
    <div className="p-8 relative bg-backgroundLight rounded-lg mb-8 border border-[#EAECF0] cardShadow">
      <h4 className="text-xl font-medium text-darkBlue">
       Literacy Level Distribution
      </h4>
      <hr className="mt-2 mb-6" />

      <div className="absolute top-[8rem] right-8">
        {labels?.map((lable, index) => (
          <div key={index} className="flex gap-3 items-center">
            <GoDotFill size={16} color={colorList[index]} />

            <span className="text-base">{lable as string}</span>
          </div>
        ))}
      </div>

      <DoughnutChart
        chartData={{
          labels: labels,
          datasets: [
            {
              label: "Occuption",
              data: datasets,
              backgroundColor: colorList,
              borderColor: "#FFFFFF",
              spacing: 4,
              weight: 0.5,
            },
          ],
        }}
      />
    </div>
  );
};

export default LiteracyLevelDistriution;
