import { addNewBiodata } from "../lib/redux/slices/biodata/biodataThunk";
import AuthPageHeader from "../components/shared/AuthPageHeader";

import { FC, useState } from "react";

import { toast } from "../hooks/useToast";

import { changeStateValue } from "../lib/redux/slices/geolocation/locationSlice";

import { format } from "date-fns";
import { IBioData } from "@/types/biodata";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../lib/redux/store";
import BiodataForm from "../components/biodata/BiodataForm";

interface AddBiodataProps {}
const AddBiodata: FC<AddBiodataProps> = () => {
  const [inputDetails, setInputDetils] = useState<Omit<IBioData, "id">>({
    last_name: "",
    first_name: "",
    other_name: "",
    phone_number: "",
    gender: "",
    email: "",
    dob: "",
    lga_id: "",
    state_id: "",
    occupation_id: "",
    literacy_level_id: "",
    settlement_id: "",
    association: "",
  });

  const [date, setDate] = useState<Date>();

  const dispatch = useDispatch<AppDispatch>();

  const handleFormSubmit = async () => {
    try {
      if (
        !inputDetails.first_name ||
        !inputDetails.last_name ||
        !inputDetails.other_name ||
        !inputDetails.gender ||
        !inputDetails.email ||
        !inputDetails.phone_number ||
        !inputDetails.state_id ||
        !inputDetails.lga_id ||
        !inputDetails.occupation_id ||
        !inputDetails.settlement_id ||
        !inputDetails.association ||
        !date
      ) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }

      const res = await dispatch(
        addNewBiodata({
          ...inputDetails,
          dob: format(date, "yyy-LL-dd"),
        })
      );
    
      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      changeStateValue({ name: "allStateLGA", value: null });

      return toast({
        title: "New Data Created",
        description: "You haved Added A new Biodata",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-white p-12 text-base">
      <div className="w-[60%] mx-auto space-y-12">
        <div className="flex w-full justify-center">
          <AuthPageHeader title={"Bio Data Form "} />
        </div>

        <BiodataForm
          setDate={setDate}
          setInputDetils={setInputDetils}
          date={date}
          inputDetails={inputDetails}
          handleFormSubmit={handleFormSubmit}
        />
      </div>
    </div>
  );
};

export default AddBiodata;
