import { FC } from "react";
import { Checkbox } from "../../ui/Checkbox";

interface TextCheckboxProps {
  id: string;
  text: string;
  hideCheck?: boolean;
}
const TextCheckbox: FC<TextCheckboxProps> = ({ text, id, hideCheck }) => {
  return (
    <div className="flex gap-4 items-center ">
      {!hideCheck && (
        <Checkbox
          className="w-[2rem] h-[2rem] rounded-lg border-gray-600"
          name={id}
        />
      )}
      <label className="text-base" htmlFor={id}>
        {text}
      </label>
    </div>
  );
};

export default TextCheckbox;
