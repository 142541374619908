import { FC, useEffect, useState } from "react";
import PageHeader from "../components/shared/PageHeader";
import SearchFilterContainer from "../components/accessManagement/organisms/SearchFilterContainer";
import CustomTable from "../components/shared/customTable/CustomTable";
import GroupTableBody from "../components/group/GroupTableBody";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../lib/redux/store";

import { Button } from "../components/ui/Button";
import { CloudUpload } from "lucide-react";
import { IfilteOptns } from "../types/custom";
import { getAllGroups } from "../lib/redux/slices/group/groupThunk";
import Fuse from "fuse.js";
import { updateGroupStateValues } from "../lib/redux/slices/group/groupSlice";

interface GroupsProps {}
const Groups: FC<GroupsProps> = () => {
  const [filterOpts, setFilterOptns] = useState<IfilteOptns>({
    status: "",
  });

  const [search, setSearch] = useState("");

  const { pagination, allGroups } = useSelector(
    (store: RootState) => store.group
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!allGroups) {
      dispatch(getAllGroups(1));
    }
  }, [allGroups, dispatch]);

  useEffect(() => {
    const getData = async () => {
      return await dispatch(getAllGroups(1));
    };
    if (search.length === 0) {
      getData();
    }
  }, [dispatch, search]);

  const handleSearch = () => {
    if (allGroups) {
      const fuse = new Fuse(allGroups, options);

      if (search.length === 0) {
        dispatch(getAllGroups(1));
      }

      const results = fuse.search(search);
      const items = results.map((result) => result.item);
      dispatch(
        updateGroupStateValues({
          name: "allGroups",
          value: items,
        })
      );
    }
  };

  const selectPage = async (index: number) => {
    try {
      await dispatch(getAllGroups(index));
    } catch (error) {
      console.log(error);
    }
  };
  const moveNextPage = async () => {
    try {
      pagination &&
        pagination.current_page < pagination.last_page &&
        (await dispatch(getAllGroups(pagination.current_page + 1)));
    } catch (error) {
      console.log(error);
    }
  };

  const movePrevPage = async () => {
    try {
      pagination &&
        pagination.current_page > 1 &&
        (await dispatch(getAllGroups(pagination.current_page - 1)));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <PageHeader title="Group" subTitle="Manage Groups here" />
      <SearchFilterContainer
        handleSearch={handleSearch}
        setFilterOptns={setFilterOptns}
        search={search}
        setSearch={setSearch}
        filterOpts={filterOpts}
      />

      <div className="border border-[#EAECF0] p-8 shadow-sm rounded-lg bg-white ">
        <div className="flex justify-end mb-8">
          <Button>
            <CloudUpload /> Upload CSV
          </Button>
        </div>
        {pagination && (
          <CustomTable
            tableBody={<GroupTableBody />}
            headingList={groupTableHeadings}
            numberOfPages={pagination.last_page}
            currentPage={pagination.current_page}
            handleSelectPage={(index: number) => selectPage(index)}
            handlePrevPage={() => movePrevPage()}
            handleNextPage={() => moveNextPage()}
          />
        )}
      </div>
    </div>
  );
};

export default Groups;

const groupTableHeadings = [
  {
    text: "Name of Group",
  },
  {
    text: "Name of Group Leader",
  },
  {
    text: "Name of Group Secretary ",
  },
  {
    text: "State",
  },
  {
    text: "Purpose of Group",
  },
];

const options = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.2,
  keys: ["name"],
};
