import { CircleX, Eye, Pencil } from "lucide-react";
import { FC, useEffect, useRef, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { openModal } from "../../lib/redux/slices/dialogSlice";
import { TableBody, TableCell, TableRow } from "../ui/Table";
import { Button } from "../ui/Button";
import { setSelectedAgency } from "../../lib/redux/slices/mda/mdaSlice";
import { SlOptionsVertical } from "react-icons/sl";
import OptsDropdown from "../shared/OptsDropdown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import LoaderIcon from "../shared/atoms/LoaderIcon";
import {
  getAllAgencies,
  getAllMinistries,
} from "../../lib/redux/slices/mda/mdaThunk";

interface AgenciesTableBodyProps {}
const AgenciesTableBody: FC<AgenciesTableBodyProps> = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [skip, setSkip] = useState(0);
  const [agenciesData, setAgenciesData] = useState<IAgencies[] | null>(null);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setSelectedId(null));
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    allAgencies,
    currentPage,
    isFiltering,
    isSearching,
    searchList,
    filterList,
    isLoading,
    limit,
  } = useSelector((store: RootState) => store.mda);

  useEffect(() => {
    const getData = async () => {
      await dispatch(getAllAgencies());
      await dispatch(getAllMinistries());
    };

    if (!allAgencies || !getAllMinistries) {
      getData();
    }
  }, [allAgencies, dispatch]);

  useEffect(() => {
    setSkip((currentPage - 1) * limit);
  }, [currentPage, limit]);

  useEffect(() => {
    if (isFiltering) {
      setAgenciesData(filterList);
    } else if (isSearching) {
      setAgenciesData(searchList);
    } else {
      setAgenciesData(allAgencies);
    }
  }, [isFiltering, filterList, allAgencies, isSearching, searchList]);

  const lGATableOptions = [
    {
      id: "1",
      icon: <Pencil />,
      text: "Edit",
      btnOnclick: function (gpzId: string) {
        navigate(`${gpzId}/edit`);
      },
    },
    {
      id: "2",
      icon: <Eye />,
      text: "View",
      btnOnclick: function (gpzId: string) {
        navigate(`${gpzId}`);
      },
    },

    {
      id: "4",
      icon: <CircleX />,
      text: "Delete",
      btnOnclick: function () {
        dispatch(
          openModal({
            icon: <IoWarningOutline size={28} className="text-[#DC6803]" />,
            iconBg: "bg-[#FEF0C7]",
            iconBorderColor: "border-[#FFFAEB]",
            iconColor: "text-[#DC6803]",
            primaryColor: "bg-[#D83535]",
            title: "Delete Agency",
            description:
              "When you click on confirm, Agency will be automatically Deleted",
          })
        );
      },
    },
  ];
  return (
    <TableBody>
      {isLoading && <LoaderIcon width={40} height={40} />}

      {!isLoading &&
        agenciesData &&
        agenciesData.length > 0 &&
        agenciesData.slice(skip, skip + limit).map((agency, index) => (
          <TableRow
            className="odd:bg-white even:bg-slate-100 text-base border-b-0"
            key={index}
          >
            <TableCell className="font-medium">{index + 1}</TableCell>

            <TableCell className="font-medium">{agency.name}</TableCell>
            <TableCell className="font-medium">{agency.contact}</TableCell>
            <TableCell className="font-medium">
              {agency.ministry.name}
            </TableCell>

            <TableCell className="relative">
              <Button
                onClick={() => {
                  setSelectedId(agency.id);
                  dispatch(setSelectedAgency(agenciesData[index]));
                }}
                variant={"ghost"}
              >
                <SlOptionsVertical />
              </Button>
              {selectedId === agency.id && (
                <div ref={ref}>
                  <OptsDropdown
                    options={lGATableOptions}
                    selectedId={selectedId}
                  />
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      {!isLoading && agenciesData && agenciesData.length === 0 && (
        <TableRow>
          <TableCell rowSpan={0}>
            <h4 className="p-8 text-base font-semibold flex justify-center ">
              No Agency to display
            </h4>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default AgenciesTableBody;
