import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addNewBiodata,
  getAllBioData,
  getFilteredBioData,
  sendMail,
  updateBiodata,
} from "./biodataThunk";
import { IBioData } from "../../../../types/biodata";

interface biodataSliceState {
  [key: string]: any;
  selectedBiodata: IBioData | null;
  allBioData: IBioData[] | null;
  biodataRecipeint: IBioData[] | null;
  isEditBiodata: boolean;
  currentPage: number;
  isLoading: boolean;
  limit: number;
  numberOfPages: number;
  error?: string;
}

interface UpdateStatePayload {
  name: keyof biodataSliceState;
  value: any;
}

// type UserProfileKeys = keyof UserProfile;

const initialState: biodataSliceState = {
  selectedBiodata: null,
  allBioData: null,
  biodataRecipeint: null,
  isEditBiodata: false,
  currentPage: 1,
  isLoading: false,
  limit: 10,
  numberOfPages: 0,
  error: "",
};

const biodataSlice = createSlice({
  name: "biodata",
  initialState,
  reducers: {
    setSelectedBiodata: (state, action) => {
      state.selectedBiodata = action.payload;
    },
    updateBiodataStateValues: (
      state,
      action: PayloadAction<UpdateStatePayload>
    ) => {
      state[action.payload.name] = action.payload.value;
    },

    selectPage: (state, action) => {
      if (action.payload <= state.numberOfPages && action.payload >= 0) {
        state.currentPage = action.payload;
      }
    },

    moveNextPage: (state) => {
      if (state.currentPage < state.numberOfPages) {
        state.currentPage += 1;
      }
    },
    movePrevPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllBioData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBioData.fulfilled, (state, action) => {
        state.allBioData = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;

        state.isLoading = false;
      })
      .addCase(getAllBioData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getFilteredBioData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFilteredBioData.fulfilled, (state, action) => {
        console.log(action.payload.data, "action.payload.data");
        state.allBioData = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;

        state.isLoading = false;
      })
      .addCase(getFilteredBioData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addNewBiodata.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewBiodata.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addNewBiodata.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateBiodata.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBiodata.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateBiodata.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(sendMail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(sendMail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  selectPage,
  moveNextPage,
  updateBiodataStateValues,
  movePrevPage,
  setSelectedBiodata,
} = biodataSlice.actions;

export default biodataSlice.reducer;
