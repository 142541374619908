import axios, { AxiosError } from "axios";
import { createAppAsyncThunk } from "../../createAppAsyncThunk";
import { IBioData } from "@/types/biodata";
import { IBiodataFilterOptns } from "@/types/custom";

const baseUrl = "https://api.database.noa.gov.ng/api";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config) {
    const authToken = sessionStorage.getItem("authToken");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAllBioData = createAppAsyncThunk(
  "biodata/getAllBioData",
  async (_, thunkAPI) => {
    const limit = 10;
    try {
      const res = await axiosInstance.get(`${baseUrl}/bio-data`);

      const numberOfPages = Math.ceil(res.data?.data?.bios.length / limit) || 1;

      return { data: res.data.data.bios || [], numberOfPages };
    } catch (error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Get Biodata");
    }
  }
);
export const getFilteredBioData = createAppAsyncThunk(
  "biodata/getFilteredBioData",
  async (payload: IBiodataFilterOptns, thunkAPI) => {
    const limit = 10;
    try {
      const res = await axiosInstance.post(
        `${baseUrl}/bio-data/filter`,
        payload
      );

      const numberOfPages = Math.ceil(res.data?.data?.length / limit) || 1;

      return { data: res.data.data || [], numberOfPages };
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data?.message);
      }
      return thunkAPI.rejectWithValue("Could not Get Filtered Biodata");
    }
  }
);

export const addNewBiodata = createAppAsyncThunk(
  "biodata/addNewBiodata",
  async (data: IBioData, thunkAPI) => {
    try {
      await axios.post(
        `${baseUrl}/bio-data`,

        data
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status !== 500) {
          return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
      }
      return thunkAPI.rejectWithValue("Could not Add Biodata");
    }
  }
);
export const updateBiodata = createAppAsyncThunk(
  "biodata/updateBiodata",
  async (data: IBioData, thunkAPI) => {
    try {
      await axiosInstance.post(
        `${baseUrl}/bio-data/update`,

        { ...data, bio_data_id: data.id?.toString() }
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status !== 500) {
          return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
      }
      return thunkAPI.rejectWithValue("Could not Add Biodata");
    }
  }
);

export interface ISendMail {
  subject: string;
  message: string;
  gender: string;
  state_id: string;
  geo_political_zone_id: string;
  age_group_id: string;
  literacy_level_id: string;
}
export const sendMail = createAppAsyncThunk(
  "biodata/sendMail",
  async (data: ISendMail, thunkAPI) => {
    try {
      await axiosInstance.post(
        `${baseUrl}/bio-data/send/mail`,

        data
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status !== 500) {
          return thunkAPI.rejectWithValue(error.response?.data?.message);
        }
      }
      return thunkAPI.rejectWithValue("Could not Send Mails");
    }
  }
);
