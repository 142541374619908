import { FC, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";
import { Button, buttonVariants } from "../ui/Button";
import { ChevronDown, Plus, Send } from "lucide-react";
import { Link } from "react-router-dom";
import { cn } from "../../lib/shadcnUtils";

interface BiodataActionsPopoverProps {}
const BiodataActionsPopover: FC<BiodataActionsPopoverProps> = () => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          role="combobox"
          aria-expanded={open}
          className=" gap-4 justify-start p-6  h-fit "
        >
          <span className="text-base font-semibold">Actions</span>
          <ChevronDown />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions
        side="bottom"
        className="w-[20rem] p-8 space-y-3 bg-white text-base rounded-2xl shadow-lg"
      >
        <Link
          to="/bio-data-form"
          className={cn(buttonVariants({ variant: "ghost" }))}
        >
          <Plus />
          <span>Add Biodata</span>
        </Link>
        <Link
          to="send-mail"
          className={cn(buttonVariants({ variant: "ghost" }))}
        >
          <Send />
          <span>Send Email</span>
        </Link>
      </PopoverContent>
    </Popover>
  );
};

export default BiodataActionsPopover;
