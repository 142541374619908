import { FC, useState } from "react";
import SidebarLinks from "./SidebarLinks";
import {
  BarChart2,
  Boxes,
  ChevronDown,
  ChevronUp,
  Component,
  CopyCheck,
  Database,
  LogOut,
  MapPin,
  MoveLeft,
  Settings,
  User,
  Zap,
} from "lucide-react";
import UserAvater from "../shared/atoms/UserAvater";
import { Button } from "../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../lib/redux/store";
import { setShowSidebar } from "../../lib/redux/slices/dialogSlice";
import { logout } from "../../lib/redux/slices/auth/authSlice";

interface SidebarProps {}
const Sidebar: FC<SidebarProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [showAccessMgtLinks, setShowAccessMgtLinks] = useState(false);
  const [showMdaLinks, setShowMdaLinks] = useState(false);
  const [showLocationLinks, setShowLocationLinks] = useState(false);
  const { userProfile } = useSelector((store: RootState) => store.auth);

  return (
    <div className="border border-gray-200">
      <div className="p-4 flex justify-between items-center">
        <img
          src="/assets/images/noa_logo.svg"
          alt="NOA Logo"
          className="w-[18.6rem] mb-4"
        />

        <Button
          onClick={() => dispatch(setShowSidebar(false))}
          className="w-fit h-fit p-0"
          variant={"ghost"}
        >
          <MoveLeft />
        </Button>
      </div>

      <div className="min-h-[70vh] text-gray-defualt">
        <SidebarLinks
          icon={<BarChart2 height={24} width={24} />}
          title="Dashbaord"
          link="/"
        />
        <div className="relative">
          <SidebarLinks
            icon={<Zap height={24} width={24} />}
            title="Access Management"
            link="/access-mgmt"
            hasNestedLinks
            btnOnclick={() => setShowAccessMgtLinks(!showAccessMgtLinks)}
          />
          {showAccessMgtLinks && (
            <div className=" w-full">
              <SidebarLinks
                icon={<User height={24} width={24} />}
                title="Users Management"
                link="/users-management"
                isNestedLink
              />
              <SidebarLinks
                icon={<User height={24} width={24} />}
                title="Roles"
                link="/roles-management"
                isNestedLink
              />
            </div>
          )}
        </div>
        <hr />
        <div className="relative">
          <SidebarLinks
            icon={<Boxes height={24} width={24} />}
            title="MDAs"
            link="/mdas"
            hasNestedLinks
            btnOnclick={() => setShowMdaLinks(!showMdaLinks)}
          />
          {showMdaLinks && (
            <div className=" w-full">
              <SidebarLinks
                icon={<Component height={24} width={24} />}
                title="Ministeries"
                link="/ministries"
                isNestedLink
              />
              <SidebarLinks
                icon={<Component height={24} width={24} />}
                title="Agencies"
                link="/agencies"
                isNestedLink
              />
            </div>
          )}
        </div>

        <SidebarLinks
          icon={<CopyCheck height={24} width={24} />}
          title="Groups"
          link="/groups"
        />
        <SidebarLinks
          icon={<CopyCheck height={24} width={24} />}
          title="Demography"
          link="/age-group"
        />
        <div className="relative">
          <SidebarLinks
            icon={<MapPin height={24} width={24} />}
            title="Location"
            link="/location"
            hasNestedLinks
            btnOnclick={() => setShowLocationLinks(!showLocationLinks)}
          />

          {showLocationLinks && (
            <div className="w-full">
              <SidebarLinks
                icon={<MapPin height={24} width={24} />}
                title="Geo Location"
                link="/geo-political-zone"
                isNestedLink
              />
              <SidebarLinks
                icon={<MapPin height={24} width={24} />}
                title="Settlement"
                link="/settlement"
                isNestedLink
              />
            </div>
          )}
        </div>

        <SidebarLinks
          icon={<Settings height={24} width={24} />}
          title="Settings"
          link="/settings"
        />
        <SidebarLinks
          icon={<Database height={24} width={24} />}
          title="Bio Data"
          link="/bio-data"
        />
      </div>

      <div className="  p-8">
        <div className="flex gap-8">
          <UserAvater imgUrl="https://github.com/shadcn.png" name="Counsel" />
          <div className="space-y-3">
            <div>
              <h5 className="montserrat-alternates-medium text-xl text-[#191919] line-clamp-1">
                {userProfile && userProfile.name}
              </h5>
              <span className="montserrat-alternates-regular capitalize text-base">
                {userProfile && userProfile.role}
              </span>
            </div>
            <Button
              variant={"ghost"}
              onClick={() => dispatch(logout())}
              className="h-fit ml-0 pl-0 flex gap-2 justify-start items-center w-full text-rose-500 hover:text-rose-700 rounded-lg"
            >
              <LogOut />
              <span>Logout</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
