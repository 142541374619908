export const interactionRateTableHeader = [
  {
    text: "Response",
  },
  {
    text: "Rural",
  },
  {
    text: "Urban",
  },
  {
    text: "Rural Urban",
  },
  {
    text: "",
  },
];
export const interactionRateTableData = [
  {
    id: "1",
    response: "Average",
    rural: "Oyo",
    urban: "Ibarapa North",
    ruralUrban: 10,
  },
  {
    id: "2",
    response: "Average",
    rural: "Oyo",
    urban: "Ibarapa North",
    ruralUrban: 10,
  },
  {
    id: "3",
    response: "Average",
    rural: "Oyo",
    urban: "Ibarapa North",
    ruralUrban: 10,
  },
  {
    id: "4",
    response: "Average",
    rural: "Oyo",
    urban: "Ibarapa North",
    ruralUrban: 10,
  },
  {
    id: "5",
    response: "Average",
    rural: "Oyo",
    urban: "Ibarapa North",
    ruralUrban: 10,
  },
  {
    id: "6",
    response: "Average",
    rural: "Oyo",
    urban: "Ibarapa North",
    ruralUrban: 10,
  },
];

export const userTableHeadings = [
  "Name",
  "Email Address",
  "Date Added",
  "UserRole",
  "Status",
  "",
];

export enum UserRole {
  admin = "admin",
  super_admin = "super_admin",
}
export enum UserStatus {
  active = 1,
  inactive = 0,
}

export const userTableData = [
  {
    id: "1",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.active,
  },
  {
    id: "2",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.active,
  },
  {
    id: "3",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.inactive,
  },
  {
    id: "4",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.inactive,
  },
  {
    id: "5",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.active,
  },
  {
    id: "6",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.inactive,
  },
  {
    id: "7",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.inactive,
  },
  {
    id: "8",
    fullname: "Olivia Rhye",
    username: "@phoenix",
    email: "phoenix@untitledui.com",
    dateAdded: "21/12/2022",
    role: UserRole.admin,
    status: UserStatus.inactive,
  },
];

export const roleTableData = [
  {
    id: "1",
    role: "Admin",
    decription:
      "This role is responsible for managing user data accross the application.",
    status: UserStatus.active,
  },
  {
    id: "2",
    role: "Super Admin",
    decription:
      "This role is responsible for managing user accounts, permissions, and system configurations.",
    status: UserStatus.active,
  },
];
