import { FC, ReactNode } from "react";
import { Button, buttonVariants } from "../ui/Button";
import { Link } from "react-router-dom";
import { cn } from "../../lib/shadcnUtils";

interface PrimaryOutlineBtnsProps {
  isLoading?: boolean;
  outlineBtnText?: string;
  primaryBtnText: string;
  outlineBtnOnclick?: Function;
  primaryBtnLink?: string;
  outlineBtnIcon?: ReactNode;
  primaryBtnIcon?: ReactNode;
  primaryBtnNotLink?: boolean;
  primaryBtnOnclick?: Function;
}
const PrimaryOutlineBtns: FC<PrimaryOutlineBtnsProps> = ({
  outlineBtnText,
  isLoading,
  primaryBtnText,
  outlineBtnOnclick,
  primaryBtnLink,
  outlineBtnIcon,
  primaryBtnIcon,
  primaryBtnNotLink,
  primaryBtnOnclick,
}) => {
  return (
    <div className="flex gap-4 items-center ">
      {outlineBtnOnclick && (
        <Button
          onClick={() => outlineBtnOnclick()}
          variant={"outline"}
          className="bg-transparent"
        >
          {outlineBtnIcon}
          {outlineBtnText}
        </Button>
      )}
      {!primaryBtnNotLink
        ? primaryBtnLink && (
            <Link
              className={cn(buttonVariants({ variant: "default" }))}
              to={primaryBtnLink}
            >
              {primaryBtnIcon}
              {primaryBtnText}
            </Link>
          )
        : primaryBtnOnclick && (
            <Button isLoading={isLoading} onClick={() => primaryBtnOnclick()}>
              {primaryBtnIcon}
              {primaryBtnText}
            </Button>
          )}
    </div>
  );
};

export default PrimaryOutlineBtns;
