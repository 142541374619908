import { FC } from "react";
import DigitizationCardItem from "../molecules/DigitizationCardItem";
import { BookUser, Boxes, Briefcase, CircleUser, Component, Group, MapPinned, PersonStanding, PieChart } from "lucide-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../lib/redux/store";


interface DigitizationCardGridProps {}
const DigitizationCardGrid: FC<DigitizationCardGridProps> = () => {
  const { digitizationAnalytics } = useSelector(
    (store: RootState) => store.analytics
  );

  return (
    <div className="grid grid-cols-4 gap-8 rounded-xl mb-8 bg-white py-16 px-8">
      <DigitizationCardItem
        index={1}
        icon={<CircleUser />}
        title="Bio Data "
        value={digitizationAnalytics?.bio_data_count}
      />
      <DigitizationCardItem
        index={7}
        icon={<PersonStanding />}
        title="Age Group "
        value={digitizationAnalytics?.unique_age_groups_count}
      />
      <DigitizationCardItem
        index={6}
        icon={<BookUser />}
        title="Level of Literacy "
        value={digitizationAnalytics?.literacy_level_count}
      />
      <DigitizationCardItem
        index={4}
        icon={<Briefcase />}
        title="Occupation "
        value={digitizationAnalytics?.unique_occupations_count}
      />
      <DigitizationCardItem
        index={5}
        icon={<Group />}
        title="Agencies "
        value={digitizationAnalytics?.agency_count}
      />
      <DigitizationCardItem
        index={3}
        icon={<Boxes />}
        title="Ministries "
        value={digitizationAnalytics?.ministry_count}
      />
      <DigitizationCardItem
        index={2}
        icon={<MapPinned />}
        title="Geo-Political Zones"
        value={digitizationAnalytics?.geo_political_zone_count}
      />

      <DigitizationCardItem
        index={2}
        icon={<PieChart />}
        title="Unique States"
        value={digitizationAnalytics?.unique_states_count}
      />
      <DigitizationCardItem
        index={8}
        icon={<PieChart />}
        title="Unique LGAs"
        value={digitizationAnalytics?.unique_lgas_count}
      />
      <DigitizationCardItem
        index={5}
        icon={<Component />}
        title="Groups"
        value={digitizationAnalytics?.group_count}
      />
    </div>
  );
};

export default DigitizationCardGrid;
