import { useDispatch, useSelector } from "react-redux";
import BiodataForm from "../components/biodata/BiodataForm";
import { toast } from "../hooks/useToast";
import { IBioData } from "../types/biodata";
import { FC, useEffect, useState } from "react";
import { AppDispatch, RootState } from "../lib/redux/store";
import { updateBiodata } from "../lib/redux/slices/biodata/biodataThunk";
import { format } from "date-fns";
import { changeStateValue } from "../lib/redux/slices/geolocation/locationSlice";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../components/shared/PageHeader";
import { Button } from "../components/ui/Button";
import { MoveLeft } from "lucide-react";

interface ViewBiodataProps {}
const ViewBiodata: FC<ViewBiodataProps> = () => {
  const [inputDetails, setInputDetils] = useState<IBioData>({
    id: 0,
    last_name: "",
    first_name: "",
    other_name: "",
    phone_number: "",
    gender: "",
    email: "",
    dob: "",
    lga_id: "",
    state_id: "",
    occupation_id: "",
    literacy_level_id: "",
    settlement_id: "",
    association: "",
  });

  const { allBioData } = useSelector((store: RootState) => store.biodata);

  let { biodataId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (biodataId && allBioData) {
      const details = allBioData.find(
        (data) => data.id?.toString() === biodataId
      );
      if (details) {
        console.log( details.gender, " details.gender")
        setInputDetils({
          id: details.id,
          last_name: details.last_name,
          first_name: details.first_name,
          other_name: details.other_name,
          phone_number: details.phone_number,
          gender: details.gender,
          email: details.email,
          dob: details.dob,
          lga_id: details.lga_id,
          state_id: details.state_id,
          occupation_id: details.occupation_id,
          literacy_level_id: details.literacy_level_id,
          settlement_id: details.settlement_id,
          association: details.association,
        });

        setDate(new Date(details.dob))
      }
    }
  }, [biodataId, allBioData]);

  const handleFormSubmit = async () => {
    try {
      if (
        !inputDetails.first_name ||
        !inputDetails.last_name ||
        !inputDetails.other_name ||
        !inputDetails.gender ||
        !inputDetails.email ||
        !inputDetails.phone_number ||
        !inputDetails.state_id ||
        !inputDetails.lga_id ||
        !inputDetails.occupation_id ||
        !inputDetails.settlement_id ||
        !inputDetails.association ||
        !date
      ) {
        return toast({
          title: "Invalid Input",
          description: "You haved not entered all fields",
          variant: "destructive",
        });
      }

      const res = await dispatch(
        updateBiodata({
          ...inputDetails,
          dob: format(date, "yyy-LL-dd"),
        })
      );

      if (res.type.includes("rejected"))
        return toast({
          title: "An Error Occurred",
          description: res.payload as string,
          variant: "destructive",
        });

      changeStateValue({ name: "allStateLGA", value: null });

      return toast({
        title: "New Data Created",
        description: "You haved Added A new Biodata",
      });
    } catch (err) {
      console.log(err);
    }
    
  };

  const [date, setDate] = useState<Date>();
  return (
    <div className="text-base space-y-20">
      <div className="flex gap-3 ">
        <Button
          className="p-0 m-0"
          onClick={() => navigate(-1)}
          variant={"ghost"}
        >
          <MoveLeft />
        </Button>
        <PageHeader
          title={"View and Edit Biodata "}
          subTitle="Manage The Selected Biodata Here "
        />
      </div>
      <BiodataForm
        setDate={setDate}
        setInputDetils={setInputDetils}
        date={date}
        inputDetails={inputDetails}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default ViewBiodata;
