import { createSlice } from "@reduxjs/toolkit";
import {
  getDigitizationAnalytics,
  getBiodataByMonth,
} from "./analyticsThunk";
import { IDigitizationAnalytics } from "@/types/analysis";

interface contentSliceState {
  isLoading: boolean;
  biodataByMonth:
    | {
        month: number;
        total: number;
      }[]
    | null;
  filter: string;
  digitizationAnalytics: IDigitizationAnalytics | null;
}

// type UsercontentsKeys = keyof Usercontents;

const initialState: contentSliceState = {
  digitizationAnalytics: null,
  biodataByMonth: null,
  filter: "Geopolitical Zone",
  isLoading: false,
};

const contentSlice = createSlice({
  name: "contents",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getBiodataByMonth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBiodataByMonth.fulfilled, (state, action) => {
        state.biodataByMonth = action.payload;
        state.isLoading = false;
      })
      .addCase(getBiodataByMonth.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getDigitizationAnalytics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDigitizationAnalytics.fulfilled, (state, action) => {
        state.digitizationAnalytics = action.payload;
        state.isLoading = false;
      })
      .addCase(getDigitizationAnalytics.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setFilter } = contentSlice.actions;

export default contentSlice.reducer;
