import { FC } from "react";
import { Badge } from "../../ui/Badge";
import { UserStatus } from "../../../utils/data/tableData";
import { RoleStatus } from "../../../utils/data/roleData";
import { GoDotFill } from "react-icons/go";
import { LocationStatus } from "../../../utils/data/locationTableData";
import { DemographyStatus } from "../../../utils/data/demographyTableData";
import { GroupStatus } from "../../../utils/data/groupData";

interface CustomBadgeProps {
  status?:
    | RoleStatus
    | UserStatus
    | LocationStatus
    | DemographyStatus
    | GroupStatus;
}
const CustomBadge: FC<CustomBadgeProps> = ({ status }) => {

  return (
    <Badge
      className={`${
        status === UserStatus.active
          ? "bg-[#F1FCF6] text-[#229745]"
          : "bg-[#D835350F] text-[#EB5757]"
      } hover:bg-default w-fit flex gap-2 items-center h-fit`}
    >
      <GoDotFill
        className={
          status === UserStatus.active ? " text-[#229745]" : " text-[#EB5757]"
        }
      />

      {status === UserStatus.active ? "Active" : " Inactive"}
    </Badge>
  );
};

export default CustomBadge;
