import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import BaseLayout from "./components/layouts/BaseLayout";
import UserMgmt from "./pages/UserMgmt";
import NewUser from "./pages/NewUser";
import HeaderLayout from "./components/layouts/HeaderLayout";
import ViewUser from "./pages/ViewUser";
import EditUser from "./pages/EditUser";
import RoleMgmt from "./pages/RoleMgmt";
import NewRole from "./pages/NewRole";
import ViewRole from "./pages/ViewRole";
import EditRole from "./pages/EditRole";
import GeoLocation from "./pages/GeoLocation";
import NewLocation from "./pages/NewLocation";
import ViewLocation from "./pages/ViewLocation";
import EditLocation from "./pages/EditLocation";
import Settlements from "./pages/Settlements";
import Demography from "./pages/Demography";
import NewDemography from "./pages/NewDemography";
import ViewDemography from "./pages/ViewDemography";
import EditDemography from "./pages/EditDemography";
import UserSettings from "./pages/UserSettings";
import Groups from "./pages/Groups";
import CreateViewGroups from "./pages/CreateViewGroups";
import PageNotFound from "./pages/PageNotFound";
import Ministeries from "./pages/Ministeries";
import Agencies from "./pages/Agencies";
import CreateViewEditAgencies from "./pages/CreateViewEditAgencies";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import EmailSent from "./pages/EmailSent";
import EnterAuthCode from "./pages/EnterAuthCode";
import ResetSuccess from "./pages/ResetSuccess";
import BioData from "./pages/BioData";
import AddBiodata from "./pages/AddBiodata";
import BiodataSendMail from "./pages/BiodataSendMail";
import ViewBiodata from "./pages/ViewBiodata";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/email-sent" element={<EmailSent />} />
        <Route path="/auth-code" element={<EnterAuthCode />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-success" element={<ResetSuccess />} />
        <Route path="/bio-data-form" element={<AddBiodata />} />
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Dashboard />} />

          <Route element={<HeaderLayout />}>
            <Route path="users-management">
              <Route index element={<UserMgmt />} />
              <Route path="new" element={<NewUser />} />
              <Route path=":userId" element={<ViewUser />} />
              <Route path=":userId/edit" element={<EditUser />} />
            </Route>
            <Route path="roles-management">
              <Route index element={<RoleMgmt />} />
              <Route path="new" element={<NewRole />} />
              <Route path=":roleId" element={<ViewRole />} />
              <Route path=":roleId/edit" element={<EditRole />} />
            </Route>
            <Route path="ministries">
              <Route index element={<Ministeries />} />
            </Route>
            <Route path="agencies">
              <Route index element={<Agencies />} />
              <Route
                path="new"
                element={<CreateViewEditAgencies mode="new" />}
              />
              <Route
                path=":agencyId"
                element={<CreateViewEditAgencies mode="view" />}
              />
              <Route
                path=":agencyId/edit"
                element={<CreateViewEditAgencies mode="edit" />}
              />
            </Route>

            <Route path="geo-political-zone">
              <Route index element={<GeoLocation activeIndex={0} />} />
              <Route path="new" element={<NewLocation activeIndex={0} />} />
              <Route path=":gpzId" element={<ViewLocation activeIndex={0} />} />
              <Route
                path=":gpzId/edit"
                element={<EditLocation activeIndex={0} />}
              />
            </Route>
            <Route path="state">
              <Route index element={<GeoLocation activeIndex={1} />} />
              <Route path="new" element={<NewLocation activeIndex={1} />} />
              <Route
                path=":stateId"
                element={<ViewLocation activeIndex={1} />}
              />
              <Route
                path=":stateId/edit"
                element={<EditLocation activeIndex={1} />}
              />
            </Route>
            <Route path="lga">
              <Route index element={<GeoLocation activeIndex={2} />} />
              <Route path="new" element={<NewLocation activeIndex={2} />} />
              <Route path=":lgaId" element={<ViewLocation activeIndex={2} />} />
              <Route
                path=":lgaId/edit"
                element={<EditLocation activeIndex={2} />}
              />
            </Route>

            <Route path="settlement">
              <Route index element={<Settlements />} />
              <Route path="new" element={<NewLocation activeIndex={3} />} />
              <Route
                path=":communityId"
                element={<ViewLocation activeIndex={3} />}
              />
              <Route
                path=":communityId/edit"
                element={<EditLocation activeIndex={3} />}
              />
            </Route>

            <Route path="age-group">
              <Route index element={<Demography activeIndex={0} />} />
              <Route path="new" element={<NewDemography activeIndex={0} />} />
              <Route path=":id" element={<ViewDemography activeIndex={0} />} />
              <Route
                path=":id/edit"
                element={<EditDemography activeIndex={0} />}
              />
            </Route>
            <Route path="level-of-literacy">
              <Route index element={<Demography activeIndex={1} />} />
              <Route path="new" element={<NewDemography activeIndex={1} />} />
              <Route path=":id" element={<ViewDemography activeIndex={1} />} />
              <Route
                path=":id/edit"
                element={<EditDemography activeIndex={1} />}
              />
            </Route>
            <Route path="occupation">
              <Route index element={<Demography activeIndex={2} />} />
              <Route path="new" element={<NewDemography activeIndex={2} />} />
              <Route path=":id" element={<ViewDemography activeIndex={2} />} />
              <Route
                path=":id/edit"
                element={<EditDemography activeIndex={2} />}
              />
            </Route>

            <Route path="settings">
              <Route index element={<UserSettings />} />
            </Route>
            <Route path="bio-data">
              <Route index element={<BioData />} />
              <Route path=":biodataId" element={<ViewBiodata />} />
              <Route path="send-mail" element={<BiodataSendMail />} />
            </Route>

            <Route path="groups">
              <Route index element={<Groups />} />
              <Route path="new" element={<CreateViewGroups mode="new" />} />
              <Route path=":id" element={<CreateViewGroups mode="view" />} />
              <Route
                path=":id/edit"
                element={<CreateViewGroups mode="edit" />}
              />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
