import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  addNewAgency,
  deleteAgency,
  getAllAgencies,
  getAllMinistries,
} from "./mdaThunk";

interface mdaSliceState {
  [key: string]: any;
  selectedAgency: Omit<IAgencies, "ministry"> | null;
  selectedMinistry: IMinistry | null;
  allAgencies: IAgencies[] | null;
  allMinistries: IMinistry[] | null;
  isFiltering: boolean;
  isSearching: boolean;
  filterList: IAgencies[] | null;
  searchList: IAgencies[] | null;
  currentPage: number;
  isLoading: boolean;
  limit: number;
  numberOfPages: number;
  error?: string;
}

interface UpdateStatePayload {
  name: keyof mdaSliceState;
  value: any;
}

const initialState: mdaSliceState = {
  selectedAgency: null,
  selectedMinistry: null,
  allAgencies: null,
  allMinistries: null,
  isFiltering: false,
  isSearching: false,
  filterList: null,
  searchList: null,
  currentPage: 1,
  isLoading: false,
  limit: 15,
  numberOfPages: 0,
  error: "",
};

const mdaSlice = createSlice({
  name: "mda",
  initialState,
  reducers: {
    setSelectedAgency: (state, action) => {
      state.selectedAgency = action.payload;
    },
    setSelectedMinistry: (state, action) => {
      state.selectedMinistry = action.payload;
    },
    updateMdaStateValues: (
      state,
      action: PayloadAction<UpdateStatePayload>
    ) => {
      state[action.payload.name] = action.payload.value;
    },

    handleAgencyFilter: (state, action) => {
      if (state.allAgencies) {
      }
    },
    selectPage: (state, action) => {
      if (
        action.payload.newPage <= action.payload.numberOfPages &&
        action.payload.newPage >= 0
      ) {
        state.currentPage = action.payload.newPage;
      }
    },
    moveNextPage: (state, action) => {
      if (state.currentPage < action.payload.numberOfPages) {
        state.currentPage += 1;
      }
    },
    movePrevPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMinistries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMinistries.fulfilled, (state, action) => {
        state.allMinistries = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllMinistries.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.payload;
      })

      .addCase(getAllAgencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAgencies.fulfilled, (state, action) => {
        state.allAgencies = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;
        state.isLoading = false;
      })
      .addCase(getAllAgencies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.payload;
      })

      .addCase(addNewAgency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewAgency.fulfilled, (state, action) => {
        getAllAgencies();
        state.isLoading = false;
      })
      .addCase(addNewAgency.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.payload;
      })
      .addCase(deleteAgency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAgency.fulfilled, (state, action) => {
        getAllAgencies();
        state.isLoading = false;
      })
      .addCase(deleteAgency.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.payload;
      });
  },
});

export const {
  handleAgencyFilter,
  setSelectedAgency,
  updateMdaStateValues,
  selectPage,
  moveNextPage,
  movePrevPage,
} = mdaSlice.actions;

export default mdaSlice.reducer;
